import {Component, OnInit, ViewChild} from '@angular/core';
import {RectangleData} from '../../models/drawing/rectangle.data';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {CoreService} from '../../services/core.service';
import {PatientService} from '../../services/patient.service';
import {WoundsService} from '../../services/wounds.service';
import {ModalController} from '@ionic/angular';
import {HttpService} from '../../services/http.service';
import {TranslateService} from '@ngx-translate/core';
import {TranslateDbService} from '../../services/translate.db.service';
import {TranslationsCommonService} from '../../services/translations.common.service';

@Component({
  selector: 'app-patients-admission-signature',
  templateUrl: './patients-admission-signature.component.html',
  styleUrls: ['./patients-admission-signature.component.scss'],
})
export class PatientsAdmissionSignatureComponent implements OnInit {
  @ViewChild('imageCanvas', {static: false}) canvas: any;
  admissionId = '';
  reportType = 'wounds';
  ALL_TRANSLATIONS = [];

  showCanvas = true;

  imageLoading = false;

  woundId = null;
  formName = this.reportType + this.admissionId;

  rowCount = 0;

  TITLE = 'Wounds';
  canvasElement: any;

  canvasSelectionDataUrl  = '';
  customMessage = '';

  saveX: number;
  saveY: number;

  selectedColor = '#9e2956';
  colors = ['#9e2956', '#c2281d', '#de722f', '#edbf4c', '#5db37e', '#459cde', '#4250ad', '#802fa3'];
  lineWidth = 5;

  drawing = false;
  drawingNotes = '';

  lastRectangle: RectangleData;

  resultVisible = false;

  CANVAS_WIDTH = 340;
  CANVAS_HEIGHT = 450;

  WOUND_FIELDS = null;
  WOUND_FIELDS_VALUES = [];

  private submitSectionSub: Subscription;
  private httpResponseCodeReceivedSub: Subscription;

  constructor(private route: ActivatedRoute,
              public router: Router,
              public coreService: CoreService,
              public patientService: PatientService,
              public woundsService: WoundsService,
              public modalCtrl: ModalController,
              private httpService: HttpService,
              private translate: TranslateService,
              public translateDb: TranslateDbService,
              public translationsCommonService: TranslationsCommonService) {
  }

  ngOnDestroy() {
    if (this.submitSectionSub) {
      this.submitSectionSub.unsubscribe();
    }

    if (this.httpResponseCodeReceivedSub) {
      this.httpResponseCodeReceivedSub.unsubscribe();
    }
  }

  ngOnInit() {
    if (!this.httpService.isLoggedIn()) {
      this.router.navigateByUrl('/login');
    }

    this.httpResponseCodeReceivedSub = this.httpService.httpResponseCodeReceived.subscribe( httpData => {
      const code = +httpData.status;

      if (code >= 200 && code < 300) {
      } else {
        if (code >= 400) {
          this.imageLoading = false;
        }
      }
    });

    this.admissionId = this.route.snapshot.paramMap.get('admissionId');
    this.TITLE = 'Signature';
    this.translateDb.loadTranslations().subscribe(t => {
      this.ALL_TRANSLATIONS = t;
    });

    this.loadPatientAdmission();
  }

  loadPatientAdmission() {
    this.patientService.loadPatientsAdmissionsByAdmissionId(this.admissionId).subscribe(r => {
      console.log(r);
      const patientsAdmission = r[0];
      if (patientsAdmission.signature === null) {
        this.showCanvas = true;
      } else {
        this.canvasSelectionDataUrl = patientsAdmission.signature;

        this.showCanvas = false;
      }
    }, err => {
      console.log(err);
      this.coreService.broadcastErrorMessage('Fetching patients admissions failed ', err);
      // this.isLoading = false;
    });
  }

  closeAndRedirectToWounds() {
    this.modalCtrl.dismiss();
    this.router.navigateByUrl('/wounds/' + this.admissionId);
  }

  ngAfterViewInit(): void {
    if (this.canvasSelectionDataUrl === '') {
      this.canvasElement = this.canvas.nativeElement;
      this.canvasElement.width = this.CANVAS_WIDTH; // this.plt.width() + '';
      this.canvasElement.height = this.CANVAS_HEIGHT;

      this.setBackground();
    }
  }

  setBackground() {
    const background = new Image();
   // background.src = './assets/human_scheme.jpg';
    const ctx = this.canvasElement.getContext('2d');

    background.onload = () => {
      ctx.drawImage(background, 0, 0, this.canvasElement.width, this.canvasElement.height);
    };
  }

  startInteraction(ev) {
    console.log('start: selection mode');
    let eventX = ev.pageX;
    let eventY = ev.pageY;

    if (ev.pageX === undefined) { // android
      eventX = ev.touches[0].pageX;
      eventY = ev.touches[0].pageY;
    }

    console.log('start: ', ev);
    this.drawing = true;
    const canvasPosition = this.canvasElement.getBoundingClientRect();
    console.log(canvasPosition);
    this.saveX = eventX - canvasPosition.x;
    this.saveY = eventY - canvasPosition.y;
  }

  movedInteraction(ev) {
    let eventX = ev.pageX;
    let eventY = ev.pageY;

    if (ev.pageX === undefined) { // android
      eventX = ev.touches[0].pageX;
      eventY = ev.touches[0].pageY;
    }

    if (!this.drawing) { return; }

    console.log('move: ', eventX, ', ', eventY);
    const canvasPosition = this.canvasElement.getBoundingClientRect();
    const ctx = this.canvasElement.getContext('2d');

    const currentX = eventX - canvasPosition.x;
    const currentY = eventY - canvasPosition.y;

    ctx.lineJoin = 'round';
    ctx.strokeStyle = this.selectedColor;
    ctx.lineWidth = this.lineWidth;

    ctx.beginPath();
    ctx.moveTo(this.saveX, this.saveY);
    ctx.lineTo(currentX, currentY);
    ctx.closePath();

    ctx.stroke();

    this.saveX = currentX;
    this.saveY = currentY;
  }

  endInteraction() {
    this.drawing = false;
    console.log('end');
  }

  exportCanvasImage() {
    const dataUrl = this.canvasElement.toDataURL();
    console.log('image: ', dataUrl);

    this.imageLoading = true;
    this.clearImage();

    this.patientService.saveAdmissionSignature(dataUrl, this.admissionId).subscribe(r => {
      console.log('patientService: saveSignature result:');
      console.log(r);

      this.canvasSelectionDataUrl = dataUrl;
      this.imageLoading = false;
      this.showCanvas = false;
    });
  }

  clearImage() {
    const context = this.canvasElement.getContext('2d');
    context.clearRect(0, 0, this.canvasElement.width, this.canvasElement.height);

    //  this.canvasSelectionDataUrl = '';
    this.setBackground();
  }


  getPlaceholderHint(dataType: string){
    if (dataType === 'timestamp with time zone' || dataType === 'date') {
      return this.coreService.DATE_FORMAT;
    } else if (dataType === 'integer' || dataType === 'double' ) {
      return 'number';
    } else {
      return 'text';
    }
  }

  getInputType(dataType: string) {
    if (dataType === 'integer' || dataType === 'double' || dataType === 'decimal' ) {
      return 'number';
    } else {
      return 'text';
    }
  }

  getInputClass(savedInDb: boolean, state: string) {
    if (savedInDb === true) {
      return 'saveSuccessBg';
    } else {
      if (state === 'fresh'){
        return '';
      } else if (state === 'saved'){
        return 'saveSuccessBg';
      } else if (state === 'fail') {
        return 'saveFailedBg';
      }
    }
  }

  getInputStyle(state: string) {

    if (state === 'fresh'){
      return 'background-color: white; color: black';
    } else if (state === 'saved'){
      return 'background-color: lightgreen; color: black';
    } else if (state === 'fail') {
      return 'background-color: red; color: black';
    }
  }

  getBoolValue(itemValue: any) {

    if (itemValue === '') {
      return false;
    } else if (itemValue === false) {
      return false;
    } else if (itemValue === true) {
      return true;
    } else {
      return false;
    }
  }

  // only for TEXT fields
  patchField(col: string, array_index: number, newValue: any) {
    console.log('newValue: ', newValue);

    if (newValue === '' || newValue === undefined) {
      // this.coreService.broadcastToastMessage("NOT UPDATING FIELD", true);
      console.log('NOT UPDATING FIELD');
    } else {
      this.woundsService.patchWoundField(this.woundId, col, newValue).subscribe(r => {
            console.log('patchField report Id: ' + this.woundId + '; col: ' + col + '; value: ' + newValue);
            //   this.coreService.broadcastToastMessage(this.translationsCommonService.TRNSL_DATA_SAVED_SUCCESSFULLY, false);
            this.WOUND_FIELDS_VALUES[array_index].state = 'saved';
            this.WOUND_FIELDS_VALUES[array_index].savedInDb = true;

          }, err => {
            console.log('http ERROR caught FROM modal');
            console.log(err);
            // this.coreService.broadcastErrorMessage('Patching REPORT_FIELDS failed ', err);
            this.WOUND_FIELDS_VALUES[array_index].state = 'fail';
          }
      );
    }
  }

  calculateWidthClass(colName: string) {
    if (colName === 'description' || colName === 'drawNotes' || colName === 'position' || colName === 'stage') {
      return 'full-width';
    } else if ( colName === 'length' || colName === 'width' || colName === 'depth') {
      return 'onethird-width';
    } else {
      return 'half-width';
    }
  }
}
