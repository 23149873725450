import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {take, map, tap, delay, switchMap, catchError} from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import {Router} from '@angular/router';
import {HttpService} from './http.service';
import {DatePipe} from '@angular/common';
import {CoreService} from './core.service';
import {ResourcesService} from './resources.service';;
import {environment} from '../../environment';

@Injectable({
    providedIn: 'root'
})
export class PatientReportsService {

    PATIENT_REPORT_TYPES = {
        Nursing: 'nursing',
        Medicine: 'medicine',
        Injection: 'injection',
        Visiglu: 'visiglu',
        Glucose: 'glucose',
        Catheter: 'catheter',
        Banals: 'banal',
        Antigent: 'antigent',
        Braden: 'braden'
    }

    /// create new report: {reportType: {'cathether'}

    //response:

// {
//     id: 333,
//     fields: ["other", "tracheotomy", "nasogastric", "gastrostomy" ,"urethral", "catheter"],
//     field_types: ["bool", "bool", "bool", "bool" ,"bool", "bool"]
// }

    REPORT_FIELDS: Map<string, Array<string>> = new Map([
        ["nursing", ["nursing_report", "hospitalization_plan"]],
        ["medicine", ["drug_name", "dose", "frequency", "intake_way", "notes"]],
        ["injection", ["drug_name", "dose", "intake_way", "expiration_date", "doctor", "notes"]],
        ["visiglu", ["blood_pressure", "pulse", "temperature", "respiratory_rate", "glucose", "notes"]],
        ["glucose", ["morning_before_meal", "morning_after_meal", "midday_before_meal", "midday_after_meal", "evening_before_meal", "evening_after_meal"]],
        ["catheter", ["other", "tracheotomy", "nasogastric", "gastrostomy" ,"urethral", "catheter"]],
        ["banal", ["blood_analysis", "next_appointment_dt", "next_appointment"]],
        ["antigent", ["inr", "prothrombin_time", "anticoagulant_dose", "doctor"]],
        ["braden", ["sensory_perception", "moisture", "activity", "mobility", "nutrition", "friction_and_shear", "total_score"]]
    ]);

    REPORT_FIELDS_TYPES: Map<string, Array<string>> = new Map([
        ["nursing", ["string", "string"]],
        ["medicine", ["string", "string", "string", "string", "string"]],
        ["injection", ["string", "string", "string", "date", "string", "string"]],
        ["visiglu", ["int", "int", "int", "int", "int", "string"]],
        ["glucose", ["int", "int", "int", "int", "int", "int"]],
        ["catheter", ["bool", "bool", "bool", "bool" ,"bool", "bool"]],
        ["banal", ["string", "date", "string"]],
        ["antigent", ["double", "double", "string", "string"]],
        ["braden", ["int", "int", "int", "int", "int", "int", "int"]]
    ]);

    // REPORT_FIELDS_VALUES: Map<string, Array<string>> = new Map([
    //     ["nursing", ["", ""]],
    //     ["medicine", ["", "", "", "", ""]]
    // ]);

    // REPORT_FIELDS.set(PATIENT_REPORT_TYPES.Nursing, "closed");
    // REPORT_FIELDS.set("PO2", "pending_approval");

    constructor(public coreService: CoreService,
                private http: HttpClient,
                private cookieService: CookieService,
                public router: Router,
                private httpService: HttpService,
                private resourcesService: ResourcesService,
                private datepipe: DatePipe) {
    }

    reportsNursingAvailableChanged = new EventEmitter<any>();


    patchReportField(reportType: string, reportId: string, col: string, value: any) {
        console.log('patchReportField');

        let valueToPrint = '';
        if(isNaN(+value)){
            valueToPrint = '"' + value + '"';
        } else {
            valueToPrint = value;
        }
        const postDataString = "{\"" + col + "\": " + valueToPrint + "}";

        reportType = reportType[0].toUpperCase() + reportType.slice(1);

        console.log('postDataString: ' + postDataString);
        return this.httpService.patch (environment.URL_ROOT + '/' + reportType + 'Reports?id=eq.' + reportId, postDataString, col)
            .pipe(map(r => {
                console.log(r);
                return r;
            }, err => {
                console.log('xxxxx');
                return "xxxx";
            }),catchError((err) => {
                const message = 'ZZZZZZZZZZ: ' + err;
                console.log(message);

                return err;
            }));
    }

    initializeReport(reportType: string, admissionId: string) {
        console.log('initializeReport, admissionId: ', admissionId);
        const postData = {
            _admission: admissionId
        };

        reportType = reportType[0].toUpperCase() + reportType.slice(1);

        return this.httpService.post (environment.URL_ROOT + '/rpc/' + reportType + 'ReportInit', postData)
            .pipe(map(r => {
                console.log(r);
                return r;
            }));
    }

    loadPatientReports(reportType: string, admissionId: string) {
        console.log('loadPatientReports');

        reportType = reportType[0].toUpperCase() + reportType.slice(1);

        return this.httpService.get (environment.URL_ROOT + '/' + reportType + 'Reports')
            .pipe(map(r => {
                console.log(r);
                const filteredReports = r.filter(x => x.admission.toString() === admissionId).reverse().slice();
                return filteredReports;
            }));
    }

    loadPatientReportDetails(reportType: string, reportId: string) {
        console.log('loadPatientReportDetails, reportId: ' + reportId + ', reportType: ' + reportType);

        reportType = reportType[0].toUpperCase() + reportType.slice(1);

        return this.httpService.get (environment.URL_ROOT + '/' + reportType +'Reports?id=eq.' + reportId)
            .pipe(map(r => {
                console.log(r);
                return r;
            }));
    }

    addGenericReport(
        admissionId: string,
        reportType: string,
        currentReportFields: Array<string>,
        currentReportFieldsValues: Map<string, string>,
        currentReportFieldsTypes: Array<string>) {

        reportType = reportType[0].toUpperCase() + reportType.slice(1);

        console.log('addGenericReport');
        console.log('currentReportFields: ');
        console.log(currentReportFields);
        console.log('currentReportFieldsValues');
        console.log(currentReportFieldsValues);

        let postData = '';

        let count = 0;
        currentReportFields.forEach(i => {

            let comma = '';

            if (count > 0) {
                comma = ","
            } else {
                postData = postData + comma + "\"admission\": " + admissionId + ",";
            }

            let quotes = '';

            if (currentReportFieldsTypes[count] === "string" || currentReportFieldsTypes[count] === "date") {
                quotes = "\"";
            }

            postData = postData + comma + '"' + i + '": ' +quotes+ currentReportFieldsValues.get(i) + quotes;

            count++;
        });

        postData = '{' + postData + '}';

        console.log('jsonString: ', postData);

        let postDataJson = JSON.parse(postData);
        console.log(postDataJson);

        return this.httpService.post(environment.URL_ROOT + '/' + reportType + 'Reports', postData)
            .pipe(map(r => {
                console.log(r);
                return r;
            }));
    }


    editGenericReport(
        reportId: string,
        reportType: string,
        currentReportFields: Array<string>,
        currentReportFieldsValues: Map<string, string>,
        currentReportFieldsTypes: Array<string>
    ){
        reportType = reportType[0].toUpperCase() + reportType.slice(1);

        console.log('editGenericReport');
        console.log('currentReportFields: ');
        console.log(currentReportFields);
        console.log('currentReportFieldsValues: ');
        console.log(currentReportFieldsValues);
        console.log('currentReportFieldsTypes: ');
        console.log(currentReportFieldsTypes);

        let postData = '';

        let count = 0;
        currentReportFields.forEach(i => {

            let comma = '';
            if (count > 0) {
                comma = ","
            }

            let quotes = '';
            if (currentReportFieldsTypes[count] === 'string' || currentReportFieldsTypes[count] === 'date') {
                quotes = "\"";
            }

            postData = postData + comma + "\"" + i + "\": " + quotes + currentReportFieldsValues.get(i) + quotes;

            count++;
        });

        postData = '{' + postData + '}';
        const postDataJson = JSON.parse(postData);

        console.log(postDataJson);

        return this.httpService.patch(environment.URL_ROOT + '/' + reportType + 'Reports?id=eq.' + reportId, postDataJson)
            .pipe(map(r => {
                console.log(r);
                return r;
            }));
    }

    loadReportFields(reportType: string) {

        const reportTypeToUse = reportType[0].toUpperCase() + reportType.slice(1);
        const postData = {
            _endpoint: reportTypeToUse + 'Reports'
        };

        return this.httpService.post(environment.URL_ROOT + '/rpc/Struct', postData)
            .pipe(map(r => {
                console.log(r);

                const fieldsList = r;
                fieldsList.shift(); // remove admission field
                fieldsList.shift(); // remove patient field
                fieldsList.shift(); // remove date field
                fieldsList.shift(); // remove time field

                return fieldsList;
            }));
    }

    reportValue_shouldPresentText(reportValue: any) {
        let shouldPresentText = true;
        if (reportValue !== null) {
            if (reportValue.toString() === 'true' || reportValue.toString() == 'false') {
                shouldPresentText = false;
            }
        }

        return shouldPresentText;
    }

    reportValue_shouldPresentBoolean(reportValue: any, checkedBooleanValue: boolean){
        let shouldPresentBoolean = false;
        if (reportValue !== null){

            if (reportValue.toString() === checkedBooleanValue.toString()) {
                shouldPresentBoolean = true;
            }
        }

        return shouldPresentBoolean;
    }

    formatReportValueLabel(reportLabel){
        let reportLabelToUse = reportLabel;
        if (reportLabel.length > 3) {
            reportLabelToUse = reportLabel.substr(0,3);
        }

        return reportLabelToUse;
    }

    broadcastInfoAboutAddedReport(reportItem: any) {
        this.reportsNursingAvailableChanged.emit(reportItem);
    }
}
