import {Component, Input, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {CoreService} from '../../../services/core.service';
import {ModalController} from '@ionic/angular';
import {HttpService} from '../../../services/http.service';
import {TranslateService} from '@ngx-translate/core';
import {NurseService} from '../../../services/nurse.service';
import {FileData} from '../filedata.model';
import {ImageBrowserComponent} from '../image-browser/image-browser.component';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss'],
})
export class ConfirmModalComponent implements OnInit {
  @Input() message: string;
  @Input() sectionName: string;

  submitSectionChangedSub: Subscription;
  constructor(private route: ActivatedRoute,
              public router: Router,
              public coreService: CoreService,
              public modalCtrl: ModalController,
              private httpService: HttpService,
              private translate: TranslateService,
              private nurseService: NurseService) { }

  ngOnDestroy() {
    if (this.submitSectionChangedSub) {
      this.submitSectionChangedSub.unsubscribe();
    }

  }

  ngOnInit() {

    this.submitSectionChangedSub = this.coreService.submitSectionsChanged.subscribe(update => {
      update.map(i => {
        if (i.submitBroadcasted && i.sectionName === this.sectionName) {
          this.modalCtrl.dismiss();
        }

        if (i.cancelBroadcasted && i.sectionName === this.sectionName) {
          this.modalCtrl.dismiss();
        }
      });
    });

  }

}
