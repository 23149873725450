import {Component, Input, OnInit} from '@angular/core';
import {CoreService} from "../../../services/core.service";
import {FormBooleanSectionData} from "../formbooleansectiondata.model";

@Component({
  selector: 'app-form-boolean-section',
  templateUrl: './form-boolean-section.component.html',
  styleUrls: ['./form-boolean-section.component.scss'],
})
export class FormBooleanSectionComponent implements OnInit {
  @Input() isDisabled: boolean;
  @Input() sectionName: string;
  @Input() position: boolean;

  constructor(private coreService: CoreService) { }

  ngOnInit() {
    console.log('FormBooleanSectionComponent, position:', this.position);
     //this.position = !this.position;
  }

  togglePosition() {
    console.log('brodcastTogglePosition');
     let newPosition = !this.position
     this.position = !this.position

    console.log('new position: ', newPosition);
    this.coreService.setFormBooleanSectionUpdate(new FormBooleanSectionData(this.isDisabled, this.sectionName, newPosition));
  }

  setPosition(newPosition:boolean) {
    console.log('setPosition: ', newPosition);

    this.position = newPosition;

    console.log('new position: ',  this.position);
    this.coreService.setFormBooleanSectionUpdate(new FormBooleanSectionData(this.isDisabled, this.sectionName, newPosition));
  }
}
