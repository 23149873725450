import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {CoreService} from '../../../services/core.service';
import {CallNumber} from '@ionic-native/call-number/ngx';
import {Clipboard} from '@ionic-native/clipboard/ngx';
import {HttpService} from '../../../services/http.service';
import {Router} from '@angular/router';
import {MessageService} from '../../../services/message.service';
import {Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {TranslationsCommonService} from '../../../services/translations.common.service';

@Component({
  selector: 'app-sms-modal',
  templateUrl: './sms-modal.component.html',
  styleUrls: ['./sms-modal.component.scss'],
})
export class SmsModalComponent implements OnInit, OnDestroy {
  @Input() phoneNumber: string;
  @Input() firstName: string;
  @Input() lastName: string;

  message = '';
  formName = 'smsForm' + this.phoneNumber;


  submitSectionChangedSub: Subscription;
  constructor(public modalCtrl: ModalController,
              public coreService: CoreService,
              private messageService: MessageService,
              private callNumber: CallNumber,
              private clipboard: Clipboard,
              private httpService: HttpService,
              public router: Router,
              private translate: TranslateService,
              public translatationsCommonService: TranslationsCommonService
  ) { }

  ngOnDestroy() {
    if (this.submitSectionChangedSub) {
      this.submitSectionChangedSub.unsubscribe();
    }
  }

  ngOnInit() {
    if (!this.httpService.isLoggedIn()){
      this.router.navigateByUrl('/login');
    }


    this.submitSectionChangedSub = this.coreService.submitSectionsChanged.subscribe(update => {
      update.map(i => {
        if (i.sectionName === this.formName){
          if (i.submitBroadcasted) {
            this.messageService.sendSms(this.phoneNumber, this.message).subscribe(r => {
              this.coreService.broadcastToastMessage(this.translatationsCommonService.TRNSL_SENT_SUCCESSFULLY, false);
            }, err => {
              console.log(err);
              this.coreService.broadcastErrorMessage(this.translatationsCommonService.TRNSL_SENDING_FAILED, err);
            });
          }

          if (i.cancelBroadcasted) {
            this.modalCtrl.dismiss();
          }
        }
      });
    });
  }


  sendSms() {
    this.messageService.sendSms(this.phoneNumber, this.message).subscribe(r => {

      console.log('Saving contact feature NOT IMPLEMENTED yet');

      this.coreService.broadcastToastMessage('Saving contact feature NOT IMPLEMENTED yet', true);
    }, err => {
      console.log(err);
      this.coreService.broadcastErrorMessage('Sending sms failed ', err);
    });
  }


  closeModal() {
    this.modalCtrl.dismiss(null, 'cancel');
  }


}
