import {Component, Input, OnInit} from '@angular/core';
import {CoreService} from '../../../services/core.service';
import {PatientService} from '../../../services/patient.service';

@Component({
  selector: 'app-patchable-dropdown-wounds',
  templateUrl: './patchable-dropdown-wounds.component.html',
  styleUrls: ['./patchable-dropdown-wounds.component.scss'],
})
export class PatchableDropdownWoundsComponent implements OnInit {
  @Input() FIELD_NAME: string;
  @Input() FIELD_VALUE: string;
  @Input() IS_DISABLED: boolean;
  @Input() LABEL_TEXT: string;
  @Input() PLACEHOLDER_TEXT: string;
  @Input() PATCH_URL: string;
  @Input() HYDRATE_URL: string;
  @Input() CSS_CLASSES: string;
  @Input() LABEL_VISIBLE: boolean;
  @Input() LABEL_LEFT = false;
  @Input() LABEL_AS_FIRST_ITEM = false;

  @Input() ITEM_ID = '';
  @Input() SOURCE_FOR_NOTIFICATION = '';
  @Input() PATCH_ACTION = true;
  @Input() READ_ONLY = false;
  @Input() READ_ONLY_CLICK_DISABLED = false;
  @Input() LAZY_LOADING = false;
  @Input() LAZY_LOADING_DISPLAY_NAME = '';

  @Input() LIST_ITEM_MODE = false;

  @Input() FIELD_NAME_PREFIX = '';

  cssClassesUpdated = '';
  saveStatus = 'initial';
  AVAILABLE_ITEMS = [];

  constructor(private coreService: CoreService,
              private patientService: PatientService) {

    this.FIELD_NAME = '';
    this.FIELD_VALUE = '';
    this.IS_DISABLED = false;
    this.LABEL_TEXT = '';
    this.PLACEHOLDER_TEXT = '';
    this.PATCH_URL = '';
    this.HYDRATE_URL = '';
    this.CSS_CLASSES = '';
    this.LABEL_VISIBLE = true;
    this.LABEL_LEFT = false;
    this.LABEL_AS_FIRST_ITEM = false;
    this.ITEM_ID = '';
    this.SOURCE_FOR_NOTIFICATION = '';
    this.PATCH_ACTION = true;
    this.READ_ONLY = false;
    this.READ_ONLY_CLICK_DISABLED = false;
    this.LAZY_LOADING = false;
    this.LAZY_LOADING_DISPLAY_NAME = '';
    this.LIST_ITEM_MODE = false;
    this.FIELD_NAME_PREFIX = '';


  }

  ngOnChanges() {
    this.loadData();
  }

  ngOnInit(): void {
    this.cssClassesUpdated = this.CSS_CLASSES;
    this.loadData();
  }

  loadData() {
    if (this.READ_ONLY && this.LAZY_LOADING) {
      // don't load items because in READ ONLY mode - we are only displaying a value
    } else {
      this.patientService.loadItemsForHydration(this.HYDRATE_URL).subscribe(r => {
        console.log('loadItemsForHydration............');
        console.log(r);
        this.AVAILABLE_ITEMS = r;
      }, err => {
        console.log(err);
        this.coreService.formatAndBroadcastDiagnosticMessage(err);
      });
    }
  }

  executeAction() {

    console.log('executing action...');

    this.patchField();

    //
    // setTimeout(() => {
    //   this.READ_ONLY = true;
    //
    // }, 1500);
  }

  patchField() {
    if(this.PATCH_ACTION){
      this.cssClassesUpdated = this.CSS_CLASSES;

      this.coreService.broadcast_HEADER_DIAGNOSTIC_MESSAGE(this.coreService.DIAGNOSTIC_MESSAGE_UPDATING);
      this.patientService.PATCH_Field(this.PATCH_URL, this.FIELD_NAME, this.FIELD_VALUE).subscribe(r => {

        this.setSavedStatus();
        this.LAZY_LOADING_DISPLAY_NAME = this.printItemLabelFromId(this.FIELD_VALUE);

        this.coreService.broadcast_HEADER_DIAGNOSTIC_MESSAGE(this.coreService.DIAGNOSTIC_MESSAGE_OK);
      }, err => {
        console.log(err);
        this.saveStatus = 'failed';
        this.coreService.formatAndBroadcastDiagnosticMessage(err);
      });
    } else {
      this.coreService.broadcast_PATCHABLE_INPUT_UPDATED(this.FIELD_NAME, this.FIELD_VALUE, this.SOURCE_FOR_NOTIFICATION, this.ITEM_ID);
      console.log(this.FIELD_NAME + ' - PATCH ACTION SUPPRESSED!');
    }
  }

  setSavedStatus() {
    this.saveStatus = 'saved';
    setTimeout(() => {
      this.saveStatus = '';
    }, 1000);
  }

  enableEditing() {

    if(this.READ_ONLY_CLICK_DISABLED === false) {

      this.LAZY_LOADING = false;

      this.READ_ONLY = false;

      this.loadData();
    }


  }

  activateField() {
    // apply classes here etc...
    this.saveStatus = '';
    //this.fieldActive = true;
  }

  getInputClass(initialClass: string) {
    if (this.saveStatus === 'saved') {
      return initialClass + ' ' + 'comhese-field-saved';
    } else if(this.saveStatus === 'failed') {
      return initialClass + ' ' + 'comhese-field-save-fail';
    } else {
      return initialClass;

    }
  }


  // getStyleIndicatorStyle() {
  //   if (this.saveStatus === 'saved') {
  //     return "background-color: lightgreen";
  //   } else if (this.saveStatus === 'failed') {
  //     return "background-color: red";
  //   } else {
  //     return "";
  //   }
  // }

  printItemLabelFromId(itemId: string) {

    const xxx = this.AVAILABLE_ITEMS.filter(x=> x['id'] == itemId)[0];

    let prefix = '';
    if(this.FIELD_NAME_PREFIX != ''){
      prefix =  xxx[this.FIELD_NAME_PREFIX] + ' - ';
    }

    return prefix +  xxx['name'];
  }

  printItemLabel(item: any) {
    const xxx = this.AVAILABLE_ITEMS.filter(x=> x['id'] == item['id'])[0];

    let prefix = '';
    if(this.FIELD_NAME_PREFIX != ''){
      prefix =  xxx[this.FIELD_NAME_PREFIX]+ ' - ';
    }

    return   prefix +  xxx['name'];
  }
}
