import { NgModule, CUSTOM_ELEMENTS_SCHEMA  } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import {CommonModule, DatePipe} from '@angular/common';
import { HeaderComponent } from './components/shared/header/header.component';
import {CoreService} from './services/core.service';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {LoginComponent} from './components/login/login.component';
import {Calendar} from '@ionic-native/calendar/ngx';
import {QuickMenuComponent} from './components/shared/quick-menu/quick-menu.component';
import {QuickMenuIconComponent} from './components/shared/quick-menu-icon/quick-menu-icon.component';
import {GenderSelectorComponent} from './components/shared/gender-selector/gender-selector.component';
import {CallModalComponent} from './components/shared/call-modal/call-modal.component';
import {CallNumber} from '@ionic-native/call-number/ngx';
import { Clipboard } from '@ionic-native/clipboard/ngx';
import {Contacts} from '@ionic-native/contacts';
import { DatePickerModule } from 'ionic4-date-picker';
import {PickerDateTimeComponent} from './components/shared/picker-date-time/picker-date-time.component';
import {PickerDateTimeContainerComponent} from './components/shared/picker-date-time-container/picker-date-time-container.component';
import {ImageBrowserComponent} from './components/shared/image-browser/image-browser.component';
import {File} from '@ionic-native/file/ngx';
import {FileOpener} from '@ionic-native/file-opener/ngx';
import {SubmitSectionData} from './components/shared/submitsectiondata.model';
import {SubmitSectionComponent} from './components/shared/submit-section/submit-section.component';
import {AndroidPermissions} from '@ionic-native/android-permissions/ngx';
import { FileChooser } from '@ionic-native/file-chooser/ngx';
import {FilePath} from '@ionic-native/file-path/ngx';
import {Base64} from '@ionic-native/base64/ngx';
import {FileUploadComponent} from './components/shared/file-upload/file-upload.component';
import {GenderAvatarComponent} from './components/shared/gender-avatar/gender-avatar.component';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import {HomePage} from './components/home/home.page';
import {ResourcesService} from './services/resources.service';
import {ToastNotificationComponent} from './components/shared/toast-notification/toast-notification.component';
import {SingleValueSelectorComponent} from './components/shared/single-value-selector/single-value-selector.component';
import {NurseService} from './services/nurse.service';
import {ConfirmModalComponent} from './components/shared/confirm-modal/confirm-modal.component';
import {SmsModalComponent} from './components/shared/sms-modal/sms-modal.component';
import {MessageService} from './services/message.service';
import {DocumentsService} from './services/documents.service';
import {TranslationsCommonService} from './services/translations.common.service';
import { SQLite, SQLiteObject } from '@ionic-native/sqlite/ngx';
import {CommonService} from './services/common.service';
import {SettingsComponent} from './components/settings/settings.component';
import {ObserveVisibilityDirective} from './directives/observevisibility';
import {CalendarService} from './services/calendar.service';
import {LogService} from './services/log.service';
import {NursesComponent} from './components/nurses/nurses.component';
import {ModalVisitorsUpsertComponent} from './components/shared/modal-visitors-upsert/modal-visitors-upsert.component';
import {PatientService} from './services/patient.service';
import {PatientsComponent} from './components/patients/patients.component';
import {ModalPatientsUpsertComponent} from './components/shared/modal-patients-upsert/modal-patients-upsert.component';
import {PatientsAdmissionsComponent} from './components/patients-admissions/patients-admissions.component';
import {PatientEvaluationsComponent} from './components/patient-evaluations/patient-evaluations.component';
import {ModalPatientEvaluationUpsertComponent} from './components/shared/modal-patient-evaluation-upsert/modal-patient-evaluation-upsert.component';
import {ModalPatientEvaluationSectionComponent} from './components/shared/modal-patient-evaluation-section/modal-patient-evaluation-section.component';
import {PatientDetailsComponent} from './components/patient-details/patient-details.component';
import {FormBooleanSectionComponent} from './components/shared/form-boolean-section/form-boolean-section.component';
import {FormDropdownSectionComponent} from './components/shared/form-dropdown-section/form-dropdown-section.component';
import {PatientReportsService} from './services/patientreports.service';
import {ReportsGenericComponent} from './components/reports/reports-generic/reports-generic.component';
import {PatientMenuComponent} from './components/patient-menu/patient-menu.component';
import {PatientAdmissionDetailsComponent} from './components/patient-admission-details/patient-admission-details.component';
import {ReportsMenuComponent} from './components/reports/reports-menu/reports-menu.component';
import {ModalPatientAdmissionUpsertComponent} from './components/shared/modal-patient-admission-upsert/modal-patient-admission-upsert.component';
import {UpdatesLogComponent} from './components/updates-log/updates-log.component';
import {PatientsEvaluationDetailsComponent} from './components/patients-evaluation-details/patients-evaluation-details.component';
import {TranslateDbService} from './services/translate.db.service';
import {ReportsGenericModal2Component} from './components/reports/reports-generic-modal2/reports-generic-modal2.component';
import {FormBooleanSectionDbdependentComponent} from './components/shared/form-boolean-section-dbdependent/form-boolean-section-dbdependent.component';
import {ReportGenericDetailsComponent} from './components/reports/report-generic-details/report-generic-details.component';
import {ReportsCatheterComponent} from './components/reports/reports-catheter/reports-catheter.component';
import {ScreenOrientation} from '@ionic-native/screen-orientation/ngx';
import {Base64ToGallery} from '@ionic-native/base64-to-gallery/ngx';
import {WoundsFormComponent} from './components/wounds/wounds-form/wounds-form.component';
import {WoundsService} from './services/wounds.service';
import {WoundFilesComponent} from './components/wounds/wound-files/wound-files.component';
import { WoundFormBooleanSectionDbdependentComponent } from './components/wounds/wound-form-boolean-section-dbdependent/wound-form-boolean-section-dbdependent.component';
import {WoundsComponent} from './components/wounds/wounds/wounds.component';
import {PatientsAdmissionSignatureComponent} from './components/patients-admission-signature/patients-admission-signature.component';
import {WoundsStageModalComponent} from './components/wounds/wounds-stage-modal/wounds-stage-modal.component';
import {PatientsAdmissionChartsComponent} from './components/patients-admission-charts/patients-admission-charts.component';
import {PatchableInputTextComponent} from './components/shared/patchable-input-text/patchable-input-text.component';
import {PatchableDropdownWoundsComponent} from './components/shared/patchable-dropdown-wounds/patchable-dropdown-wounds.component';
import {PatchableDropdownComponent} from "./components/shared/patchable-dropdown/patchable-dropdown.component";


// @ts-ignore
@NgModule({
    declarations: [
        AppComponent,
        HomePage,
        HeaderComponent,
        LoginComponent,
        QuickMenuComponent,
        QuickMenuIconComponent,
        GenderSelectorComponent,
        CallModalComponent,
        PickerDateTimeComponent,
        PickerDateTimeContainerComponent,
        ImageBrowserComponent,
        SubmitSectionComponent,
        FileUploadComponent,
        GenderAvatarComponent,
        HomePage,
        ToastNotificationComponent,
        SingleValueSelectorComponent,
        ConfirmModalComponent,
        SmsModalComponent,
        SettingsComponent,
        ObserveVisibilityDirective,
        NursesComponent,
        ModalVisitorsUpsertComponent,
        PatientsComponent,
        ModalPatientsUpsertComponent,
        PatientsAdmissionsComponent,
        ModalPatientAdmissionUpsertComponent,
        PatientEvaluationsComponent,
        ModalPatientEvaluationUpsertComponent,
        ModalPatientEvaluationSectionComponent,
        PatientDetailsComponent,
        FormBooleanSectionComponent,
        FormDropdownSectionComponent,
        ReportsGenericComponent,
        PatientMenuComponent,
        PatientAdmissionDetailsComponent,
        ReportsMenuComponent,
        UpdatesLogComponent,
        PatientsEvaluationDetailsComponent,
        ReportsGenericModal2Component,
        FormBooleanSectionDbdependentComponent,
        ReportGenericDetailsComponent,
        ReportsCatheterComponent,
        WoundsFormComponent,
        WoundFilesComponent,
        WoundFormBooleanSectionDbdependentComponent,
        WoundsComponent,
        PatientsAdmissionSignatureComponent,
        WoundsStageModalComponent,
        PatientsAdmissionChartsComponent,
        PatchableInputTextComponent,
        PatchableDropdownWoundsComponent,
        PatchableDropdownComponent
    ],
    entryComponents: [],
    imports: [
        BrowserModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        HttpClientModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        DatePickerModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        })

    ],
    providers: [
        Contacts,
        DatePipe,
        StatusBar,
        SplashScreen,
        Calendar,
        CallNumber,
        Clipboard,
        File,
        FileOpener,
        ScreenOrientation,
        AndroidPermissions,
        FilePath,
        FileChooser,
        Base64,
        {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
        CoreService,
        NurseService,
        ResourcesService,
        MessageService,
        DocumentsService,
        TranslationsCommonService,
        SQLite,
        CommonService,
        CalendarService,
        LogService,
        PatientService,
        PatientReportsService,
        TranslateDbService,
        Base64ToGallery,
        WoundsService
    ],
    // https://academind.com/tutorials/adding-web-components-to-any-app
    // add the schemas property and pass the CUSTOM_ELEMENTS_SCHEMA constant
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    exports: [
        QuickMenuIconComponent
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}
