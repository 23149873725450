import {Component, Input, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {CoreService} from "../../../services/core.service";
import {PatientService} from "../../../services/patient.service";
import {ModalController} from "@ionic/angular";
import {HttpService} from "../../../services/http.service";
import {TranslateService} from "@ngx-translate/core";
import {TranslationsCommonService} from "../../../services/translations.common.service";
import {FormDropdownSectionItem} from "../formdropdownsectiondata.model";

@Component({
  selector: 'app-modal-patients-upsert',
  templateUrl: './modal-patients-upsert.component.html',
  styleUrls: ['./modal-patients-upsert.component.scss'],
})
export class ModalPatientsUpsertComponent implements OnInit {
  @Input() showHeader: number;

  @Input() action: string;
  @Input() patientItem: any;

  patientid_cardDisabled = false;

  first_name              = "";
  last_name               = "";
  reg_no                  = "";
  email                   = "";
  birth_dt                = "";
  marital_status          = "";
  id_card                 = "";
  address                 = "";
  postcode                = "";
  phone                   = "";
  profession              = "";
  retired                 = false;
  religion                = "";
  language                = "";

  isLoading = false;


  formName = '';

  /////// form-bool settings  //////////////////
  form_bool_RETIRED = 'form_bool_RETIRED';
  //////////////////////////////////////////////////

  /////// form-dropdown settings  //////////////////
  form_dropdown_MARITAL_STATUS = 'form_dropdown_MARITAL_STATUS';
  form_dropdown_MARITAL_STATUS_items = [];
  form_dropdown_MARITAL_STATUS_selected_item: FormDropdownSectionItem = null
  //////////////////////////////////////////////////


  private submitSectionSub: Subscription;
  private formBooleanSectionSub: Subscription;
  private formDropdownSectionSub: Subscription;

  constructor(private route: ActivatedRoute,
              public router: Router,
              public coreService: CoreService,
              public patientService: PatientService,
              public modalCtrl: ModalController,
              private httpService: HttpService,
              private translate: TranslateService,
              public translationsCommonService: TranslationsCommonService) {
  }

  ngOnDestroy() {
    if (this.submitSectionSub) {
      this.submitSectionSub.unsubscribe();
    }

    if (this.formBooleanSectionSub) {
      this.formBooleanSectionSub.unsubscribe();
    }

    if (this.formDropdownSectionSub) {
      this.formDropdownSectionSub.unsubscribe();
    }
  }

  ngOnInit() {
    if (!this.httpService.isLoggedIn()) {
      this.router.navigateByUrl('/login');
    }

    /////// form-dropdown load  //////////////////
    this.patientService.loadFormItems(this.patientService.PATIENT_FORM_ITEMS.MARITALSTATUSES).subscribe(
      formItems => {

        console.log(formItems);
      this.form_dropdown_MARITAL_STATUS_items = formItems;

      // select first as default
        if (this.action === "add") {
          this.marital_status = formItems[0].id;
        } else {


          const formDropdownSectionItem = this.form_dropdown_MARITAL_STATUS_items.find(x => x.id.toString() === this.patientItem.marital_status.toString());
          console.log('formDropdownSectionItem');
          console.log(formDropdownSectionItem);
          this.form_dropdown_MARITAL_STATUS_selected_item = formDropdownSectionItem;
        }
    });
    //////////////////////////////////////////////////


    if (this.action === "edit") {
      this.formName = "patientEdit";
      console.log('modal action: ' + this. action);
      console.log('patientItem:');
      console.log(this.patientItem);

      this.patientid_cardDisabled = true;

      this.first_name                                       = this.patientItem.first_name;
      this.last_name                                        = this.patientItem.last_name;
      this.reg_no                                           = this.patientItem.reg_no;
      this.email                                            = this.patientItem.email;
      this.birth_dt                                         = this.patientItem.birth_dt;
      this.marital_status                                   = this.patientItem.marital_status;
      this.id_card                                          = this.patientItem.id_card;
      this.address                                          = this.patientItem.address;
      this.postcode                                         = this.patientItem.postcode;
      this.phone                                            = this.patientItem.phone;
      this.profession                                       = this.patientItem.profession;
      this.retired                                          = this.patientItem.retired;
      this.religion                                         = this.patientItem.religion;
      this.language                                         = this.patientItem.language;

    } else {
      this.formName = "patientAdd";
    }

    this.submitSectionSub = this.coreService.submitSectionsChanged.subscribe(
        submitSectionDataItems  => {
          console.log('submitSectionSub: ');
          console.log(submitSectionDataItems);

          const submitSectionItem = submitSectionDataItems.find(x => x.sectionName === this.formName);

          if (submitSectionItem != null){
            if (submitSectionItem.cancelBroadcasted === true) {
              //this.router.navigateByUrl('/vaccine-lots'  );
              this.modalCtrl.dismiss();
            }

            if (submitSectionItem.submitBroadcasted === true) {

              console.log('calling onUpsertPatient:', this.retired);
              this.onUpsertPatient();
            }
          }
        }
    );


    /////// form-boolean update handler  //////////////////
    this.formBooleanSectionSub = this.coreService.formBooleanSectionsChanged.subscribe(
        formBooleanSectionDataItems  => {
          console.log('formBooleanSectionSub: ');
          console.log(formBooleanSectionDataItems);

          const formBooleanSectionItem = formBooleanSectionDataItems.find(x => x.sectionName === this.form_bool_RETIRED);

          if (formBooleanSectionItem != null) {
            console.log('formBooleanSectionItem.position:');
            console.log(formBooleanSectionItem.position);
            this.retired = formBooleanSectionItem.position;

            console.log('new value of retired:',this.retired);
          }
        }
    );
    ////////////////////////////////////////////////////////////

    /////////// form-dropdown update handler  //////////////////
    this.formDropdownSectionSub = this.coreService.formDropdownSectionsChanged.subscribe(
        formDropdownSectionDataItems  => {
          console.log('formDropdownSectionSub: ');
          console.log(formDropdownSectionDataItems);

          const formDropdownSectionItem = formDropdownSectionDataItems.find(x => x.sectionName === this.form_dropdown_MARITAL_STATUS);

          console.log('formDropdownSectionItem');
          console.log(formDropdownSectionItem);
          if (formDropdownSectionItem != null) {
            this.marital_status = formDropdownSectionItem.selectedItem.id;
          }
        }
    );
    ////////////////////////////////////////////////////////////


  }

  onUpsertPatient() {
    console.log('onUpsertPatient');
    this.isLoading = true;

    if (this.action === "edit") {

      console.log('onUpsertPatient:', this.retired);
      console.log('patientItem:');
      console.log(this.patientItem);
      this.patientService.editPatient(
          this.patientItem.id,
          this.first_name       ,
          this.last_name        ,
          this.reg_no           ,
          this.email            ,
          this.birth_dt         ,
          this.marital_status   ,
          this.id_card          ,
          this.address          ,
          this.postcode         ,
          this.phone            ,
          this.profession       ,
          this.retired          ,
          this.religion         ,
          this.language
      ).subscribe(r => {
        console.log(r);
        this.isLoading = false;
        this.coreService.broadcastToastMessage(this.translationsCommonService.TRNSL_DATA_SAVED_SUCCESSFULLY, false);

        this.patientService.broadcastInfoAboutAddedPatient(r);

        this.modalCtrl.dismiss();
        //this.router.navigateByUrl('/vaccine-lots');
      }, err => {
        console.log(err);
        this.coreService.broadcastErrorMessage(this.translationsCommonService.TRNSL_DATA_SAVING_FAILED, err);
        this.isLoading = false;
      });

    }else{
      this.patientService.addPatient(
          this.first_name       ,
          this.last_name        ,
          this.reg_no           ,
          this.email            ,
          this.birth_dt         ,
          this.marital_status   ,
          this.id_card          ,
          this.address          ,
          this.postcode         ,
          this.phone            ,
          this.profession       ,
          this.retired          ,
          this.religion         ,
          this.language
      ).subscribe(r => {
        console.log(r);
        this.isLoading = false;
        this.coreService.broadcastToastMessage(this.translationsCommonService.TRNSL_DATA_SAVED_SUCCESSFULLY, false);

        this.patientService.broadcastInfoAboutAddedPatient(r);

        this.modalCtrl.dismiss();
        //this.router.navigateByUrl('/vaccine-lots');
      }, err => {
        console.log(err);
        this.coreService.broadcastErrorMessage(this.translationsCommonService.TRNSL_DATA_SAVING_FAILED, err);
        this.isLoading = false;
      });
    }

  }

  checkIfFormValid() {
    return true;
  }
}
