import {Component, Input, OnInit} from '@angular/core';
import {ModalController, NavController} from '@ionic/angular';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {DomSanitizer} from '@angular/platform-browser';
import {Location} from '@angular/common';

@Component({
  selector: 'app-quick-menu',
  templateUrl: './quick-menu.component.html',
  styleUrls: ['./quick-menu.component.scss'],
})
export class QuickMenuComponent implements OnInit {
  constructor(
      public modalCtrl: ModalController,
      public router: Router,
      private route: ActivatedRoute,
      private navCtrl: NavController,
      private location: Location) {
  }

  ngOnInit() {}

  loadPage(pageName: string){
    console.log('loading page: ' + pageName);
    this.router.navigateByUrl( '/' + pageName );
  }

  goback() {
    this.location.back();
  }
}
