import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {RectangleData} from '../../../models/drawing/rectangle.data';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {CoreService} from '../../../services/core.service';
import {PatientService} from '../../../services/patient.service';
import {WoundsService} from '../../../services/wounds.service';
import {ModalController} from '@ionic/angular';
import {HttpService} from '../../../services/http.service';
import {TranslateService} from '@ngx-translate/core';
import {TranslateDbService} from '../../../services/translate.db.service';
import {TranslationsCommonService} from '../../../services/translations.common.service';
import {FileData} from '../../shared/filedata.model';
import {ImageBrowserComponent} from '../../shared/image-browser/image-browser.component';

@Component({
  selector: 'app-wound-files',
  templateUrl: './wound-files.component.html',
  styleUrls: ['./wound-files.component.scss'],
})
export class WoundFilesComponent implements OnInit, OnDestroy {
  @Input() admissionId: string;
  @Input() woundId: string;
  reportType = 'wounds';
  TITLE = 'Wounds Files';

  files = [];

  imageLoading = false;

  uploadVisible = true;
  private fileLoadedChangedSub: Subscription;
  private httpResponseCodeReceivedSub: Subscription;

  private httpResponseMessageReceivedSub: Subscription;
  httpMessageShow = false;
  httpMessage = '';
  constructor(private route: ActivatedRoute,
              public router: Router,
              public coreService: CoreService,
              public patientService: PatientService,
              public woundsService: WoundsService,
              public modalCtrl: ModalController,
              private httpService: HttpService,
              private translate: TranslateService,
              public translateDb: TranslateDbService,
              public translationsCommonService: TranslationsCommonService) {
  }

  ngOnDestroy() {
    if (this.fileLoadedChangedSub) {
      this.fileLoadedChangedSub.unsubscribe();
    }

    if (this.httpResponseCodeReceivedSub) {
      this.httpResponseCodeReceivedSub.unsubscribe();
    }

    if (this.httpResponseMessageReceivedSub) {
      this.httpResponseMessageReceivedSub.unsubscribe();
    }
  }

  ngOnInit() {
    if (!this.httpService.isLoggedIn()) {
      this.router.navigateByUrl('/login');
    }

    this.loadFiles();


    this.httpResponseMessageReceivedSub = this.httpService.httpResponseMessageReceived.subscribe(message => {
      console.log('httpResponseMessageReceivedSub: ' + message);
      this.httpMessage = message;
      this.httpMessageShow = true;
    });

    this.fileLoadedChangedSub = this.coreService.fileLoadedChanged.subscribe(file => {
      console.log('StudentDocumentsFileComponent.fileLoadedChangedSub');
      this.uploadFileToDb(file);
    });

    this.httpResponseCodeReceivedSub = this.httpService.httpResponseCodeReceived.subscribe( httpData => {
      const code = +httpData.status;

      if (code >= 200 && code < 300) {
      } else {
        if (code >= 400) {
          this.imageLoading = false;
        }
      }
    });
  }

  loadFiles() {
    if (this.woundId !== 'NEW') {
      this.woundsService.loadWoundFiles(this.woundId).subscribe(r => {
        this.files = r.reverse().slice();
        console.log(this.files);
      });
    }
  }

  getThumbnail(item: any) {
    const base64string =    'data:' + item.mimetype + ';base64,' + item.thumbnail;
    return base64string;
  }

  deleteFile(itemData: any) {
    console.log('deleting file');
    this.loadFiles();
    this.coreService.broadcastToastMessage('Deleting file (not implemented)', false);
  }

  uploadFileToDb(uploadedFile: FileData) {
        // uploadedFile.file_name,
        // uploadedFile.file_type,

    this.imageLoading = true;
    this.woundsService.saveWoundImage(
        uploadedFile.base64string,
        uploadedFile.file_description,
        this.woundId).subscribe(r => {
//      this.showSucessBadge();

      this.uploadVisible = false;
      //
      // this.base64String = '';
      // this.fileType = '';
      // this.fileName = '';
      // this.description = '';

      this.loadFiles();

      this.coreService.broadcastToastMessage('File uploaded', false);
      this.imageLoading = false;
    }, err => {
      console.log(err);
      this.imageLoading = false;
      this.coreService.broadcastErrorMessage('File upload failed ', err);
    });
  }

  openImageBrowser(fileId: string) {
    this.modalCtrl
        .create({
          component: ImageBrowserComponent,
          componentProps: { fileId },
          cssClass: 'bg-none'
        })
        .then(modalEl => {
          modalEl.present();
          return modalEl.onDidDismiss();
        });
  }
}
