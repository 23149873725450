import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {take, map, tap, delay, switchMap, catchError} from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import {Router} from '@angular/router';
import {HttpService} from './http.service';
import {DatePipe} from '@angular/common';
import {CoreService} from './core.service';
import {ResourcesService} from './resources.service';
import {FormDropdownSectionItem} from '../components/shared/formdropdownsectiondata.model';
import {environment} from '../../environment';

@Injectable({
  providedIn: 'root'
})
export class PatientService {

    PATIENT_EVALUATION_SECTION = {
        HealthHistory: 'HealthHistory',
        SupportSystem: 'SupportSystem',
        Vision: 'Vision',
        Hearing: 'Hearing',
        MentalState: 'MentalState',
        Respiratory: 'Respiratory',
        Cardiovasc: 'Cardiovasc',
        Digestive: 'Digestive',
        Urine: 'Urine',
        Skin: 'Skin',
        Muskel: 'Muskel',
        Transport: 'Transport',
        Housing: 'Housing',
        Interior: 'Interior',
        Exterior: 'Exterior'
    };



    PATIENT_EVALUATION_SECTION_SNAKE: Map<string, string> = new Map([
        [this.PATIENT_EVALUATION_SECTION.HealthHistory, 'health_history'],
        [this.PATIENT_EVALUATION_SECTION.SupportSystem, 'support_system'],
        [this.PATIENT_EVALUATION_SECTION.Vision, 'vision'],
        [this.PATIENT_EVALUATION_SECTION.Hearing, 'hearing'],
        [this.PATIENT_EVALUATION_SECTION.MentalState, 'mental_state'],
        [this.PATIENT_EVALUATION_SECTION.Respiratory, 'respiratory'],
        [this.PATIENT_EVALUATION_SECTION.Cardiovasc, 'cardiovasc'],
        [this.PATIENT_EVALUATION_SECTION.Digestive, 'digestive'],
        [this.PATIENT_EVALUATION_SECTION.Urine, 'urine'],
        [this.PATIENT_EVALUATION_SECTION.Skin, 'skin'],
        [this.PATIENT_EVALUATION_SECTION.Muskel, 'muskel'],
        [this.PATIENT_EVALUATION_SECTION.Transport, 'transport'],
        [this.PATIENT_EVALUATION_SECTION.Housing, 'housing'],
        [this.PATIENT_EVALUATION_SECTION.Interior, 'interior'],
        [this.PATIENT_EVALUATION_SECTION.Exterior, 'exterior'],
    ]);

    PATIENT_FORM_ITEMS = {
        ACTIVITIES: 'ItemsActivities',
        CATHETERCHANGES: 'ItemsCatheterChanges',
        CARDIOVASCS: 'ItemsCardiovascs',
        DIGESTIVES: 'ItemsDigestives',
        HEALTHHISTORIES: 'ItemsHealthHistories',
        HOMEEVALS: 'ItemsHomeEvals',
        INTERIORS: 'ItemsInteriors',
        EXTERIORS: 'ItemsExteriors',
        FRICTIONANDSHEARS: 'ItemsFrictionAndShears',
        MOBILITIES: 'ItemsMobilities',
        MOISTURES: 'ItemsMoistures',
        MUSCULOSKELETALS: 'ItemsMusculoskeletals',
        MARITALSTATUSES: 'ItemsMaritalStatuses',
        MENTALSTATES: 'ItemsMentalStates',
        NUTRITIONS: 'ItemsNutritions',
        RESPIRATORIES: 'ItemsRespiratories',
        SKINS: 'ItemsSkins',
        TRANSPORTS: 'ItemsTransports',
        SUPPORTSYSTEMS: 'ItemsSupportSystems',
        URINES: 'ItemsUrines',
        SENSORYPERCEPTIONS: 'ItemsSensoryPerceptions'

    };
 

    constructor(public coreService: CoreService,
                private http: HttpClient,
                private cookieService: CookieService,
                public router: Router,
                private httpService: HttpService,
                private resourcesService: ResourcesService,
                private datepipe: DatePipe) {
    }

    patientsAvailableChanged = new EventEmitter<any>();

    patientsAdmissionsAvailableChanged = new EventEmitter<any>();

    patientsEvaluationsAvailableChanged = new EventEmitter<any>();

    patientsSectionChanged = new EventEmitter<any>();


    POST_Field(url: string, postData: string ) {
        console.log('post_Field');


        console.log('postDataString: ' + postData);
        return this.httpService.post (environment.URL_ROOT + '/' + url, postData)
            .pipe(map(r => {
                console.log(r);
                return r;
            }, catchError((err) => {
                const message = 'POST RESPONSE ERROR: ' + url;
                console.log(message);
                return err;
            })));
    }

    PATCH_Field(url: string, fieldName: string, fieldValue: any) {
        console.log('patchField');

        const postData = {
            [fieldName]: fieldValue
        };

        console.log('postDataString: ' + JSON.stringify(postData));
        return this.httpService.patch (environment.URL_ROOT + '/' + url, postData)
            .pipe(map(r => {
                console.log(r);
                return r;
            }, catchError((err) => {
                const message = 'PATCH RESPONSE ERROR: ' + url;
                console.log(message);
                return err;
            })));
    }


    savePatientSectionNotes(admission: string, note_type_id: string, content: string){
        console.log('savePatientSectionNotes: ');
        console.log('note_type_id: ', note_type_id);

        const postData = {
            admission,
            note_type_id,
            content
        };

        console.log('postData:');
        console.log(postData);

        return this.httpService.patch(environment.URL_ROOT + '/AdmissionNotes', postData)
            .pipe(map(r => {
                console.log(r);
                return r;
            }));
    }


    saveAdmissionSignature(base64String: string, admissionId: string){
        console.log('saveAdmissionSignature: ');

        const postData = {
            signature: base64String
        };

        console.log('postData:');
        console.log(postData);

        return this.httpService.patch(environment.URL_ROOT + '/TAdmissions?admission=eq.' + admissionId, postData)
            .pipe(map(r => {
                console.log(r);
                return r;
            }));
    }

    addPatientSectionItem(admissionId: string, PATH_ADD: string, itemValue: string, tableName: string) {
        console.log('addPatientSectionItem: ' );
        const postData = {
            // _method: 'POST',
            _table: tableName,
            _admission: admissionId,
            _item: itemValue
        };
        console.log(postData);

        return this.httpService.post(environment.URL_ROOT + '/rpc/EvalPost', postData)
            .pipe(map(r => {
                console.log(r);
                return r;
            }));
    }


    toggleEvaluationItem(admission: string, item: string) {
        const postData = {
            admission,
            item
        };

        return this.httpService.post(environment.URL_ROOT + '/TAdmissionEvaluationItems', postData)
              .pipe(map(r => {
                  console.log(r);
                  return r;
              }));
    }

    // removePatientSectionItem(admissionId: string, PATH_DELETE: string, itemValue: string, tableName: string) {
    //     console.log('removePatientSectionItem: ' );
    //     const postData = {
    //         _method: 'DELETE',
    //         _table: tableName,
    //         _admission: admissionId,
    //         _item: itemValue
    //     };
    //     console.log(postData);
    //
    //     return this.httpService.post(environment.URL_ROOT + '/rpc/EvalAPI', postData)
    //         .pipe(map(r => {
    //             console.log(r);
    //             return r;
    //         }));
    // }

    loadPatientsAdmissions(patientId: string){
        console.log('loadPatients');

        return this.httpService.get(environment.URL_ROOT + '/Admissions?patient=eq.' + patientId)
            .pipe(map(r => {
                console.log(r);
                return r;
            }));
    }

    loadPatientsAdmissionsByAdmissionId(admissionId: string){
        console.log('loadPatientsAdmissionsByAdmission');
        const postData = {
            _id: admissionId
        };

        return this.httpService.get (environment.URL_ROOT + '/Admissions?admission=eq.' + admissionId)
            .pipe(map(r => {
                console.log(r);
                return r;
            }));
    }


    loadPatientsEvaluation(admissionId: string){
        console.log('loadPatientsEvaluation');


        return this.httpService.get (environment.URL_ROOT + '/Admissions?admission=eq.' + admissionId)
            .pipe(map(r => {
                console.log(r);
                return r;
            }));
    }


    loadPatientsEvaluations(admissionId: string){
        console.log('loadPatientsEvaluations');


        return this.httpService.get (environment.URL_ROOT + '/Admissions?admission=eq.' + admissionId)
            .pipe(map(r => {
                console.log(r);
                return r;
            }));
    }

    loadPatient(patientId: string) {

        console.log('loadPatient');
        console.log('patientId: ', patientId);
        return this.httpService.get (environment.URL_ROOT + '/PatientsTable?username=eq.' + patientId)
            .pipe(map(r => {
                console.log(r);
                return r;
            }));
    }

    loadPatients() {
        console.log('loadPatients');

        return this.httpService.get (environment.URL_ROOT + '/PatientsTable')
            .pipe(map(r => {
                console.log(r);
                return r;
            }));
    }


    // region reports
    loadPatientReports(reportType: string, admissionId: string | null) {
        console.log('loadPatientReports');

        // make first character uppercase
        reportType = reportType[0].toUpperCase() + reportType.slice(1);

        const URL_TO_USE = environment.URL_ROOT + '/' + reportType + 'Reports?admission=eq.' + admissionId;

        console.log('URL_TO_USE: ' + URL_TO_USE);

        return this.httpService.get(URL_TO_USE)
            .pipe(map(r => {
                return r;
                // if (r.body.length > 1){
                //     const filteredReports = r.body.reverse().slice();
                //     return filteredReports;
                // } else {
                //     const emptyResult = []; //: never[] = [];
                //     return emptyResult;
                // }

            }));
    }
    //endregion


    editPatient(
        patientId: string,
        first_name: string,
        last_name: string,
        reg_no: string,
        email: string,
        birth_dt: string,
        marital_status: string,
        id_card: string,
        address: string,
        postcode: string,
        phone: string,
        profession: string,
        retired: boolean,
        religion: string,
        language: string) {


        const postData = {
            first_name       ,
            last_name        ,
            reg_no           ,
            email            ,
            birth_dt         ,
            marital_status   ,
            id_card          ,
            address          ,
            postcode         ,
            phone            ,
            profession       ,
            retired          ,
            religion         ,
            language
        };

        console.log(postData);

        return this.httpService.patch(environment.URL_ROOT + '/TPatients?id=eq.' + patientId, postData)
            .pipe(map(r => {
                console.log(r);
                return r;
            }));
    }

    addPatient(first_name: string,
               last_name: string,
               reg_no: string,
               email: string,
               birth_dt: string,
               marital_status: string,
               id_card: string,
               address: string,
               postcode: string,
               phone: string,
               profession: string,
               retired: boolean,
               religion: string,
               language: string) {

        const postData = {
            first_name       ,
            last_name        ,
            reg_no           ,
            email            ,
            birth_dt         ,
            marital_status   ,
            id_card          ,
            address          ,
            postcode         ,
            phone            ,
            profession       ,
            retired          ,
            religion         ,
            language
        };

        console.log(postData);

        return this.httpService.post(environment.URL_ROOT + '/TPatients', postData)
            .pipe(map(r => {
                console.log(r);
                return r;
            }));
    }

    addPatientAdmission
             (patientId: string,
               diagnosis: string,
               closest_relative: string,
               kinship: string,
               cr_phone: string,
               referral_from: string,
               hospital_admission_dt: string,
               hospital_exit_dt: string,
               death_dt: string,
               hospital_staff_involved: string) {

        const postData = {
            patient                       :  patientId                  ,
            diagnosis                  ,
            closest_relative           ,
            kinship                    ,
            cr_phone                   ,
            referral_from              ,
            hospital_admission_dt      ,
            hospital_exit_dt           ,
            death_dt                   ,
            hospital_staff_involved
        };

        console.log(postData);

        return this.httpService.post(environment.URL_ROOT + '/TAdmissions', postData)
            .pipe(map(r => {
                console.log(r);
                return r;
            }));
    }

    upsertEvaluation
   (
         action: string,
         admissionId: string,
         evaluation: string,
      // create_dt               :string,
         creator: string,
         admission: string,
         vision: string,
         hearing: string,
         respiratory: string,
         surgeries: string,
         alcohol: boolean,
         allergies: string,
         is_abused: boolean,
         reporter: string) {

        const userName = this.resourcesService.getUserName();

        const postData = {
            admission                 : admissionId            ,
          //  evaluation                : evaluation             ,
           // create_dt                 : create_dt              ,
          //  creator                   : creator                ,
            vision                 ,
            hearing                ,
            respiratory            ,
            surgeries              ,
            alcohol                ,
            allergies              ,
            is_abused              ,
            reporter,
            creator: userName
        };

        console.log(postData);

        if (action === 'edit'){
            return this.httpService.patch(environment.URL_ROOT + '/Evaluations?evaluation=eq.' + evaluation, postData)
                .pipe(map(r => {
                    console.log(r);
                    return r;
                }));
        }else{
            return this.httpService.post(environment.URL_ROOT + '/Evaluations', postData)
                .pipe(map(r => {
                    console.log(r);
                    return r;
                }));
        }
    }

    broadcastInfoAboutAddedHealthHistory(history: any) {
        this.patientsSectionChanged.emit(history);
    }

    broadcastInfoAboutAddedEvaluation(evaluation: any) {
        this.patientsEvaluationsAvailableChanged.emit(evaluation);
    }

    broadcastInfoAboutAddedPatient(patient: any) {
        this.patientsAvailableChanged.emit(patient);
    }

    broadcastInfoAboutAddedPatientAdmission(patientAdmission: any) {
        this.patientsAdmissionsAvailableChanged.emit(patientAdmission);
    }


    // loadEvaluationNotes(evalutationId: string){
    //     console.log('loadEvaluationNotes: ' + evalutationId);
    //     return this.httpService.get(environment.URL_ROOT + '/Evaluations?evaluation=eq.' + evalutationId)
    //     .pipe(map(r => {
    //         console.log(r);
    //         return r;
    //     }));
    // }

    loadAdmissionDetails(admissionId: string){
        console.log('loadAdmissionDetails: ' + admissionId);
        return this.httpService.get(environment.URL_ROOT + '/Admissions?admission=eq.' + admissionId)
            .pipe(map(r => {
                console.log(r);
                return r;
            }));
    }

    loadPatientSection(admissionId: string, resourceName: string){

        // const postData = {
        //     _method: 'GET',
        //     _table: resourceName,
        //     _admission: admissionId
        // };

        console.log('loadPatientSection: ' + resourceName);


        const url = '/T' +   resourceName.toLowerCase() + '?admission=eq.' + admissionId;

        // console.log('postData:');
        // console.log(postData);
       // return this.httpService.post(environment.URL_ROOT + '/rpc/EvalAPI' , postData)
        return this.httpService.get(environment.URL_ROOT + url )
            .pipe(map(r => {
                console.log(r);
                return r;
            }));
    }

    loadFormItems(resourceName: string) {
        console.log('loadFormItems: ' + resourceName);
        return this.httpService.get(environment.URL_ROOT + '/' + resourceName)
            .pipe(map(r => {

                const dropdownItems: FormDropdownSectionItem[] = [];
                r.forEach(x => {
                    dropdownItems.push(new FormDropdownSectionItem(x.name, x.id));
                });
                console.log(r);
                return dropdownItems;
            }));
    }




    loadAdmission(admissionId: string) {
        console.log('loadAdmission: ', admissionId);

        return this.httpService.get (environment.URL_ROOT + '/Admissions?admission_id=eq.' + admissionId)
            .pipe(map(r => {
                console.log(r);
                return r;
            }));
    }


    // region evaluation

    loadEvaluationTable() {
        console.log('loadEvaluationTable');
        return this.httpService.get (environment.URL_ROOT + '/EvaluationItems')
            .pipe(map(r => {
                console.log(r);
                return r;
            }));
    }

    loadEvaluationTableForSection(section_id: string) {
        console.log('loadEvaluationTable');
        return this.httpService.get (environment.URL_ROOT + '/EvaluationItems?section_id=eq.' + section_id)
            .pipe(map(r => {
                console.log(r);
                return r;
            }));
    }

    loadEvaluationReport(admissionId: string) {
        console.log('loadEvaluationReport');
        return this.httpService.get (environment.URL_ROOT + '/AdmissionEvaluationItems?admission=eq.' + admissionId)
            .pipe(map(r => {
                console.log(r);
                return r;
            }));
    }

    toggleEvaluation(admission: string | null, item: string) {
        console.log('toggleEvaluation: ' );
        const postData = {
            admission,
            item
        };

        console.log(postData);

        return this.httpService.post(environment.URL_ROOT + '/TAdmissionEvaluationItems', postData)
            .pipe(map(r => {
                console.log(r);
                return r;
            }));
    }
    //endregion


    loadItemsForHydration(hydrationUrl: string) {
        //console.log('loadItemsForHydration');

        return this.httpService.get(environment.URL_ROOT + '/' + hydrationUrl)
            .pipe(map(r => {
                //console.log(r.body);
                return r;
            }));
    }
}
