import { Component, OnInit } from '@angular/core';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {CoreService} from '../../../services/core.service';
import {PatientService} from '../../../services/patient.service';
import {PatientReportsService} from '../../../services/patientreports.service';
import {ModalController, Platform} from '@ionic/angular';
import {HttpService} from '../../../services/http.service';
import {TranslateService} from '@ngx-translate/core';
import {ReportsGenericModal2Component} from '../reports-generic-modal2/reports-generic-modal2.component';
import {TranslateDbService} from '../../../services/translate.db.service';

@Component({
  selector: 'app-report-generic-details',
  templateUrl: './report-generic-details.component.html',
  styleUrls: ['./report-generic-details.component.scss'],
})
export class ReportGenericDetailsComponent implements OnInit {

  reportId = '';
  reportType = '';
  reportDetails = null;
  patientDetails = null;
  TITLE = '';

  REPORT_FIELDS = null;
  ALL_TRANSLATIONS = [];

  private reportsNursingAvailableChangedSub: Subscription;

  constructor(private route: ActivatedRoute,
              public router: Router,
              public coreService: CoreService,
              public patientService: PatientService,
              public patientReportsService: PatientReportsService,
              public modalCtrl: ModalController,
              private httpService: HttpService,
              private platform: Platform,
              public translateDb: TranslateDbService,
              private translate: TranslateService) {
  }

  ngOnDestroy() {

  }

  ngOnInit() {
    if (!this.httpService.isLoggedIn()) {
      this.router.navigateByUrl('/login');
    }

    // this.translateDb.loadTranslations().subscribe(t => {
    //   this.ALL_TRANSLATIONS = t;
    // });


    this.translateDb.loadTranslations();

    this.reportId = this.route.snapshot.paramMap.get('reportId');
    this.reportType = this.route.snapshot.paramMap.get('reportType');

    console.log(this.patientReportsService.REPORT_FIELDS.get(this.reportType));

    this.loadReportDetails();

    this.loadReportFields();
  //  this.TITLE = this.reportType + ", reportId: " + this.reportId;

  }



  loadReportDetails(){
    this.patientReportsService.loadPatientReportDetails(this.reportType, this.reportId).subscribe(r => {
      console.log(r);
      if (r != null) {
        if (r.length > 0) {
          console.log('LOADING PATIENT reports DATA');
          console.log(r[0]);
          this.reportDetails = r[0];

          this.loadPatientDetails();
        }
      }
    }, err => {
      console.log(err);
      this.coreService.broadcastErrorMessage('Fetching patients reports failed ', err);
    });
  }

  loadPatientDetails(){
    this.patientService.loadPatient(this.reportDetails.patient).subscribe(r => {
      console.log(r);
      if (r != null) {
        if (r.length > 0) {
          console.log('LOADING PATIENT reports DATA');
          console.log(r[0]);
          this.patientDetails = r[0];


          this.TITLE = this.patientDetails.full_name + ' - ' + this.reportType + ' Report, reportId: ' + this.reportId;
        }
      }
    }, err => {
      console.log(err);
      this.coreService.broadcastErrorMessage('Fetching patients details failed ', err);
    });
  }


  loadReportFields() {
    this.patientReportsService.loadReportFields(this.reportType).subscribe(r => {
      this.REPORT_FIELDS = r;

      const R_REPLACED_VIEW_FIELDS = [];

      r.forEach(i => {

        const colNameToCheck = 'vf_' + i.col;
        let shouldReplace = false;
        let colNameToReplace = '';

        r.forEach(ii => {
          if (colNameToCheck.indexOf('vf_vf_') === -1){
            if (ii.col.indexOf(colNameToCheck) >= 0) {
              shouldReplace = true;
              colNameToReplace = ii.col.replace('vf_', '');
            }
          }
        });

        if (shouldReplace) {
          if (i.col === 'vf_' + colNameToReplace) {
            R_REPLACED_VIEW_FIELDS.push(i);
          } else {
            if (i.col === colNameToReplace ) {
            } else {
              R_REPLACED_VIEW_FIELDS.push(i);
            }
          }
        } else {
          R_REPLACED_VIEW_FIELDS.push(i);
        }
      });

      this.REPORT_FIELDS = R_REPLACED_VIEW_FIELDS;
    }, err => {
      console.log(err);
      this.coreService.broadcastErrorMessage('Fetching REPORT_FIELDS failed ', err);
    });
  }


}
