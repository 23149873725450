import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {CoreService} from '../../../services/core.service';
import {PatientService} from '../../../services/patient.service';
import {PatientReportsService} from '../../../services/patientreports.service';
import {ModalController, Platform} from '@ionic/angular';
import {HttpService} from '../../../services/http.service';
import {TranslateService} from '@ngx-translate/core';
import {ReportsGenericModal2Component} from '../reports-generic-modal2/reports-generic-modal2.component';
import {ReportsCatheterComponent} from '../reports-catheter/reports-catheter.component';
import {TranslateDbService} from '../../../services/translate.db.service';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import {Capacitor, Plugins} from '@capacitor/core';

@Component({
  selector: 'app-reports-generic',
  templateUrl: './reports-generic.component.html',
  styleUrls: ['./reports-generic.component.scss'],
})
export class ReportsGenericComponent implements OnInit, OnDestroy {

  admissionId = '';
  reportType = '';

  currentReportFields = [];
  currentReportFieldsValues: Map<string, string> = new Map([
    ['x', '']
  ]);

  TITLE = '';

  patientId = '';

  patientData_first_name = '';
  patientData_last_name = '';

  componentItems = [];
  componentItemsFiltered = [];

  itemsVisibilityState = [];

  searchPhrase = '';
  isLoading = false;

  REPORT_FIELDS = null;

  ALL_TRANSLATIONS = [];

  private reportsNursingAvailableChangedSub: Subscription;

  constructor(private route: ActivatedRoute,
              public router: Router,
              public coreService: CoreService,
              public patientService: PatientService,
              public patientReportsService: PatientReportsService,
              public modalCtrl: ModalController,
              private httpService: HttpService,
              private platform: Platform,
              public translateDb: TranslateDbService,
              private translate: TranslateService,
              private screenOrientation: ScreenOrientation) {
  }

  ngOnDestroy() {
    if (this.reportsNursingAvailableChangedSub) {
      this.reportsNursingAvailableChangedSub.unsubscribe();
    }
  }

  ngOnInit() {
    if (!this.httpService.isLoggedIn()) {
      this.router.navigateByUrl('/login');
    }

    console.log('platform android: ',    this.platform.is('android'));
    //this.platform.ready().then(() => {
      //if (Capacitor.isPluginAvailable('ScreenOrientation')) {

        // if (this.platform.is('android')) {
        //   console.log('current orientation:');
        //   // get current
        //   console.log(this.screenOrientation.type); // logs the current orientation, example: 'landscape'
        //
        //
        //   console.log('setting landscape orientation:');
        //   // set to landscape
        //   this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.LANDSCAPE);
        //
        //   // allow user rotate
        //   this.screenOrientation.unlock();
        //
        //   // detect orientation changes
        //   this.screenOrientation.onChange().subscribe(
        //       () => {
        //         console.log('Orientation Changed');
        //       }
        //   );
        // }
     // }
    //});

    this.admissionId = this.route.snapshot.paramMap.get('admissionId');
    this.reportType = this.route.snapshot.paramMap.get('reportType');

    console.log('using reports:');
    console.log('reportType: ', this.reportType);
    console.log(this.patientReportsService.REPORT_FIELDS.get(this.reportType));

    this.currentReportFields = this.patientReportsService.REPORT_FIELDS.get(this.reportType);

    this.isLoading = true;

    this.generateReportTitle();

    this.loadReportFields();
    this.loadReports();


    this.reportsNursingAvailableChangedSub = this.patientReportsService.reportsNursingAvailableChanged
        .subscribe( r => {
              //email
              console.log('reportsNursingAvailableChangedSub ACTIVATED!')
              this.loadReports( );
            }
        );

    this.translateDb.loadTranslations().subscribe(t => {
      this.ALL_TRANSLATIONS = t;
    });
  }


  loadReportFields() {
    this.patientReportsService.loadReportFields(this.reportType).subscribe(r => {
      this.REPORT_FIELDS = r;
    }, err => {
      console.log(err);
      this.coreService.broadcastErrorMessage('Fetching REPORT_FIELDS failed ', err);
    });
  }


  generateReportTitle() {
    let trnsName = 'patient_reports.' + this.reportType.toUpperCase() + '_REPORTS';
    this.translate.get(trnsName).subscribe(t=>{
      this.TITLE = t + ", Admission: " + this.admissionId;
    });
    //   'GENERIC REPORTS: Nursing Reports, Admission: ' + admissionId + ', '
  }

  loadReports(){
    this.patientReportsService.loadPatientReports(this.reportType, this.admissionId).subscribe(r => {
      console.log(r);
      this.isLoading = false;
      if (r != null) {
        if (r.length > 0) {
          console.log('LOADING PATIENT reports DATA');
          console.log(r[0]);
          console.log('component Items:');
          console.log(r);
          this.componentItems = r;
          this.componentItemsFiltered = r;

          this.componentItemsFiltered.forEach(i => {
            this.itemsVisibilityState.push(false);
          });
        }
      }
    }, err => {
      console.log(err);
      this.coreService.broadcastErrorMessage('Fetching patients reports failed ', err);
      this.isLoading = false;
    });
  }

  loadReportDetails(reportItem: any){

    console.log(reportItem);
    this.router.navigateByUrl('report-generic-details/' + this.reportType + '/' + reportItem.id);
  }



  openAddReportModal() {
    console.log('xXXXXXXXXXXXXXXXXX' + this.reportType);
    if (this.reportType === 'catheter'){
      this.modalCtrl
          .create({
            component: ReportsCatheterComponent,
            componentProps: { reportType: this.reportType, admissionId: this.admissionId, showHeader: false, action: "add", reportItem: null  },
            cssClass: 'bg-none'
          })
          .then(modalEl => {
            modalEl.present();
            return modalEl.onDidDismiss();
          });
    }else{
      this.modalCtrl
          .create({
            component: ReportsGenericModal2Component,
            componentProps: { reportType: this.reportType, admissionId: this.admissionId, showHeader: false, action: "add", reportItem: null  },
            cssClass: 'bg-none'
          })
          .then(modalEl => {
            modalEl.present();
            return modalEl.onDidDismiss();
          });
    }
  }
}
