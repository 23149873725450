import {Component, Input, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {CoreService} from "../../../services/core.service";
import {PatientService} from "../../../services/patient.service";
import {ModalController} from "@ionic/angular";
import {HttpService} from "../../../services/http.service";
import {TranslateService} from "@ngx-translate/core";
import {TranslationsCommonService} from "../../../services/translations.common.service";

@Component({
  selector: 'app-modal-patient-admission-upsert',
  templateUrl: './modal-patient-admission-upsert.component.html',
  styleUrls: ['./modal-patient-admission-upsert.component.scss'],
})
export class ModalPatientAdmissionUpsertComponent implements OnInit {
  @Input() patientId: string;
  @Input() showHeader: number;

  @Input() action: string;
  @Input() patientAdmissionItem: any;

  patientid_cardDisabled = false;

  diagnosis                ="";
  closest_relative         ="";
  kinship                  ="";
  cr_phone                 ="";
  referral_from            ="";
  hospital_admission_dt    ="";
  hospital_exit_dt         ="";
  death_dt                 ="";
  hospital_staff_involved  ="";


  isLoading = false;


  formName = 'addPatient';
  private submitSectionSub: Subscription;

  constructor(private route: ActivatedRoute,
              public router: Router,
              public coreService: CoreService,
              public patientService: PatientService,
              public modalCtrl: ModalController,
              private httpService: HttpService,
              private translate: TranslateService,
              public translationsCommonService: TranslationsCommonService) {
  }

  ngOnDestroy() {
    if (this.submitSectionSub) {
      this.submitSectionSub.unsubscribe();
    }
  }

  ngOnInit() {
    if (!this.httpService.isLoggedIn()) {
      this.router.navigateByUrl('/login');
    }



    if (this.action === "edit") {
      console.log('modal action: ' + this. action);
      console.log('patientAdmissionItem:');
      console.log(this.patientAdmissionItem);

      this.patientid_cardDisabled = true;


      this.diagnosis                          =                  this.patientAdmissionItem.diagnosis               ;
      this.closest_relative                   =                  this.patientAdmissionItem.closest_relative        ;
      this.kinship                            =                  this.patientAdmissionItem.kinship                 ;
      this.cr_phone                           =                  this.patientAdmissionItem.cr_phone                ;
      this.referral_from                      =                  this.patientAdmissionItem.referral_from           ;
      this.hospital_admission_dt              =                  this.patientAdmissionItem.hospital_admission_dt   ;
      this.hospital_exit_dt                   =                  this.patientAdmissionItem.hospital_exit_dt        ;
      this.death_dt                           =                  this.patientAdmissionItem.death_dt                ;
      this.hospital_staff_involved            =                  this.patientAdmissionItem.hospital_staff_involved ;
    }



    this.submitSectionSub = this.coreService.submitSectionsChanged.subscribe(
        submitSectionDataItems  => {
          console.log('submitSectionSub: ');
          console.log(submitSectionDataItems);

          const submitSectionItem = submitSectionDataItems.find(x => x.sectionName === this.formName);

          if (submitSectionItem != null){
            if (submitSectionItem.cancelBroadcasted === true) {
              //this.router.navigateByUrl('/vaccine-lots'  );
              this.modalCtrl.dismiss();
            }

            if (submitSectionItem.submitBroadcasted === true) {
              this.onAddPatientAdmission();
            }
          }
        }
    );
  }



  onAddPatientAdmission() {
    console.log('onAddPatientAdmission');
    this.isLoading = true;
    this.patientService.addPatientAdmission(
        this.patientId,
        this.diagnosis                        ,
        this.closest_relative                 ,
        this.kinship                          ,
        this.cr_phone                         ,
        this.referral_from                    ,
        this.hospital_admission_dt            ,
        this.hospital_exit_dt                 ,
        this.death_dt                         ,
        this.hospital_staff_involved
    ).subscribe(r => {
      console.log(r);
      this.isLoading = false;
      this.coreService.broadcastToastMessage(this.translationsCommonService.TRNSL_DATA_SAVED_SUCCESSFULLY, false);

      this.patientService.broadcastInfoAboutAddedPatientAdmission(r);

      this.modalCtrl.dismiss();
      //this.router.navigateByUrl('/vaccine-lots');
    }, err => {
      console.log(err);
      this.coreService.broadcastErrorMessage(this.translationsCommonService.TRNSL_DATA_SAVING_FAILED, err);
      this.isLoading = false;
    });
  }

  checkIfFormValid() {
    return true;
  }
}
