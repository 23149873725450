import { Component, OnInit } from '@angular/core';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {CoreService} from '../../services/core.service';
import {PatientService} from '../../services/patient.service';
import {ModalController, Platform} from '@ionic/angular';
import {HttpService} from '../../services/http.service';
import {TranslateService} from '@ngx-translate/core';
import {ModalPatientsUpsertComponent} from '../shared/modal-patients-upsert/modal-patients-upsert.component';
import {ReportsGenericModal2Component} from '../reports/reports-generic-modal2/reports-generic-modal2.component';
import {ReportsCatheterComponent} from '../reports/reports-catheter/reports-catheter.component';
import {WoundsFormComponent} from '../wounds/wounds-form/wounds-form.component';
import {TranslateDbService} from '../../services/translate.db.service';


//declare var gnuBarChart: boolean;
@Component({
  selector: 'app-patient-menu',
  templateUrl: './patient-menu.component.html',
  styleUrls: ['./patient-menu.component.scss'],
})
export class PatientMenuComponent implements OnInit {
  patientId = '';
  patientItem = null;

  isLoading = false;

  formItem_MARITALSTATUS_LABEL = '';

  TITLE = '';


  CHART_DATA_BP1 = [];
  CHART_DATA_BP1_string = '';

  CHART_DATA_TEMPERATURE = [];
  CHART_DATA_TEMPERATURE_string = '';

  CHART_DATA_GLUCOSE = [];
  CHART_DATA_GLUCOSE_string = '';


  CHART_DATA_PULSE = [];
  CHART_DATA_PULSE_string = '';

  CHART_DATA_RESPRATE = [];
  CHART_DATA_RESPRATE_string = '';

  CHART_DATA_INR = [];
  CHART_DATA_INR_string = '';

  ALL_TRANSLATIONS = [];

    // asign the global variable to a component property
 //   gnuBarChart: boolean = gnuBarChart;

  private patientsAvailableChangedSub: Subscription;

  constructor(private route: ActivatedRoute,
              public router: Router,
              public coreService: CoreService,
              public patientService: PatientService,
              public modalCtrl: ModalController,
              private httpService: HttpService,
              private platform: Platform,
              private translate: TranslateService,
              public translateDb: TranslateDbService,) {
  }

  ngOnDestroy() {
    if (this.patientsAvailableChangedSub) {
      this.patientsAvailableChangedSub.unsubscribe();
    }
  }

  ngOnInit() {
     if (!this.httpService.isLoggedIn()) {
       this.router.navigateByUrl('/login');
     }

     this.patientId = this.route.snapshot.paramMap.get('patientId');
     this.isLoading = true;
     this.patientService.loadPatient(this.patientId).subscribe(r => {
     console.log(r);
     this.patientItem = r[0];

     console.log('patientItem');
     console.log(this.patientItem);
     this.isLoading = false;

     this.loadFormItems(this.patientService.PATIENT_FORM_ITEMS.MARITALSTATUSES);

     this.generateTitle();

     // this.loadCharts('visiglu');
     // this.loadCharts('antigent');
         this.translateDb.loadTranslations();

    }, err => {
      console.log(err);
      this.coreService.broadcastErrorMessage('Fetching patients failed ', err);
      this.isLoading = false;
    });
  }
  //
  // loadCharts(reportType: string) {
  //     this.patientService.loadPatientReports(reportType, this.patientItem.last_admission).subscribe(r => {
  //         console.log('visiglu load result');
  //         console.log(r);
  //
  //         const CHART_ITEMS_LIMIT = -20;
  //
  //         r.map(rr => {
  //             if (reportType === 'visiglu') {
  //                 // {"date":"2022-01-24T07:20:25.311Z","low":60,"high":203}
  //                 // this.CHART_DATA_BP1.push(rr.bp_diastolic );
  //                 this.CHART_DATA_BP1.push('{ "date": "' + rr.create_dt + '", "low": ' + rr.bp_diastolic + ', "high": ' + rr.bp_systolic + '}');
  //
  //                 this.CHART_DATA_TEMPERATURE.push(rr.temperature);
  //                 this.CHART_DATA_GLUCOSE.push(rr.glucose);
  //                 this.CHART_DATA_PULSE.push(rr.pulse);
  //                 this.CHART_DATA_RESPRATE.push(rr.respiratory_rate);
  //
  //                 this.CHART_DATA_BP1 = this.CHART_DATA_BP1.slice(CHART_ITEMS_LIMIT);
  //                 this.CHART_DATA_TEMPERATURE = this.CHART_DATA_TEMPERATURE.slice(CHART_ITEMS_LIMIT);
  //                 this.CHART_DATA_GLUCOSE = this.CHART_DATA_GLUCOSE.slice(CHART_ITEMS_LIMIT);
  //                 this.CHART_DATA_PULSE = this.CHART_DATA_PULSE.slice(CHART_ITEMS_LIMIT);
  //                 this.CHART_DATA_RESPRATE = this.CHART_DATA_RESPRATE.slice(CHART_ITEMS_LIMIT);
  //
  //                 this.CHART_DATA_BP1_string = '[' + this.CHART_DATA_BP1.map(x => x).join(', ') + ']';
  //                 console.log('CHART_DATA_BP1_string');
  //                 console.log(this.CHART_DATA_BP1_string);
  //                 this.CHART_DATA_TEMPERATURE_string = this.CHART_DATA_TEMPERATURE.map(x => x).join(', ');
  //                 this.CHART_DATA_GLUCOSE_string = this.CHART_DATA_GLUCOSE.map(x => x).join(', ');
  //                 this.CHART_DATA_PULSE_string = this.CHART_DATA_PULSE.map(x => x).join(', ');
  //                 this.CHART_DATA_RESPRATE_string = this.CHART_DATA_RESPRATE.map(x => x).join(', ');
  //
  //                 this.loadChartData('barContainer_BP1', this.CHART_DATA_BP1_string, this.CHART_DATA_BP1.slice(-1).toString());
  //                 this.loadChartData('barContainer_Temperature', this.CHART_DATA_TEMPERATURE_string, this.CHART_DATA_TEMPERATURE.slice(-1).toString());
  //                 this.loadChartData('barContainer_Glucose', this.CHART_DATA_TEMPERATURE_string, this.CHART_DATA_GLUCOSE.slice(-1).toString());
  //                 this.loadChartData('barContainer_Pulse', this.CHART_DATA_PULSE_string, this.CHART_DATA_PULSE.slice(-1).toString());
  //                 this.loadChartData('barContainer_RespiratoryRate', this.CHART_DATA_RESPRATE_string, this.CHART_DATA_RESPRATE.slice(-1).toString());
  //
  //             } else if (reportType === 'antigent') {
  //                 this.CHART_DATA_INR.push(rr.inr);
  //                 this.CHART_DATA_INR = this.CHART_DATA_INR.slice(CHART_ITEMS_LIMIT);
  //                 this.CHART_DATA_INR_string = this.CHART_DATA_INR.map(x => x).join(', ');
  //                 this.loadChartData('barContainer_INR', this.CHART_DATA_INR_string, this.CHART_DATA_INR.slice(-1).toString());
  //             }
  //         });
  //     });
  // }
  //
  // loadChartData(containerId: string, chartData: string, average: string) {
  //     const elParent = document.getElementById(containerId) as HTMLElement;
  //     const elChild = elParent.firstChild as HTMLElement;
  //     // console.log(elChild.getAttributeNames());
  //
  //     console.log(containerId + '_string:');
  //     console.log(chartData);
  //     console.log('average-value: ' + average);
  //
  //     elChild.setAttribute('chart-data', chartData);
  //     elChild.setAttribute('average-value', average);
  // }

  generateTitle() {
    console.log('patientItem');
    console.log(this.patientItem);
    this.TITLE = this.patientItem.full_name + ' (' + this.patientItem.age + ')';
  }

  loadFormItems(itemName: string){

    this.patientService.loadFormItems(itemName).subscribe(
        formItems => {

          console.log(formItems);

          formItems.forEach(x => {
            if(x.id === this.patientItem.marital_status){
              this.formItem_MARITALSTATUS_LABEL = x.name;
            }
          });
        });
  }

  openAdmissions(id: string) {
    this.router.navigateByUrl('/patients-admissions/' + id);
  }

  openAddPatientModal() {
    this.modalCtrl
        .create({
          component: ModalPatientsUpsertComponent,
          componentProps: {  showHeader: false, action: "add", patientItem: null  },
          cssClass: 'bg-none'
        })
        .then(modalEl => {
          modalEl.present();
          return modalEl.onDidDismiss();
        });
  }

  call(phone: string){
    alert('calling ' + phone);
  }

  editPatient(patientItem: any) {
    this.modalCtrl
        .create({
          component: ModalPatientsUpsertComponent,
          componentProps: {  showHeader: false, action: "edit", patientItem: patientItem  },
          cssClass: 'bg-none'
        })
        .then(modalEl => {
          modalEl.present();
          return modalEl.onDidDismiss();
        });
  }



  openAddReportModal(reportType: string, admissionId: string) {

      if (reportType === 'catheter'){
          this.modalCtrl
              .create({
                  component: ReportsCatheterComponent,
                  componentProps: { reportType: reportType, admissionId: admissionId, showHeader: false, action: "add", reportItem: null  },
                  cssClass: 'bg-none'
              })
              .then(modalEl => {
                  modalEl.present();
                  return modalEl.onDidDismiss();
              });
      } else {
          this.modalCtrl
              .create({
                  component: ReportsGenericModal2Component,
                  componentProps: { reportType: reportType, admissionId: admissionId, showHeader: false, action: "add", reportItem: null  },
                  cssClass: 'bg-none'
              })
              .then(modalEl => {
                  modalEl.present();
                  return modalEl.onDidDismiss();
              });
      }
  }
}
