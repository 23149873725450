import { Component, OnInit } from '@angular/core';
import {Subscription} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {CoreService} from "../../../services/core.service";
import {PatientService} from "../../../services/patient.service";
import {ModalController, Platform} from "@ionic/angular";
import {HttpService} from "../../../services/http.service";
import {TranslateService} from "@ngx-translate/core";
import {ModalPatientsUpsertComponent} from "../../shared/modal-patients-upsert/modal-patients-upsert.component";

@Component({
  selector: 'app-reports-menu',
  templateUrl: './reports-menu.component.html',
  styleUrls: ['./reports-menu.component.scss'],
})
export class ReportsMenuComponent implements OnInit {

  isLoading = false;
  admissionId = '';
  constructor(private route: ActivatedRoute,
              public router: Router,
              public coreService: CoreService,
              public patientService: PatientService,
              public modalCtrl: ModalController,
              private httpService: HttpService,
              private platform: Platform,
              private translate: TranslateService) {
  }


  ngOnInit() {
    if (!this.httpService.isLoggedIn()) {
      this.router.navigateByUrl('/login');
    }

    this.isLoading = false;

    this.admissionId = this.route.snapshot.paramMap.get('admissionId');
  }

}
