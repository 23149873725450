import {Component, Input, OnInit} from '@angular/core';
import {CoreService} from '../../../services/core.service';
import {DatePickerData} from '../datepickerdata.model';
import {SubmitSectionData} from '../submitsectiondata.model';

@Component({
  selector: 'app-submit-section',
  templateUrl: './submit-section.component.html',
  styleUrls: ['./submit-section.component.scss'],
})
export class SubmitSectionComponent implements OnInit {
  @Input() isDisabled: boolean;
  @Input() sectionName: string;

  constructor(private coreService: CoreService) { }

  ngOnInit() {}

  broadcastCancel() {
    console.log('broadcastCancel');
    this.coreService.setSubmitSectionUpdate(new SubmitSectionData(this.isDisabled, true, false, this.sectionName));
  }

  broadcastSave() {
    console.log('broadcastSave');
    this.coreService.setSubmitSectionUpdate(new SubmitSectionData(this.isDisabled, false, true, this.sectionName));
  }

}
