import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import {Router} from '@angular/router';
import {HttpService} from './http.service';
import {DatePipe} from '@angular/common';
// import {TRANSLATIONS_EL} from './shared/resources/resources.el';
// import {TRANSLATIONS_EN} from './shared/resources/resources.en';

@Injectable({
    providedIn: 'root'
})
export class ResourcesService {
    private URL_ROOT = 'https://api.home-care.app';
    private langCookie = 'en';
    private userName = '';

    constructor(private http: HttpClient,
                private cookieService: CookieService,
                public router: Router,
                private httpService: HttpService,
                private datepipe: DatePipe) {}

    public getLangCode() {

        this.langCookie = this.cookieService.get('gnu_lang');

        if (this.langCookie === undefined || this.langCookie === null) {
            this.langCookie = 'en';
        }

        return this.langCookie;
    }

    public getUserName() {

        const token = this.cookieService.get('token');

        if (this.userName === undefined || this.langCookie === null) {
            this.userName = 'UNKNOWN';
        } else {
            console.log('token: ' + token);
            const tokenData = this.parseJwt(token);
            console.log('decoded token: ');
            console.log(tokenData);

            this.userName = tokenData.name;
        }

        return this.userName;
    }

    parseJwt(token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    };
}
