import {Component, Input, OnInit} from '@angular/core';
import {CoreService} from '../../../services/core.service';
import {PatientService} from '../../../services/patient.service';

@Component({
  selector: 'app-patchable-input-text',
  templateUrl: './patchable-input-text.component.html',
  styleUrls: ['./patchable-input-text.component.scss'],
})
export class PatchableInputTextComponent implements OnInit {
  @Input() INPUT_TYPE: string;
  @Input() FIELD_NAME: string;
  @Input() FIELD_VALUE: string;
  @Input() IS_DISABLED: boolean;
  @Input() LABEL_TEXT: string;
  @Input() PLACEHOLDER_TEXT: string;
  @Input() PATCH_URL: string;
  @Input() CSS_CLASSES: string;
  @Input() ROWS: string;
  @Input() LABEL_VISIBLE = true;
  @Input() SOURCE_FOR_NOTIFICATION = '';
  @Input() ITEM_ID = '';
  @Input() LABEL_LEFT = false;
  @Input() PATCH_ACTION = true;
  @Input() READ_ONLY = false;
  @Input() READ_ONLY_CLICK_DISABLED = false;

  @Input() OVERRIDE_POST: boolean; // true will force action sent by POST
  @Input() JSONSTRINGPOSTDATA: string;

  PLACEHOLDER_TEXT_ALT = '';

  fieldActive = false;
  copiedToClipboard = false;

  // cssClassesUpdated = '';
  saveStatus = 'initial';
  constructor(private coreService: CoreService,
              private patientService: PatientService) {

    this.INPUT_TYPE = 'text';
    this.FIELD_NAME = '';
    this.FIELD_VALUE = '';
    this.IS_DISABLED = false;
    this.LABEL_TEXT = '';
    this.PLACEHOLDER_TEXT = '';
    this.PATCH_URL = '';
    this.CSS_CLASSES = '';
    this.ROWS = '1';
    this.SOURCE_FOR_NOTIFICATION = '';
    this.ITEM_ID = '';
    this.LABEL_LEFT = false;
    this.PATCH_ACTION = true;
    this.READ_ONLY = false;
    this.READ_ONLY_CLICK_DISABLED = false;

    this.OVERRIDE_POST = false;
    this.JSONSTRINGPOSTDATA = '';
  }

  ngOnInit(): void {
    //  this.cssClassesUpdated = this.CSS_CLASSES;
    //   console.log('cssClassesUpdated: ', this.cssClassesUpdated);
  }

  enableEditing() {

    if (this.READ_ONLY_CLICK_DISABLED === false) {

      this.READ_ONLY = false;
    }
  }

  executeAction() {

    console.log('executing action...');

    if (this.OVERRIDE_POST){
      this.postField();
    } else {
      this.patchField();
    }

    // 0 is creation mode
    // if(this.ITEM_ID != '0'){
    //   setTimeout(() => {
    //     this.READ_ONLY = true;
    //
    //   }, 1500);
    // }

  }

  patchField() {
    this.fieldActive = false;

    this.copiedToClipboard = false;
    if (this.PATCH_ACTION) {
      //  this.cssClassesUpdated = this.CSS_CLASSES;

      this.coreService.broadcast_HEADER_DIAGNOSTIC_MESSAGE(this.coreService.DIAGNOSTIC_MESSAGE_UPDATING);

      this.patientService.PATCH_Field(this.PATCH_URL, this.FIELD_NAME, this.FIELD_VALUE).subscribe(r => {
        this.setSavedStatus();

        // @ts-ignore

        this.coreService.broadcast_PATCHABLE_INPUT_UPDATED(this.FIELD_NAME, this.FIELD_VALUE, this.SOURCE_FOR_NOTIFICATION, this.ITEM_ID);
        this.coreService.broadcast_HEADER_DIAGNOSTIC_MESSAGE(this.coreService.DIAGNOSTIC_MESSAGE_OK);
      }, err => {
        this.saveStatus = 'failed';
        this.coreService.formatAndBroadcastDiagnosticMessage(err);
      });
    }else {

      this.coreService.broadcast_PATCHABLE_INPUT_UPDATED(this.FIELD_NAME, this.FIELD_VALUE, this.SOURCE_FOR_NOTIFICATION, this.ITEM_ID);
      console.log(this.FIELD_NAME + ' - PATCH ACTION SUPPRESSED!');
    }
  }

  postField() {

    this.fieldActive = false;

    this.copiedToClipboard = false;
    const updatedPostData = this.JSONSTRINGPOSTDATA.replace('qqqREPLACEqqq', this.FIELD_VALUE);

    console.log('updatedPostData');
    console.log(updatedPostData);

    if (this.PATCH_ACTION) {
      //  this.cssClassesUpdated = this.CSS_CLASSES;

      this.coreService.broadcast_HEADER_DIAGNOSTIC_MESSAGE(this.coreService.DIAGNOSTIC_MESSAGE_UPDATING);

      this.patientService.POST_Field(this.PATCH_URL, updatedPostData).subscribe(r => {

        this.setSavedStatus();
        // @ts-ignore

        this.coreService.broadcast_PATCHABLE_INPUT_UPDATED(this.FIELD_NAME, this.FIELD_VALUE, this.SOURCE_FOR_NOTIFICATION, this.ITEM_ID);
        this.coreService.broadcast_HEADER_DIAGNOSTIC_MESSAGE(this.coreService.DIAGNOSTIC_MESSAGE_OK);
      }, err => {
        this.saveStatus = 'failed';
        this.coreService.formatAndBroadcastDiagnosticMessage(err);
      });
    } else {

      this.coreService.broadcast_PATCHABLE_INPUT_UPDATED(this.FIELD_NAME, this.FIELD_VALUE, this.SOURCE_FOR_NOTIFICATION, this.ITEM_ID);
      console.log(this.FIELD_NAME + ' - POST ACTION SUPPRESSED!');
    }
  }

  setSavedStatus() {
    this.saveStatus = 'saved';
    setTimeout(() => {
      this.saveStatus = '';

    }, 1000);
  }

  activateField() {
    // apply classes here etc...
    this.saveStatus = '';
    this.fieldActive = true;
  }

  getInputClass(initialClass: string) {

    initialClass = this.CSS_CLASSES + ' ';

    if (this.saveStatus === 'saved') {
      return initialClass + ' ' + 'comhese-field-saved';
    } else if (this.saveStatus === 'failed') {
      return initialClass + ' ' + 'comhese-field-save-fail';
    } else {
      if (this.fieldActive){

        return initialClass + ' ' + 'comhese-field-gotfocus';
      }else{
        return initialClass;
      }

    }
  }

  // copyToClipboard(inputElement: { select: () => void; setSelectionRange: (arg0: number, arg1: number) => void; }) {
  //   inputElement.select();
  //   document.execCommand('copy');
  //   inputElement.setSelectionRange(0, 0);
  //
  //   this.copiedToClipboard = true;
  // }

}
