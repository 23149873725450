import {Component, OnDestroy, OnInit} from '@angular/core';
import {CoreService} from '../../../services/core.service';
import {ToastController} from '@ionic/angular';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-toast-notification',
  templateUrl: './toast-notification.component.html',
  styleUrls: ['./toast-notification.component.scss'],
})
export class ToastNotificationComponent implements OnInit, OnDestroy {


    toastMessageChangedSub: Subscription;
  constructor(public coreService: CoreService,
              private toastController: ToastController) { }

  ngOnDestroy() {
    if (this.toastMessageChangedSub) {
      this.toastMessageChangedSub.unsubscribe();
    }


  }

  ngOnInit() {
    this.toastMessageChangedSub = this.coreService.toastMessageChanged.subscribe(
        toastData  => {
          console.log('toast-notification.toastMessageChangedSub: ');
          console.log(toastData);
           this.presentToast(toastData.message, toastData.isError).then(x =>{
               console.log('toast presented!');
           });
        }
    );
  }

  async presentToast(toastMessage: string, isError: boolean = false) {

    let colorToUse = 'primary';

    if (isError) {
      colorToUse = 'danger';
    }

     const toast = await this.toastController.create({
      position: 'top',
      message: toastMessage,
      duration: 5000,
      color: colorToUse,
      buttons: [ {
          text: 'x',
          role: 'cancel',
          handler: () => {
               toast.dismiss();
          }
        }
      ]
    })


     await toast.present();
  }
}
