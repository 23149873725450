import {Component, Input, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {CoreService} from "../../../services/core.service";
import {NurseService} from "../../../services/nurse.service";
import {ModalController} from "@ionic/angular";
import {HttpService} from "../../../services/http.service";
import {TranslateService} from "@ngx-translate/core";
import {TranslationsCommonService} from "../../../services/translations.common.service";

@Component({
  selector: 'app-modal-visitors-upsert',
  templateUrl: './modal-visitors-upsert.component.html',
  styleUrls: ['./modal-visitors-upsert.component.scss'],
})
export class ModalVisitorsUpsertComponent implements OnInit {
  @Input() showHeader: number;

  @Input() action: string;
  @Input() visitorItem: any;

  visitorNameDisabled = false;
  visitorEmailDisabled = false;

  visitorName = "";
  visitorEmail= "";
  visitorType= "";
  visitorFirstName= "";
  visitorLastName= "";
  visitorIdCard= "";
  visitorPhone= "";

  isLoading = false;


  formName = 'addVisitor';
  private submitSectionSub: Subscription;

  constructor(private route: ActivatedRoute,
              public router: Router,
              public coreService: CoreService,
              private nurseService: NurseService,
              public modalCtrl: ModalController,
              private httpService: HttpService,
              private translate: TranslateService,
              public translationsCommonService: TranslationsCommonService) {
  }

  ngOnDestroy() {
    if (this.submitSectionSub) {
      this.submitSectionSub.unsubscribe();
    }
  }

  ngOnInit() {
    if (!this.httpService.isLoggedIn()) {
      this.router.navigateByUrl('/login');
    }



    if(this.action === "edit"){
      console.log('modal action: ' + this. action);
      console.log('visitorItem:');
      console.log(this.visitorItem);

      this.visitorNameDisabled = true;
      this.visitorEmailDisabled = true;

      this.visitorName          = this.visitorItem.name     ;
      this.visitorEmail         = this.visitorItem.email    ;
      this.visitorType          = this.visitorItem.type     ;
      this.visitorFirstName     = this.visitorItem.first_name;
      this.visitorLastName      = this.visitorItem.last_name ;
      this.visitorIdCard        = this.visitorItem.id_card   ;
      this.visitorPhone         = this.visitorItem.phone    ;
    }

    this.submitSectionSub = this.coreService.submitSectionsChanged.subscribe(
        submitSectionDataItems  => {
          console.log('submitSectionSub: ');
          console.log(submitSectionDataItems);

          const submitSectionItem = submitSectionDataItems.find(x => x.sectionName === this.formName);

          if (submitSectionItem != null){
            if (submitSectionItem.cancelBroadcasted === true) {
              //this.router.navigateByUrl('/vaccine-lots'  );
              this.modalCtrl.dismiss();
            }

            if (submitSectionItem.submitBroadcasted === true) {
              this.onAddNurse();
            }
          }
        }
    );
  }



  onAddNurse() {
    console.log('onAddNurse');
    this.isLoading = true;
    this.nurseService.addNurse(
        this.visitorName,
        this.visitorEmail,
        this.visitorType,
        this.visitorFirstName,
        this.visitorLastName,
        this.visitorIdCard,
        this.visitorPhone
    ).subscribe(r => {
      console.log(r);
      this.isLoading = false;
      this.coreService.broadcastToastMessage(this.translationsCommonService.TRNSL_DATA_SAVED_SUCCESSFULLY, false);

      this.nurseService.broadcastInfoAboutAddedNurse(r);

      this.modalCtrl.dismiss();
      //this.router.navigateByUrl('/vaccine-lots');
    }, err => {
      console.log(err);
      this.coreService.broadcastErrorMessage(this.translationsCommonService.TRNSL_DATA_SAVING_FAILED, err);
      this.isLoading = false;
    });
  }

  checkIfFormValid() {
    return true;
  }


}
