import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {take, map, tap, delay, switchMap} from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import {Router} from '@angular/router';
import {BehaviorSubject} from 'rxjs';
import {HttpService} from './http.service';
import {DatePipe} from '@angular/common';
import {CoreService} from './core.service';
import {url} from 'inspector';
import {environment} from '../../environment';

@Injectable({
  providedIn: 'root'
})
export class DocumentsService {

    documentsChanged = new EventEmitter<string>();


    constructor(public coreService: CoreService,
                private http: HttpClient,
                private cookieService: CookieService,
                public router: Router,
                private httpService: HttpService,
                private datepipe: DatePipe) {
    }

    public FORM_TYPES = {
        SMS: 'SMS',
        HTML:  'HTML'
    };

    broadcastDocumentsUpdate() {
        this.documentsChanged.emit('');
    }

    getSmsFormTemplates() {
        console.log('getSmsFormTemplates');
        return this.httpService.get(environment.URL_ROOT + '/getSmsTemplates')
            .pipe(map(r => {
                console.log(r);
                return r;
            }));
    }

    getHtmlFormTemplates() {
        console.log('getHtmlFormTemplates');
        return this.httpService.get(environment.URL_ROOT + '/getHtmlTemplates')
            .pipe(map(r => {
                console.log(r);
                return r;
            }));
    }

    getFormTemplates(formType: string) {
        if (formType === this.FORM_TYPES.SMS) {
            return this.getSmsFormTemplates();
        } else if (formType === this.FORM_TYPES.HTML) {
            return this.getHtmlFormTemplates();
        }
    }

    addSmsTemplate(name: string, msg: string, notes: string) {
        const postData = {
            _name: name,
            _msg: msg
        };

        console.log(postData);
        return this.httpService.post(environment.URL_ROOT + '/rpc/addSmsTemplate', postData)
            .pipe(map(r => {
                console.log(r);
                return r;
            }));
    }
}
