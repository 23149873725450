import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {Subscription} from "rxjs";
import {CoreService} from "../../services/core.service";
import {PatientService} from "../../services/patient.service";
import {ModalController, Platform} from "@ionic/angular";
import {HttpService} from "../../services/http.service";
import {TranslateService} from "@ngx-translate/core";
import {ModalPatientsUpsertComponent} from "../shared/modal-patients-upsert/modal-patients-upsert.component";
import {Capacitor} from "@capacitor/core";
import {ModalPatientAdmissionUpsertComponent} from "../shared/modal-patient-admission-upsert/modal-patient-admission-upsert.component";

@Component({
  selector: 'app-patients-admissions',
  templateUrl: './patients-admissions.component.html',
  styleUrls: ['./patients-admissions.component.scss'],
})
export class PatientsAdmissionsComponent implements OnInit {

  patientId = '';

  TITLE = '';
  patientsAdmissions = [];
  patientsAdmissionsFiltered = [];

  itemsVisibilityState = [];
  patientItem = null;

  searchPhrase = '';
  isLoading = false;

  private patientsAdmissionsAvailableChangedSub: Subscription;

  constructor(private route: ActivatedRoute,
              public router: Router,
              public coreService: CoreService,
              public patientService: PatientService,
              public modalCtrl: ModalController,
              private httpService: HttpService,
              private platform: Platform,
              private translate: TranslateService) {
  }

  ngOnDestroy() {
    if (this.patientsAdmissionsAvailableChangedSub) {
      this.patientsAdmissionsAvailableChangedSub.unsubscribe();
    }
  }

  ngOnInit() {
    if (!this.httpService.isLoggedIn()) {
      this.router.navigateByUrl('/login');
    }
    this.patientId = this.route.snapshot.paramMap.get('patientId');
    this.isLoading = true;

    this.loadPatientAdmissions();

    this.loadPatient();

    this.patientsAdmissionsAvailableChangedSub = this.patientService.patientsAdmissionsAvailableChanged
        .subscribe( r => {
              this.loadPatientAdmissions();
            }
        );
  }

  loadPatient(){
    this.patientService.loadPatient(this.patientId).subscribe(r => {
      console.log(r);
      this.patientItem = r[0];

      this.TITLE = this.patientItem.full_name + ' Admissions';

      console.log('patientItem');
      console.log(this.patientItem);
      this.isLoading = false;

    }, err => {
      console.log(err);
      this.coreService.broadcastErrorMessage('Fetching patients failed ', err);
      this.isLoading = false;
    });
  }

  loadPatientAdmissions(){
    this.patientService.loadPatientsAdmissions(this.patientId).subscribe(r => {
      console.log(r);
      this.isLoading = false;
      this.patientsAdmissions = r;
      this.patientsAdmissionsFiltered =r;

      if (r.length>0) {
        console.log('LOADING PATIENT DATA');
        console.log(r[0]);

      }
    }, err => {
      console.log(err);
      this.coreService.broadcastErrorMessage('Fetching patients admissions failed ', err);
      this.isLoading = false;
    });
  }

  openReports(reportType:string, admissionId: string) {
    this.router.navigateByUrl('/reports-generic/' + reportType + '/' + admissionId);
  }

  openEvaluations (admissionId: string) {
    this.router.navigateByUrl('/patients-evaluations/' + this.patientId + '/' + admissionId);

  }

  openAddPatientAdmissionModal() {
    this.modalCtrl
        .create({
          component: ModalPatientAdmissionUpsertComponent,
          componentProps: { patientId: this.patientId, showHeader: false, action: "add", patientItem: null  },
          cssClass: 'bg-none'
        })
        .then(modalEl => {
          modalEl.present();
          return modalEl.onDidDismiss();
        });
  }

  filterItems() {
    const f1 = this.patientsAdmissions.filter(x => x.last_name.toLowerCase().includes(this.searchPhrase.toLowerCase()));
    const f2 = [];
    //const f2 = this.patientsAdmissions.filter(x => x.name.toLowerCase().includes(this.searchPhrase.toLowerCase()));

    // this.patientsAdmissions.map(x => {
    //   x.vaccines.map(v => {
    //     if (v.toLowerCase().includes(this.searchPhrase.toLowerCase())) {
    //       f3.push(x);
    //     }
    //   });
    // });

    const f4 = f1.concat(f2);

    const uniqueItems = [...new Set(f4)];

    this.patientsAdmissionsFiltered = uniqueItems.slice();
    console.log('screenings filtered');
    console.log(this.patientsAdmissionsFiltered);
  }

  call(phone: string){
    alert('calling ' + phone);
  }

  editPatient(patientItem: any){
    this.modalCtrl
        .create({
          component: ModalPatientAdmissionUpsertComponent,
          componentProps: { patientId: this.patientId, showHeader: false, action: "edit", patientItem: patientItem  },
          cssClass: 'bg-none'
        })
        .then(modalEl => {
          modalEl.present();
          return modalEl.onDidDismiss();
        });
  }
}
