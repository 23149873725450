import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {CoreService} from '../../services/core.service';
import {PatientService} from '../../services/patient.service';
import {ModalController, Platform} from '@ionic/angular';
import {HttpService} from '../../services/http.service';
import {TranslateService} from '@ngx-translate/core';
import {ModalPatientsUpsertComponent} from '../shared/modal-patients-upsert/modal-patients-upsert.component';
import {Capacitor} from '@capacitor/core';
import {TranslateDbService} from '../../services/translate.db.service';

@Component({
  selector: 'app-patient-details',
  templateUrl: './patient-details.component.html',
  styleUrls: ['./patient-details.component.scss'],
})
export class PatientDetailsComponent implements OnInit, OnDestroy {

  ALL_TRANSLATIONS = [];
  patientId = '';
  patientItem = null;
  patientLabels = null;
  isLoading = false;

  formItem_MARITALSTATUS_LABEL = '';

  TITLE = '';
  LAST_ADMISSION_ID = '';

  private patientsAvailableChangedSub: Subscription;

  constructor(private route: ActivatedRoute,
              public router: Router,
              public coreService: CoreService,
              public patientService: PatientService,
              public modalCtrl: ModalController,
              private httpService: HttpService,
              private platform: Platform,
              public translateDb: TranslateDbService,
              private translate: TranslateService) {
  }

  ngOnDestroy() {
    if (this.patientsAvailableChangedSub) {
      this.patientsAvailableChangedSub.unsubscribe();
    }
  }

  ngOnInit() {
    if (!this.httpService.isLoggedIn()) {
      this.router.navigateByUrl('/login');
    }
    this.patientId = this.route.snapshot.paramMap.get('patientId');

    this.isLoading = true;


    // this.translateDb.loadTranslations().subscribe(t => {
    //   this.ALL_TRANSLATIONS = t;
    // });

    this.translateDb.loadTranslations();


    this.loadPatient();

    // this.patientService.loadPatient(this.patientId).subscribe(r => {
    //   console.log(r);
    //   this.patientItem = r.Patient;
    //   this.patientLabels = r.Labels;
    //
    //   console.log('patientItem');
    //   console.log(this.patientItem);
    //   this.isLoading = false;
    //
    //   this.loadFormItems(this.patientService.PATIENT_FORM_ITEMS.MARITALSTATUSES);
    //
    //   this.loadPatientAdmissions();
    //   this.generateTitle();
    //
    // }, err => {
    //   console.log(err);
    //   this.coreService.broadcastErrorMessage('Fetching patients failed ', err);
    //   this.isLoading = false;
    // });
  }

  loadPatient(){
    this.patientService.loadPatient(this.patientId).subscribe(r => {
      console.log(r);
      this.patientItem = r[0];

      this.TITLE = this.patientItem.full_name + ' Evaluation';

      console.log('patientItem');
      console.log(this.patientItem);
      this.isLoading = false;

    }, err => {
      console.log(err);
      this.coreService.broadcastErrorMessage('Fetching patients failed ', err);
      this.isLoading = false;
    });
  }

    generateTitle() {
      console.log('patientItem');
      console.log(this.patientItem);
      this.TITLE = this.patientItem.first_name + ' ' + this.patientItem.last_name + ' (' + this.patientItem.age + ')';
    }

  loadFormItems(itemName: string){
        this.patientService.loadFormItems(itemName).subscribe(
        formItems => {
            formItems.forEach(x => {
              if (x.id === this.patientItem.marital_status) {
                this.formItem_MARITALSTATUS_LABEL = x.name;
              }
            });
        });
  }

  openAdmissions(id: string) {
     this.router.navigateByUrl('/patient-admission-details/' +  this.patientItem.last_admission);
  }

  openAddPatientModal() {
    this.modalCtrl
        .create({
          component: ModalPatientsUpsertComponent,
          componentProps: {  showHeader: false, action: 'add', patientItem: null  },
          cssClass: 'bg-none'
        })
        .then(modalEl => {
          modalEl.present();
          return modalEl.onDidDismiss();
        });
  }

  call(phone: string){
    alert('calling ' + phone);
  }

  editPatient(patientItem: any){
    this.modalCtrl
        .create({
          component: ModalPatientsUpsertComponent,
          componentProps: {  showHeader: false, action: 'edit', patientItem  },
          cssClass: 'bg-none'
        })
        .then(modalEl => {
          modalEl.present();
          return modalEl.onDidDismiss();
        });
  }

  loadPatientAdmissions(){
    this.patientService.loadPatientsAdmissions(this.patientId).subscribe(r => {
      console.log(r);
      this.isLoading = false;


      if (r.length > 0) {
       this.LAST_ADMISSION_ID = '2' ; // r.reverse()[0].id;
      }
    }, err => {
      console.log(err);
      this.coreService.broadcastErrorMessage('Fetching patients admissions failed ', err);
      this.isLoading = false;
    });
  }


  openReports(reportType: string, admissionId: string) {
    // this.router.navigateByUrl('/reports-' + reportType + '/' + admissionId);
    this.router.navigateByUrl('/reports-generic/' + reportType + '/' + admissionId);
  }
}
