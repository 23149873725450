import {Component, Input, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {CoreService} from '../../../services/core.service';
import {PatientService} from '../../../services/patient.service';
import {PatientReportsService} from '../../../services/patientreports.service';
import {ModalController} from '@ionic/angular';
import {HttpService} from '../../../services/http.service';
import {TranslateService} from '@ngx-translate/core';
import {TranslateDbService} from '../../../services/translate.db.service';
import {TranslationsCommonService} from '../../../services/translations.common.service';
import {catchError} from 'rxjs/operators';
//import 'rxjs/add/operator/catch';

@Component({
  selector: 'app-reports-generic-modal2',
  templateUrl: './reports-generic-modal2.component.html',
  styleUrls: ['./reports-generic-modal2.component.scss'],
})
export class ReportsGenericModal2Component implements OnInit {
  @Input() reportType: string;
  @Input() admissionId: string;
  @Input() showHeader: number;
  @Input() action: string;
  @Input() reportItem: any;

  ALL_TRANSLATIONS = [];
  REPORT_FIELDS = null;
  REPORT_FIELDS_VALUES = [];

  reportId = null;
  isLoading = false;
  formName = this.reportType + 'ReportModal';

  TITLE = '';

  private submitSectionSub: Subscription;
  private formBooleanSectionSub: Subscription;
  private pathingReportFieldFailedSub: Subscription;
  private datePickerDatesChangedSub: Subscription;

  constructor(private route: ActivatedRoute,
              public router: Router,
              public coreService: CoreService,
              public patientService: PatientService,
              public patientReportsService: PatientReportsService,
              public modalCtrl: ModalController,
              private httpService: HttpService,
              private translate: TranslateService,
              public translateDb: TranslateDbService,
              public translationsCommonService: TranslationsCommonService) {
  }

  ngOnDestroy() {
    if (this.submitSectionSub) {
      this.submitSectionSub.unsubscribe();
    }

    if (this.formBooleanSectionSub) {
      this.formBooleanSectionSub.unsubscribe();
    }

    if (this.pathingReportFieldFailedSub) {
      this.pathingReportFieldFailedSub.unsubscribe();
    }

    if (this.datePickerDatesChangedSub) {
      this.datePickerDatesChangedSub.unsubscribe();
    }
  }

  checkIfReportFieldsContainBoolean() {
    let reportFieldsContainBoolean = false;
    this.REPORT_FIELDS.forEach(i => {
      if (i.type === 'boolean') {
        reportFieldsContainBoolean = true;
      }
    });

    return reportFieldsContainBoolean;
  }

  loadReportFields() {
    this.patientReportsService.loadReportFields(this.reportType).subscribe(r => {

      const R_REMOVED_VIEW_FIELDS = [];

      r.forEach(i => {
        if (i.col.indexOf('vf_') === -1) {
           R_REMOVED_VIEW_FIELDS.push(i);
        }
      });

      this.REPORT_FIELDS = R_REMOVED_VIEW_FIELDS;
      this.REPORT_FIELDS.forEach(i => {
        this.REPORT_FIELDS_VALUES.push(
            {
              col: i.col,
              state: 'fresh',
              value: '',
              savedInDb: false
            }
        );
      });


    }, err => {
      console.log(err);
      this.coreService.broadcastErrorMessage('Fetching REPORT_FIELDS failed ', err);
      this.isLoading = false;
    });
  }


  getInputClass(savedInDb: boolean, state: string) {
    if (savedInDb === true) {
      return 'saveSuccessBg';
    } else {


      if (state === 'fresh'){
        return '';
      } else if (state === 'saved'){
        return 'saveSuccessBg';
      } else if (state === 'fail') {
        return 'saveFailedBg';
      }
    }

  }

  getInputStyle(state: string) {

    if (state === 'fresh'){
      return 'background-color: white; color: black';
    } else if (state === 'saved'){
      return 'background-color: lightgreen; color: black';
    } else if (state === 'fail') {
      return 'background-color: red; color: black';
    }
  }

  // only for TEXT fields
  patchField(col: string, array_index: number, newValue: any) {
    console.log('newValue: ', newValue);

    if (newValue === '' || newValue === undefined) {
      // this.coreService.broadcastToastMessage("NOT UPDATING FIELD", true);
      console.log('NOT UPDATING FIELD');
    } else {
      this.patientReportsService.patchReportField(this.reportType, this.reportId, col, newValue).subscribe(r => {
        console.log('patchField report Id: ' + this.reportId + '; col: ' + col + '; value: ' + newValue);
        //   this.coreService.broadcastToastMessage(this.translationsCommonService.TRNSL_DATA_SAVED_SUCCESSFULLY, false);
        this.REPORT_FIELDS_VALUES[array_index].state = 'saved';
        this.REPORT_FIELDS_VALUES[array_index].savedInDb = true;

      }, err => {
        console.log('http ERROR caught FROM modal');
        console.log(err);
        // this.coreService.broadcastErrorMessage('Patching REPORT_FIELDS failed ', err);
        this.REPORT_FIELDS_VALUES[array_index].state = 'fail';
     }
      );
    }
  }

  initializeReport() {
    this.patientReportsService.initializeReport(this.reportType, this.admissionId).subscribe(r => {
      this.reportId = r.id;

      console.log('initialized report Id: ', this.reportId);
    }, err => {
      console.log(err);
      this.coreService.broadcastErrorMessage('Fetching REPORT_FIELDS failed ', err);
      this.isLoading = false;
    });
  }

  ngOnInit() {
    if (!this.httpService.isLoggedIn()) {
      this.router.navigateByUrl('/login');
    }

    this.initializeReport();
    this.loadReportFields();

    this.TITLE = this.reportType;
    this.translateDb.loadTranslations().subscribe(t => {
      this.ALL_TRANSLATIONS = t;
    });

    /////// form-boolean update handler  //////////////////
    this.formBooleanSectionSub = this.coreService.formBooleanSectionsChanged.subscribe(
        formBooleanSectionDataItems  => {
          console.log('formBooleanSectionSub: ');
          console.log(formBooleanSectionDataItems);

          formBooleanSectionDataItems.forEach(changedItem => {

            let count = 0;

            const arrayCopy = this.REPORT_FIELDS_VALUES.slice();

            console.log('arrayCopy');
            console.log(arrayCopy);

            arrayCopy.forEach(x => {
              if (x.col === changedItem.sectionName) {

                console.log('ITEM FOUND!');

                  this.REPORT_FIELDS_VALUES[count].state = 'saved';
                  this.REPORT_FIELDS_VALUES[count].value = changedItem.position;
                  this.REPORT_FIELDS_VALUES[count].savedInDb = true;
              }
              count++;
            });
          });
        }
    );
    ////////////////////////////////////////////////////////////

    this.pathingReportFieldFailedSub = this.httpService.patchingReportFieldFailed.subscribe(failedReportField =>{

      console.log('pathingReportFieldFailedSub');
      console.log('failedReportField: ', failedReportField);
      let count = 0;

      const arrayCopy = this.REPORT_FIELDS_VALUES.slice();

      console.log('arrayCopy');
      console.log(arrayCopy);

      arrayCopy.forEach(x => {
        if (x.col === failedReportField.reportField) {

          console.log('ITEM FOUND!');

          this.REPORT_FIELDS_VALUES[count].state = 'fail';
         // this.REPORT_FIELDS_VALUES[count].value = changedItem.position;
          this.REPORT_FIELDS_VALUES[count].savedInDb = false;
        }
        count++;
      });
    });

    this.datePickerDatesChangedSub = this.coreService.datePickerDatesChanged.subscribe(cd => {
      console.log('datePickerDatesChangedSub:');
      console.log(cd);

      console.log('REPORT_FIELDS');
      console.log(this.REPORT_FIELDS);

      let count = 0;
      this.REPORT_FIELDS.forEach(x => {

        if(x.col === cd[0].descriptionLabel) {
          console.log('found!');
          this.patchField(cd[0].descriptionLabel, count, cd[0].date);
        }

        count ++;
      });
    });
  }

  getBoolValue(itemValue: any) {

    if (itemValue === '') {
      return false;
    } else if(itemValue === false) {
      return false;
    } else if (itemValue === true) {
      return true;
    } else {
      return false;
    }
  }

  getPlaceholderHint(dataType: string){
    if (dataType === 'timestamp with time zone' || dataType === 'date') {
      return this.coreService.DATE_FORMAT;
    } else if (dataType === 'integer' || dataType === 'double' ) {
      return 'number';
    } else {
      return 'text';
    }
  }

  getInputType(dataType: string) {
    if (dataType === 'integer' || dataType === 'double' || dataType === 'decimal' ) {
      return 'number';
    } else {
      return 'text';
    }
  }

  checkIfFormValid() {
    return true;
  }

  closeAndRedirectToReportDetails() {
    this.modalCtrl.dismiss();
    this.router.navigateByUrl('/report-generic-details/' + this.reportType + '/' + this.reportId);
  }
}
