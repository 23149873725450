import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {take, map, tap, delay, switchMap} from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import {Router} from '@angular/router';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpService} from './http.service';
import {Visitor} from '../components/shared/visitor.model';
import {Event} from '../components/shared/event.model';
import {DatePipe} from '@angular/common';
import { EventEmitter } from '@angular/core';
import {DatePickerData} from '../components/shared/datepickerdata.model';
import {SubmitSectionData} from '../components/shared/submitsectiondata.model';
import {FileData} from '../components/shared/filedata.model';
import {Toast} from '../models/toast.model';
import {SingleSetting} from '../models/single.setting.model';
import {isNumeric} from 'rxjs/internal-compatibility';
import { isObservable } from 'rxjs';
import {CommonService} from './common.service';
import {DataSourceInformation} from '../models/datasource.information.model';
import {FormBooleanSectionData} from '../components/shared/formbooleansectiondata.model';
import {FormDropdownSectionData} from '../components/shared/formdropdownsectiondata.model';
import {PatchableInputData} from '../models/patchableinput.data';
import {version} from '../../version';
import {environment} from '../../environment';
import {FileUploadStatus} from "../components/shared/fileuploadstatus.model";

@Injectable({
    providedIn: 'root'
})
export class CoreService {
    DIAGNOSTIC_MESSAGE_UPDATING = 'Updating...';
    DIAGNOSTIC_MESSAGE_FETCHING = 'Fetching data...';
    DIAGNOSTIC_MESSAGE_OK = 'OK';
    DIAGNOSTIC_MESSAGE_ERROR = 'ERROR';
    DIAGNOSTIC_MESSAGE_PROCESSING_IMAGE = 'Processing image...';

    DATE_FORMAT = 'yyyy-MM-dd';
    TIME_FORMAT = 'HH:mm';
    DATETIME_FORMAT = 'yyyy-MM-dd HH:mm';

    STUDENT_LIST_LIMIT = 15;

    public version = '';
    private _events: Event[] = [];
    private _visitorsAvailable: Visitor[] = [];

    constructor(private http: HttpClient,
                private cookieService: CookieService,
                public router: Router,
                private httpService: HttpService,
                private datepipe: DatePipe) {
        // this.loadAvailableStudents().subscrsscibe(s =>{
        //     this.availableStudents = s;
        // });a
        this.version = version.app_version;
    }

    selectedGenderChanged = new EventEmitter<string[]>();
    selectedDateChanged = new EventEmitter<string>();
    datePickerDatesChanged = new EventEmitter<DatePickerData[]>();
    submitSectionsChanged = new EventEmitter<SubmitSectionData[]>();
    formBooleanSectionsChanged = new EventEmitter<FormBooleanSectionData[]>();
    formDropdownSectionsChanged = new EventEmitter<FormDropdownSectionData[]>();
    fileLoadedChanged = new EventEmitter<FileData>();
    errorMessageChanged = new EventEmitter<string>();
    toastMessageChanged = new EventEmitter<Toast>();
    loadingStateChanged = new EventEmitter<boolean>();
    singleSettingValueChanged = new EventEmitter<SingleSetting>();
    studentChanged = new EventEmitter<string>();
    studentsLoadedChanged = new EventEmitter<any[]>();
    dataSourceIndicatorChanged = new EventEmitter<DataSourceInformation>();


    fileSavingResultSub = new EventEmitter<FileUploadStatus>();

    Changed_HEADER_DIAGNOSTIC_MESSAGE = new EventEmitter<string>();
    Changed_PATCHABLE_INPUT_UPDATED = new EventEmitter<PatchableInputData>();

    Changed_POSTABLE_INPUT_UPDATED = new EventEmitter<PatchableInputData>();


    private _selectedDate = '';
    private _datePickerDates: DatePickerData[] = [];
    private _submitSectionData: SubmitSectionData[] = [];

    availableStudents = [];

    formatAndBroadcastDiagnosticMessage(errorObject: any) {

        if (errorObject.error === undefined) {
            this.broadcast_HEADER_DIAGNOSTIC_MESSAGE( this.DIAGNOSTIC_MESSAGE_ERROR +  ': Operation FAILED! No hint available');
        } else {
            const errorCode = errorObject.error.code;
            const errorHint = errorObject.error.hint;
            const errorMessage = errorObject.error.message;
            const formattedErrorMessage = errorCode + ': ' + errorHint + ' ' + errorMessage;
            this.broadcast_HEADER_DIAGNOSTIC_MESSAGE(this.DIAGNOSTIC_MESSAGE_ERROR +  ': ' + formattedErrorMessage);
        }
    }


    broadcast_HEADER_DIAGNOSTIC_MESSAGE(message: string) {
        this.Changed_HEADER_DIAGNOSTIC_MESSAGE.emit(message);
    }

    broadcast_PATCHABLE_INPUT_UPDATED(fieldName: string, fieldValue: string, source: string, itemId: string) {
        const patchableInputData = new PatchableInputData(fieldName, fieldValue, source, itemId);
        this.Changed_PATCHABLE_INPUT_UPDATED.emit(patchableInputData);
    }

    broadcastFileUploadStatus(fileUploadStatus: FileUploadStatus) {
        this.fileSavingResultSub.emit(fileUploadStatus);
    }

    setSingleValueSetting(singleSetting: SingleSetting) {
        this.singleSettingValueChanged.emit(singleSetting);
    }

    broadcastDataSourceInformation(datasource, message: string,  isError: boolean = false) {
        console.log('broadcastToastMessage: ', message, isError);
        this.dataSourceIndicatorChanged.emit(new DataSourceInformation(datasource, message, isError));
    }

    broadcastStudentUpdate(studentId: string) {
        this.studentChanged.emit(studentId);
    }

    broadcastLoadingState() {
        this.loadingStateChanged.emit(true);
    }

    broadcastToastMessage(message: string,  isError: boolean = false) {
        console.log('broadcastToastMessage: ', message, isError);
        this.toastMessageChanged.emit(new Toast(message, isError));
    }

    broadcastErrorMessage(description: string,  err: any) {
        console.log('broadcastErrorMessage: ', description, err);

        const errorMessage = description + ' : <br />' + this.formatDbErrorMessage(err);
      //  this.errorMessageChanged.emit(errorMessage);
        this.toastMessageChanged.emit(new Toast(errorMessage, true));
    }

    findFileIcon(datattype: string) {

        let iconToUse = 'image-outline';
        switch (datattype) {
            case 'image': {
                iconToUse = '';
                break;
            }
            case 'video': {
                iconToUse = 'videocam-outline';
                break;
            }
            case 'audio': {
                iconToUse = 'volume-high-outline';
                break;
            }
            case 'text': {
                iconToUse = 'document-outline';
                break;
            }
            case 'pdf': {
                iconToUse = 'document-outline';
                break;
            }
        }

        return iconToUse;
    }

    formatDbErrorMessage(httpData: any) {
        console.log(httpData);
        // console.log('hint: ', err.data.error.hint);
        // console.log('details: ', err.data.error.details);
        // console.log('code: ', err.data.error.code);
        // console.log('message: ', err.data.error.message);
        //
        // const formattedMessage = '<b>Hint:</b> ' + err.hint +
        //     '<br /><b>Details:</b> ' + err.details +
        //     '<br /><b>Code:</b> ' + err.code +
        //     '<br /><b>Message:</b> ' + err.message;


        console.log('httpData:');
        console.log(httpData);
        // console.log(httpData.data);
        // console.log(httpData.data.error);

        const errorCode = httpData.data.error.code;
        const errorDetails = httpData.data.error.details;
        const errorHint = httpData.data.error.hint;
        const errorMessage = httpData.data.error.message;

        let formattedMessage = 'Error:<br />Code: ' + errorCode;
        if (errorDetails !== null){ formattedMessage = formattedMessage + '<br />Details: ' + errorDetails; }
        if (errorHint !== null){ formattedMessage = formattedMessage + '<br />Hint: ' + errorHint; }
        formattedMessage = formattedMessage + '<br />Message: ' + errorMessage;

        console.log('formattedMessage: ' + formattedMessage);

        return formattedMessage;
    }

    getLocations(event_type) {
        console.log('getLocations');
        return this.httpService.get (environment.URL_ROOT + '/getEventLocations?event_type=fts.{' + event_type +  '}')
            .pipe(map(r => {
                console.log(r);
                return r;
            }));
    }

    addLocation(event_location: string, location_name: string, event_type) {

        const postData = {
            _event_location: event_location,
            _location_name: location_name,
            _events: [event_type]
        };

        console.log(postData);

        return this.httpService.post(environment.URL_ROOT + '/rpc/addEventLocation', postData)
            .pipe(map(r => {
                console.log(r);
                return r;
            }));
    }

    saveStudentFile(studentId: string, fileName: string, fileType: string, description: string, base64String: string) {
        const postData = {
            _student: studentId,
            _filename: fileName,
            _description: description,
            _blob: base64String
        };

        console.log(postData);
        return this.httpService.post(environment.URL_ROOT + '/rpc/addStudentFile', postData)
            .pipe(map(r => {
                console.log(r);
                return r;
            }));
    }

    getStudentFiles(studentId: string) {
        console.log('getStudentFiles, studentId:' + studentId);
        return this.httpService.get(environment.URL_ROOT + '/getStudentFiles?student=eq.' + studentId)
            .pipe(map(r => {
                    console.log(r);
                    return r;
                },
                error => {
                    console.log('error during file upload: ', error.message);
                    return error.message;
                }));
    }

    update_imported_row(id: string, parameter_name: string, parameter_value: string) {
        const postData = {
            row_id: id,
            _column: parameter_name,
            _value: parameter_value,
            __visitor_assign_scope: 'SCHOOL',
            __missing_class: 'CREATE'
        };

        console.log(postData);

        return this.httpService.post(environment.URL_ROOT + '/rpc/update_imported_row', postData)
            .pipe(map(r => {
                console.log(r);
                return r;
            }));
    }

    process_imported_students(batch: string) {

        console.log('process_imported_students');
        const postData = {
            batch_id: batch,
            __missing_class: 'CREATE',
            __visitor_assign_scope: 'SCHOOL',
            __parents_action: 'CREATE'
        };

        console.log(postData);

        return this.httpService.post(environment.URL_ROOT + '/rpc/process_imported_students', postData)
            .pipe(map(r => {
                console.log(r);
                return r;
            }));
    }

    csv_import_students(file_name: string, base64: string) {
        const postData = {
            _blob: base64
        };

        console.log(postData);

        return this.httpService.post(environment.URL_ROOT + '/rpc/csv_import_students', postData)
            .pipe(map(r => {
                console.log(r);
                return r;
            }));
    }

    registerFile(newFile: FileData) {
        console.log('registerFile:');
        console.log(newFile);

        // this._fileLoaded = newFile;
        this.fileLoadedChanged.emit(newFile);
    }

    setSubmitSectionUpdate(submitSectionData: SubmitSectionData) {
        // date: string, descriptionLabel: string
        console.log('setSubmitSectionUpdate:');
        console.log(submitSectionData);

        // const itemToUpdate = this._submitSectionData.find(x => x.sectionName === submitSectionData.sectionName);
        //
        // console.log('itemToUpdate');
        // console.log(itemToUpdate);
        // const newArray = [];
        //
        // if (itemToUpdate) {  // https://stackoverflow.com/questions/28975896/is-there-a-way-to-check-for-both-null-and-undefined
        //     itemToUpdate.isDisabled = submitSectionData.isDisabled;
        //     itemToUpdate.cancelBroadcasted = submitSectionData.cancelBroadcasted;
        //     itemToUpdate.submitBroadcasted = submitSectionData.submitBroadcasted;
        //
        //     newArray.push(itemToUpdate);
        // } else {
        //     //this._submitSectionData.push(submitSectionData);
        //
        //     newArray.push(submitSectionData);
        // }
        const newArray = [];
        newArray.push(submitSectionData);
        this.submitSectionsChanged.emit(newArray.slice());
    }

    setFormBooleanSectionUpdate(formBooleanSectionData: FormBooleanSectionData) {
        // date: string, descriptionLabel: string
        console.log('setFormBooleanSectionUpdate:');
        console.log(formBooleanSectionData);


        const newArray = [];
        newArray.push(formBooleanSectionData);
        this.formBooleanSectionsChanged.emit(newArray.slice());
    }

    setFormDropdownSectionUpdate(formDropdownSectionData: FormDropdownSectionData) {
        // date: string, descriptionLabel: string
        console.log('setFormDropdownSectionUpdate:');
        console.log(formDropdownSectionData);

        const newArray = [];
        newArray.push(formDropdownSectionData);
        this.formDropdownSectionsChanged.emit(newArray.slice());
    }

    changeBackgroundInputColor(inputId: string, inputActive: boolean, label = null) {
        console.log('changeBackgroundInputColor');
        console.log(inputId);
        const input = document.getElementById(inputId);
        const inputValue = (input as HTMLInputElement).value;

        console.log('inputValue: ', inputValue);
        console.log(input);

        console.log('label: ', label);

        // if (inputActive) {
        //     input.style.backgroundColor = 'white';
        //     input.style.color = 'black';
        //     input.style.border = '1px solid black;';
        // } else {
        //     input.style.background = 'none';
        //     input.style.color = 'white';
        //     input.style.border = '1px solid gray';
        // }


        if (inputActive) {
            input.style.backgroundColor = 'white';
            input.style.color = 'black';
            input.style.border = '1px solid black;';
        } else {
            input.style.backgroundColor = 'white';
            input.style.color = 'black';
            input.style.border = '1px solid black;';
        }
    }

    getFile(fileId: string) {
        console.log('getFile');
        return this.httpService.get(environment.URL_ROOT + '/getFiles?file=eq.' + fileId)
            .pipe(map(r => {
                console.log(r);
                return r[0];
            }));
    }

    setDatePickerDate(datepickerData: DatePickerData) {
        // date: string, descriptionLabel: string
        console.log('setDatePickerDate:');
        console.log(datepickerData);

        const itemToUpdate = this._datePickerDates.find(x => x.descriptionLabel === datepickerData.descriptionLabel);

        const newDatePickerDates = []; // to prevent sending multiple dates when it's not needed

        if (itemToUpdate != null) {
            itemToUpdate.date = datepickerData.date;
            newDatePickerDates.push(itemToUpdate);
        } else {
            this._datePickerDates.push(datepickerData);
            newDatePickerDates.push(datepickerData);
        }

        this.datePickerDatesChanged.emit(newDatePickerDates.slice());
    }

    setSelectedDate(date: string) {
        console.log('setSelectedDate:');
        console.log(date);
        this._selectedDate = date;
        this.selectedDateChanged.emit(this._selectedDate);
    }

    setSelectedGenders(genders: string[]) {
        console.log('setSelectedGenders: ', genders);
        this.selectedGenderChanged.emit(genders);
    }

    buildIdsString(itemsSelected: string[], brackets: string = '{}') {
        let itemsString = '';
        let ind = 1;
        itemsSelected.map(x => {
            itemsString = itemsString + x;
            if (ind < itemsSelected.length){
                itemsString = itemsString + ',';
            }

            ind ++;
        });

        if (brackets === '{}') {
            const itemIds = '{' + itemsString + '}';
            return itemIds;
        } else {
            const itemIds = '[' + itemsString + ']';
            return itemIds;
        }
    }

    saveSubjectFile(subjectId: string, fileName: string, fileType: string, description: string, base64String: string) {
        const postData = {
            _subject: subjectId,
            _filename: fileName,
            _description: description,
            _blob: base64String
        };

        console.log(postData);
        return this.httpService.post(environment.URL_ROOT + '/rpc/addSubjectFile', postData)
            .pipe(map(r => {
                console.log(r);
                return r;
            }));
    }

    getSubjectFiles(subjectId: string) {
        console.log('getSubjects');
        return this.httpService.get(environment.URL_ROOT + '/getSubjectFiles?subject=eq.' + subjectId)
            .pipe(map(r => {
                    console.log(r);
                    return r;
                },
                error => {
                    console.log('error during file upload: ', error.message);
                    return error.message;
                }));
    }

    getSubjects() {
        console.log('getSubjects');
        return this.httpService.get(environment.URL_ROOT + '/getSubjects')
            .pipe(map(r => {
                console.log(r);
                return r;
            }));
    }

    getAlternatingListItemClass(index: number, existingClasses: string = '', customClassPrimary: string = '', customClassAlt: string = '') {

        let classesToUse = existingClasses;

        let classPrimary = 'heavisListRow';
        let classAlt = 'heavisListRowAlt';

        if (customClassPrimary !== '') { classPrimary = customClassPrimary; }
        if (customClassAlt !== '') { classAlt = customClassAlt; }

        if (index % 2 > 0) {
            classesToUse = classesToUse + ' ' + classAlt;
        } else {
            classesToUse = classesToUse + ' ' + classPrimary;
        }

        return classesToUse;
    }

    universalValidate(dataType: string, lengthRequired: number, settingValue: string){

        let isValid = false;
        console.log('universalValidate settingValue: ', settingValue);
        console.log('universalValidate dataType: ', dataType);
        console.log('universalValidate lengthRequired: ', lengthRequired);

        let typeValidation = false;
        let lengthValidation = false;
        switch (dataType) {
            case 'number':
                if (isNumeric(settingValue)) {
                    typeValidation = true;
                }
                break;
            case 'string':
                typeValidation = true;
                break;
        }

        if (settingValue.toString().length >= lengthRequired) {
            lengthValidation = true;
        }

        isValid = typeValidation && lengthValidation;
        console.log('universalValidate validation result: ', isValid);
        return isValid;
    }

    //
    // loadChartData(containerId: string, chartData: string, average: string) {
    //
    //     const elParent = document.getElementById(containerId) as HTMLElement;
    //     const elChild = elParent.firstChild as HTMLElement;
    //     console.log(elChild.getAttributeNames());
    //
    //     console.log(containerId + '_string:');
    //     console.log(chartData);
    //     //console.log('average-value: ' + average);
    //
    //     elChild.setAttribute('data', chartData);
    //     elChild.setAttribute('width', '400px');
    //     //   elChild.setAttribute('average-value', average);
    // }

    loadChartData(containerId: string, chartData: string, average: string) {


        setTimeout(() => {
            console.log(chartData);

            const elParent  = document.getElementById(containerId); // as HTMLElement;
            // @ts-ignore
            const elChild  = elParent.lastElementChild; // as HTMLElement;

            console.log(elParent);

            console.log(elChild);


            console.log('data attribute before:');
            // @ts-ignore
            console.log(elChild.getAttribute('data'));

            // @ts-ignore
            elChild.setAttribute('data', chartData);

            console.log('data attribute after:');
            // @ts-ignore
            console.log(elChild.getAttribute('data'));
        }, 1500);



    }
}
