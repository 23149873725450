import {Component, Input, OnInit} from '@angular/core';
import {WoundsService} from '../../../services/wounds.service';
import {ModalController} from '@ionic/angular';

@Component({
  selector: 'app-wounds-stage-modal',
  templateUrl: './wounds-stage-modal.component.html',
  styleUrls: ['./wounds-stage-modal.component.scss'],
})
export class WoundsStageModalComponent implements OnInit {
  @Input() selectedStage: string;
  @Input() woundId: string;

  constructor(
      public woundsService: WoundsService,
      public modalCtrl: ModalController) { }

  ngOnInit() {}

  selectStage(newStage: string) {

    this.woundsService.patchWoundField(this.woundId, 'stage', this.selectedStage).subscribe(r => {
      this.selectedStage = newStage;
      this.woundsService.broadcastNewStage(this.selectedStage);
      this.modalCtrl.dismiss();
    });

  }

  getColor(stage: string) {
    if (this.selectedStage === stage) {
      return 'danger';
    } else {
      return 'primary';
    }
  }


  getClass(stage: string) {
    if (this.selectedStage === stage) {
      return 'ion-text-center stageTileActive';
    } else {
      return 'ion-text-center stageTileInActive';
    }
  }


}
