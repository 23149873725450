import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import { CallNumber } from '@ionic-native/call-number/ngx';
import { Clipboard } from '@ionic-native/clipboard/ngx';
import {HttpService} from '../../../services/http.service';
import {Router} from '@angular/router';
import {CoreService} from '../../../services/core.service';
import {SmsModalComponent} from '../sms-modal/sms-modal.component';
//import { Contacts, Contact, ContactField, ContactName } from '@ionic-native/contacts/ngx';

@Component({
  selector: 'app-call-modal',
  templateUrl: './call-modal.component.html',
  styleUrls: ['./call-modal.component.scss'],
})
export class CallModalComponent implements OnInit {
  @Input() phoneNumber: string;
  @Input() firstName: string;
  @Input() lastName: string;

  numberCopied = false;

  contactSaved = false;
  copyMessage = '';
  saveContactMessage = '';

  constructor(public modalCtrl: ModalController,
              public coreService: CoreService,
              private callNumber: CallNumber,
              private clipboard: Clipboard,
              private httpService: HttpService,
              public router: Router
  ) { }

  ngOnInit() {
    if (!this.httpService.isLoggedIn()){
      this.router.navigateByUrl('/login');
    }

    this.copyMessage = this.phoneNumber;
  }

  initiateCall(){
    this.callNumber.callNumber(this.phoneNumber, true)
        .then(res => console.log('Launched dialer!', res))
        .catch(err => console.log('Error launching dialer', err));
  }

  copyNumberToClipboard() {
    this.clipboard.copy(this.phoneNumber).then(
            (resolve: string) => {
              console.log('copied ', resolve);
            },
            (reject: string) => {
              alert('Error: ' + reject);
            }
        );

    this.copyMessage = this.copyMessage + ' copied';
    //this.clipboard.clear();
  }

  saveContact(){
      console.log('Saving contact feature NOT IMPLEMENTED yet');


    // const contact: Contact = this.contacts.create();
    //
    // contact.name = new ContactName(null, this.firstName, this.lastName);
    // contact.phoneNumbers = [new ContactField('mobile', this.phoneNumber)];
    // contact.save().then(
    //     () => {
    //       console.log('Contact saved!', contact);
    //       this.saveContactMessage = 'Contact Saved';
    //       this.contactSaved = true;
    //     },
    //     (error: any) => {
    //       console.error('Error saving contact.', error);
    //       this.saveContactMessage = 'Contact Saving failed!';
    //       this.contactSaved = true;
    //     }
    // );
    this.coreService.broadcastToastMessage('Saving contact feature NOT IMPLEMENTED yet', true);
  }

  closeModal() {
    this.modalCtrl.dismiss(null, 'cancel');
  }


  sendSms() {
    this.modalCtrl
        .create({
          component: SmsModalComponent,
          componentProps: { phoneNumber: this.phoneNumber, firstName: this.firstName, lastName: this.lastName }
        })
        .then(modalEl => {
          modalEl.present();
          return modalEl.onDidDismiss();
        });
  }
}
