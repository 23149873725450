import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import {CommonModule} from '@angular/common';
import {LoginComponent} from './components/login/login.component';
import {QuickMenuComponent} from './components/shared/quick-menu/quick-menu.component';
import {LogoutComponent} from './components/logout/logout.component';
import {HomePage} from './components/home/home.page';
import {SettingsComponent} from './components/settings/settings.component';
import {NursesComponent} from './components/nurses/nurses.component';
import {PatientsComponent} from './components/patients/patients.component';
import {PatientsAdmissionsComponent} from './components/patients-admissions/patients-admissions.component';
import {PatientEvaluationsComponent} from './components/patient-evaluations/patient-evaluations.component';
import {PatientDetailsComponent} from './components/patient-details/patient-details.component';
import {ReportsGenericComponent} from './components/reports/reports-generic/reports-generic.component';
import {PatientMenuComponent} from './components/patient-menu/patient-menu.component';
import {PatientAdmissionDetailsComponent} from './components/patient-admission-details/patient-admission-details.component';
import {ReportsMenuComponent} from './components/reports/reports-menu/reports-menu.component';
import {UpdatesLogComponent} from './components/updates-log/updates-log.component';
import {PatientsEvaluationDetailsComponent} from './components/patients-evaluation-details/patients-evaluation-details.component';
import {ReportGenericDetailsComponent} from './components/reports/report-generic-details/report-generic-details.component';
import {WoundsFormComponent} from './components/wounds/wounds-form/wounds-form.component';
import {WoundsComponent} from './components/wounds/wounds/wounds.component';
import {PatientsAdmissionSignatureComponent} from './components/patients-admission-signature/patients-admission-signature.component';
import {PatientsAdmissionChartsComponent} from './components/patients-admission-charts/patients-admission-charts.component';


const routes: Routes = [
  {
    path: 'home',
    pathMatch: 'full',
    component: PatientsComponent
  },
  {
    path: 'updates-log',
    pathMatch: 'full',
    component: UpdatesLogComponent
  },
  {
    path: 'nurses',
    pathMatch: 'full',
    component: NursesComponent
  },
  {
    path: 'patients',
    pathMatch: 'full',
    component: PatientsComponent
  },
  {
    path: 'wounds/:admissionId',
    pathMatch: 'full',
    component: WoundsComponent
  },
  {
    path: 'wounds-form/:admissionId/:woundId',
    pathMatch: 'full',
    component: WoundsFormComponent
  },
  {
    path: 'report-generic-details/:reportType/:reportId',
    pathMatch: 'full',
    component: ReportGenericDetailsComponent
  },
  {
    path: 'reports-generic/:reportType/:admissionId',
    pathMatch: 'full',
    component: ReportsGenericComponent
  },
  {
    path: 'reports-menu/:admissionId',
    pathMatch: 'full',
    component: ReportsMenuComponent
  },
  {
    path: 'patient-details/:patientId',
    pathMatch: 'full',
    component: PatientDetailsComponent
  },
  {
    path: 'patient-menu/:patientId',
    pathMatch: 'full',
    component: PatientMenuComponent
  },
  {
    path: 'patient-admission-charts/:admissionId',
    pathMatch: 'full',
    component: PatientsAdmissionChartsComponent
  },
  {
    path: 'patient-admission-signature/:admissionId',
    pathMatch: 'full',
    component: PatientsAdmissionSignatureComponent
  },
  {
    path: 'patient-admission-details/:admissionId',
    pathMatch: 'full',
    component: PatientAdmissionDetailsComponent
  },
  {
    path: 'patients-admissions/:patientId',
    pathMatch: 'full',
    component: PatientsAdmissionsComponent
  },
  {
    path: 'patients-evaluation-details/:patientId/:admissionId',
    pathMatch: 'full',
    component: PatientsEvaluationDetailsComponent
  },
  {
    path: 'patients-evaluations/:patientId/:admissionId',
    pathMatch: 'full',
    component: PatientEvaluationsComponent
  },
  {
    path: 'login',
    pathMatch: 'full',
    component: LoginComponent
  },
  {
    path: 'logout',
    pathMatch: 'full',
    component: LogoutComponent
  },
  {
    path: 'quick-menu/:returnUrl',
    pathMatch: 'full',
    component: QuickMenuComponent
  },
  {
    path: 'quick-menu',
    pathMatch: 'full',
    component: QuickMenuComponent
  },
  {
    path: 'settings',
    pathMatch: 'full',
    component: SettingsComponent
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
];

@NgModule({
  imports: [
      CommonModule,
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules  })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
