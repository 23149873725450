import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {take, map, tap, delay, switchMap, catchError} from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import {Router} from '@angular/router';
import {BehaviorSubject, Observable} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {sha256} from 'js-sha256';
import has = Reflect.has;
import {File} from '@ionic-native/file/ngx';
import {FileOpener} from '@ionic-native/file-opener/ngx';
import {Platform} from '@ionic/angular';
import { from } from 'rxjs';
import {CommonService} from './common.service';
import {Datasources} from '../models/datasource.types';
import {LogService} from './log.service';
import {LogServiceDUMMY} from './log.serviceDUMMY';
import {TokenResponseModel} from '../models/token.response.model';
import {environment} from '../../environment';
import {StateMessage} from '../models/StateMessage.model';

@Injectable({
    providedIn: 'root'
})
export class HttpService {
    private tokenCookie = null;

    private langCookie = 'el';
    loginStateChanged = new EventEmitter<string>();
    languageChanged = new EventEmitter<string>();


    httpResponseMessageReceived = new EventEmitter<string>();
    httpResponseCodeReceived = new EventEmitter<any>();
    patchingReportFieldFailed = new EventEmitter<any>();

    httpService_stateMessageChanged = new EventEmitter<StateMessage>();

    constructor(
        private http: HttpClient,
        public router: Router,
        private cookieService: CookieService,
        private translate: TranslateService,
        private file: File,
        private fileOpener: FileOpener,
        private platform: Platform) {
    }

    public isLoggedIn() {
        console.log('this.cookieService.get(token)');
        console.log(this.cookieService.get('token'));

        console.log('tokenCookie');
        console.log(this.tokenCookie);

        if (this.tokenCookie === undefined || this.tokenCookie === null || this.tokenCookie === '' ) {
            this.tokenCookie = this.getTokenFromCookie();
            return this.validateTokenCookie();
        } else {
            return this.validateTokenCookie();
        }
    }

    validateTokenCookie() {
        console.log('validateTokenCookie');

        let validationResult = false;

        if (this.tokenCookie === undefined || this.tokenCookie === null || this.tokenCookie === ''  ) {
            validationResult = false;
        } else {
            console.log('token cookie length: ', this.tokenCookie.length);
            if (this.tokenCookie.length > 20) {
                validationResult = true;
            }
        }

        console.log('validationResult: ', validationResult);
        return validationResult;
    }

    getTokenFromCookie() {
        const tokenFromCookie =  this.cookieService.get('token');
        this.tokenCookie = tokenFromCookie;
        console.log('getTokenFromCookie');
        console.log(tokenFromCookie);

        return tokenFromCookie;
    }

    public checkAuth() {
        this.tokenCookie = this.cookieService.get('token');
        //
        // if (!this.tokenCookie){
        //     if (!this.cookieService.get('token')){
        //         this.router.navigateByUrl('/login');
        //     } else {
        //         this.tokenCookie = this.cookieService.get('token');
        //     }
        // }
    }

    public logout() {
        console.log('logout');
        this.tokenCookie = null;
        this.cookieService.set('token', '', 60, '/');
        this.loginStateChanged.emit('loggedOut');
    }

    login0(txtPhoneCountryCode: string, txtPhoneNumber: string) {

        const fullPhoneNumber = '(' + txtPhoneCountryCode + ',' + txtPhoneNumber + ')';
        this.httpService_stateMessageChanged.emit(new StateMessage('logging in...', 'logging in: ' + fullPhoneNumber));


        console.log(txtPhoneCountryCode, txtPhoneNumber);

        console.log('login from httpService');
        return this.http.post(environment.AUTH_ROOT_URL + '/rpc/totp_send', { _mobile: fullPhoneNumber })
            .pipe(map(r => {
                    console.log('login response: ', r);
                    return r['otp'];
                }
            ));
    }

    login(otp: string, txtPhoneCountryCode: string, txtPhoneNumber: string) {

        const fullPhoneNumber = '(' + txtPhoneCountryCode + ',' + txtPhoneNumber + ')';

        console.log(txtPhoneCountryCode, txtPhoneNumber);
        //   this.httpService_stateMessageChanged.emit(new StateMessage('logging in...', 'logging in: ' + username));


        console.log('login from httpService');

        const postData = { _mobile: fullPhoneNumber, _otp: otp };
        console.log(postData);


        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            vector: 'SCS'
        });


        return this.http.post(environment.AUTH_ROOT_URL + '/rpc/totp_login', postData, {headers})
            .pipe(map(r => {
                    console.log('login response: ', r);
                    this.cookieService.set('token', r['token'], 60, '');
                    this.tokenCookie = r['token'];
                    console.log('token: ', this.tokenCookie);

                    this.loginStateChanged.emit('loggedIn');
                    return this.tokenCookie;
                }
            ));
    }


    setLanguage(token: string) {
        console.log('getLangCookie');

        console.log('login from httpService');
        return this.get(environment.URL_ROOT + '/whoami', {}) // , token)
            .pipe(map(langData => {

                    let langDataToUse = 'en';
                    if (langData != null) {
                        langDataToUse = langData[0].language.toString().toLowerCase();
                    }

                    console.log('login response: ', langData);
                    this.cookieService.set('gnuhost_lang', langDataToUse, 60, '');
                    this.langCookie = langDataToUse;


                    console.log('langDataToUse: ', langDataToUse);
                    console.log('gnuhost_lang: ', this.langCookie);
                    this.languageChanged.emit(this.langCookie);

                    this.translate.setDefaultLang(this.langCookie);

                    return this.langCookie;

                    // console.log('login response: ', langData);
                    // this.cookieService.set('gnuhost_lang', langData[0].language, 60, '');
                    // this.langCookie = langData[0].language;
                    // console.log('gnuhost_lang: ', this.langCookie);
                    // this.languageChanged.emit(this.langCookie);
                    // this.translate.setDefaultLang(this.langCookie);
                }, err => {
                    this.langCookie = 'en';
                    this.cookieService.set('gnuhost_lang', 'en', 60, '');

                    this.translate.setDefaultLang('en');

                    return 'en';
                }
            ));
    }


    // TODO: remove 'externalToken' ?
    get(url: string, externalToken = null): Observable<any> {
        this.getTokenFromCookie();

        let tokenToUse = this.tokenCookie;
        if (externalToken != null) {
            tokenToUse = this.tokenCookie;
        }

        console.log('HTTP GET, tokenCookie: ', tokenToUse);

        const headers = new HttpHeaders({
            Authorization: 'Bearer ' + this.tokenCookie
        });

        console.log('tokenCookie: ', this.tokenCookie);

        console.log('url: ', url);

        return this.http.get (url, {headers, observe: 'response'})
            .pipe(map(getResponse => {
//                console.log(getResponse);

                this.httpResponseCodeReceived.emit({status: getResponse.status.toString(), data: getResponse});

                return getResponse.body;
            }), catchError((err) => {

                const message = 'GET RESPONSE ERROR: ' + url;
                console.log(message);
                this.httpResponseCodeReceived.emit({status: '400', data: err});
                return err;

            }));
    }

    post(url: string, postData: any): Observable<any> {
        console.log('post: ' + url);
        this.getTokenFromCookie();
        console.log('HTTP POST, tokenCookie: ', this.tokenCookie);
        console.log('POSTDATA: (Printing in console)');
        console.log(postData);
        const headersToUse = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.tokenCookie,
            Prefer: 'return=representation',
            hash: ''
        });

      //  const options = {headers};



        return this.http.post(url, postData, {headers: headersToUse, observe: 'response'})
            .pipe(map(postResponse => {

                console.log('http.service.post => returning postResponse');
                this.httpResponseCodeReceived.emit({status: postResponse.status.toString(), data: postResponse});
                return postResponse.body;
            }), catchError((err) => {

                const message = 'POST ERROR: ' + url;
                console.log(message);
                console.log(err);

                const formattedErrorMessage = 'url: ' + url + '<br />postdata:' +  JSON.stringify(postData) + '<br />headers: ' + JSON.stringify(headersToUse) + '<br />error: ' + JSON.stringify(err);

                this.httpResponseMessageReceived.emit('httpResponseMessageReceived: ' + formattedErrorMessage);
                this.httpResponseCodeReceived.emit({status: '400', data: err});
                return err;
            }));
    }

    patch(url: string, postData: any, reportField: string = ''): Observable<any> {
        console.log('patch: ' + url);
        this.getTokenFromCookie();
        console.log('HTTP PATCH, tokenCookie: ', this.tokenCookie);
        console.log('POSTDATA: (not printing in console)');
        console.log(postData);
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.tokenCookie,
            hash: ''
        });

        return this.http.patch(url, postData, {headers, observe: 'response'})
            .pipe(map(postResponse => {
                // console.log(postResponse);
                console.log('http.service.PATCH => returning PATCHResponse');
                this.httpResponseCodeReceived.emit({status: postResponse.status.toString(), data: postResponse});
                return postResponse.body;
            }), catchError((err) => {
                const message = 'PATCH ERROR: ' + url;
                console.log(message);
                this.httpResponseCodeReceived.emit({status: '400', data: err});
                this.patchingReportFieldFailed.emit({reportField});
                return err;
            }));
    }

    delete(url: string): Observable<any> {
        console.log('delete: ' + url);
        this.getTokenFromCookie();
        console.log('HTTP delete, tokenCookie: ', this.tokenCookie);

        // console.log(postData);
        const headers = new HttpHeaders({
            // 'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.tokenCookie,
            hash: ''
        });

        //  const options = {headers};


        return this.http.delete(url, {headers, observe: 'response'})
            .pipe(map(postResponse => {

                // console.log(postResponse);
                console.log('http.service.DELETE => returning DELETEResponse');
                this.httpResponseCodeReceived.emit({status: postResponse.status.toString(), data: postResponse});
                return postResponse.body;
            }), catchError((err) => {

                const message = 'DELETE ERROR: ' + url;
                console.log(message);
                this.httpResponseCodeReceived.emit({status: '400', data: err});
                return err;

            }));
    }



    listPhoneCountries() {


        const headers = new HttpHeaders({
            vector: 'SCS'
        });


        return this.http.get(environment.AUTH_ROOT_URL + '/countries', {headers})
            .pipe(map(r => {
                    console.log('countries response: ', r);
                    // @ts-ignore
                    return r;
                }
            ));
    }
}
