import { Component, OnInit } from '@angular/core';
import {Subscription} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {CoreService} from "../../services/core.service";
import {ModalController, Platform} from "@ionic/angular";
import {HttpService} from "../../services/http.service";
import {TranslateService} from "@ngx-translate/core";
import {PatientService} from "../../services/patient.service";
import {ModalPatientsUpsertComponent} from "../shared/modal-patients-upsert/modal-patients-upsert.component";
import {Capacitor, Plugins} from "@capacitor/core";

@Component({
  selector: 'app-patients',
  templateUrl: './patients.component.html',
  styleUrls: ['./patients.component.scss'],
})
export class PatientsComponent implements OnInit {

  patients = [];
  patientsFiltered = [];
  searchPhrase = '';
  isLoading = false;
  searchVisible = false;

  private patientsAvailableChangedSub: Subscription;

  constructor(private route: ActivatedRoute,
              public router: Router,
              public coreService: CoreService,
              public patientService: PatientService,
              public modalCtrl: ModalController,
              private httpService: HttpService,
              private platform: Platform,
              private translate: TranslateService) {
    this.searchPhrase = '';
  }

  ngOnDestroy() {
    if (this.patientsAvailableChangedSub) {
      this.patientsAvailableChangedSub.unsubscribe();
    }
  }

  ngOnInit() {
    if (!this.httpService.isLoggedIn()) {
      this.router.navigateByUrl('/login');
    }

    this.isLoading = true;

    this.patientService.loadPatients().subscribe(r => {
      console.log(r);
      this.isLoading = false;
      this.patients = r;
      this.patientsFiltered = r;

    }, err => {
      console.log(err);
      this.coreService.broadcastErrorMessage('Fetching patients failed ', err);
      this.isLoading = false;
    });

    this.patientsAvailableChangedSub = this.patientService.patientsAvailableChanged
        .subscribe( r => {
              //email

              console.log('patientsAvailableChanged:');
              console.log(r);

              console.log(this.patients);

              console.log(this.patientsFiltered);
              r.forEach( x => {

                let item = this.patients.filter(i => i.id_card === x.id_card).slice();
                console.log(item);
                if(item !== null) {
                  console.log('item found');
                  console.log(item[0]);

                  item[0].first_name      =                       x.first_name       ;
                  item[0].last_name       =                       x.last_name        ;
                  item[0].reg_no          =                       x.reg_no           ;
                  item[0].email           =                       x.email            ;
                  item[0].birth_dt        =                       x.birth_dt         ;
                  item[0].marital_status  =                       x.marital_status   ;
                  item[0].id_card         =                       x.id_card          ;
                  item[0].address         =                       x.address          ;
                  item[0].postcode        =                       x.postcode         ;
                  item[0].phone           =                       x.phone            ;
                  item[0].profession      =                       x.profession       ;
                  item[0].retired         =                       x.retired          ;
                  item[0].religion        =                       x.religion         ;
                  item[0].language        =                       x.language         ;
                }
              });
            }
        );
  }

  loadMenu(patientId: string){
    this.router.navigateByUrl('/patient-menu/' + patientId);
  }

  openAdmissions(id: string){
     this.router.navigateByUrl('/patients-admissions/' + id);
  }

  openAddPatientModal() {
    this.modalCtrl
        .create({
          component: ModalPatientsUpsertComponent,
          componentProps: {  showHeader: false, action: "add", patientItem: null  },
          cssClass: 'bg-none'
        })
        .then(modalEl => {
          modalEl.present();
          return modalEl.onDidDismiss();
        });
  }

  filterItems() {
    console.log('searchPhrase:');
    console.log(this.searchPhrase);
    const f1 = this.patients.filter(x => x.full_name!.toLowerCase().includes(this.searchPhrase!.toLowerCase()));
    const f2 = [];
    //const f2 = this.patients.filter(x => x.first_name!.toLowerCase().includes(this.searchPhrase!.toLowerCase()));

    // this.patients.map(x => {
    //   x.vaccines.map(v => {
    //     if (v.toLowerCase().includes(this.searchPhrase.toLowerCase())) {
    //       f3.push(x);
    //     }
    //   });
    // });

    const f4 = f1.concat(f2);

    const uniqueItems = [...new Set(f4)];

    this.patientsFiltered = uniqueItems.slice();
    console.log('screenings filtered');
    console.log(this.patientsFiltered);
  }

  call(phone: string){
    alert('calling ' + phone);
  }

  editPatient(patientItem: any){
    this.modalCtrl
        .create({
          component: ModalPatientsUpsertComponent,
          componentProps: {  showHeader: false, action: "edit", patientItem: patientItem  },
          cssClass: 'bg-none'
        })
        .then(modalEl => {
          modalEl.present();
          return modalEl.onDidDismiss();
        });
  }

  pinLocation(){
    alert('saving location...');
    console.log('saving location');

 //   console.log('platform android: ', this.platform.;
    console.log('platform android: ', this.platform.is('android'));
    console.log('platform ios: ', this.platform.is('ios'));
    this.platform.ready().then(() => {
      if (Capacitor.isPluginAvailable('Geolocation')) {
        console.log('geolocation available!');
      } else {
        console.log('geolocation NOT available!');
      }
    });
  }
}
