import {Component, Input, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {ModalController, NavController, Platform} from '@ionic/angular';
import {DomSanitizer} from '@angular/platform-browser';
import {Location} from '@angular/common';
import {filter} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {ModalPatientsUpsertComponent} from '../modal-patients-upsert/modal-patients-upsert.component';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Input() title = '';
  @Input() closeUrl = '';
  @Input() showControls = true;
  currentUrl: string;


  constructor(private route: ActivatedRoute,
              public router: Router,
              private navCtrl: NavController,
              private sanitizer: DomSanitizer,
              public modalCtrl: ModalController,
              private location: Location,
              private platform: Platform,
              private screenOrientation: ScreenOrientation) {
  }

  ngOnInit() {
    console.log('platform android: ',    this.platform.is('android'));
    //this.platform.ready().then(() => {
    //if (Capacitor.isPluginAvailable('ScreenOrientation')) {

    if (this.platform.is('android')) {
      console.log('current orientation:');
      // get current
      console.log(this.screenOrientation.type); // logs the current orientation, example: 'landscape'


      // console.log('setting landscape orientation:');
      // set to landscape
      //this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.LANDSCAPE);

      // allow user rotate
      this.screenOrientation.unlock();

      // detect orientation changes
      this.screenOrientation.onChange().subscribe(
          () => {
            console.log('Orientation Changed');
          }
      );
    }
    // }
    //});
  }
  openPatients() {
    this.router.navigateByUrl( '/patients');
  }
  openLogin() {
    this.router.navigateByUrl( '/login');
  }
  openQuickMenu() {
    this.router.navigateByUrl( '/quick-menu');
  }

  openAddPatientModal() {
    this.modalCtrl
        .create({
          component: ModalPatientsUpsertComponent,
          componentProps: {  showHeader: false, action: "add", patientItem: null  },
          cssClass: 'bg-none'
        })
        .then(modalEl => {
          modalEl.present();
          return modalEl.onDidDismiss();
        });
  }

  openSettings() {
    this.router.navigateByUrl( '/settings');
  }

  titleSanitized(title) {
    return this.sanitizer.bypassSecurityTrustHtml(title);
  }

  goback() {
    this.location.back();
  }

  returnHome() {
    this.router.navigateByUrl('/home' );
  }
}
