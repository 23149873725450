export class FormDropdownSectionData {
    constructor(
        public isDisabled: boolean,
        public sectionName: string,
        public selectedItem: FormDropdownSectionItem,
        public allItems: FormDropdownSectionItem[]) {}
}


export class FormDropdownSectionItem {
    constructor(
        public name: string,
        public id: string) {}
}
