import { Component, OnInit } from '@angular/core';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {CoreService} from '../../services/core.service';
import {ModalController} from '@ionic/angular';
import {HttpService} from '../../services/http.service';
import {TranslateService} from '@ngx-translate/core';
import {ModalVisitorsUpsertComponent} from '../shared/modal-visitors-upsert/modal-visitors-upsert.component';
import {NurseService} from '../../services/nurse.service';

@Component({
  selector: 'app-visitors',
  templateUrl: './nurses.component.html',
  styleUrls: ['./nurses.component.scss'],
})
export class NursesComponent implements OnInit {

  nurses = [];
  nursesFiltered = [];
  searchPhrase = '';
  isLoading = false;

  private nursesAvailableChangedSub: Subscription;

  constructor(private route: ActivatedRoute,
              public router: Router,
              public coreService: CoreService,
              private nurseService: NurseService,
              public modalCtrl: ModalController,
              private httpService: HttpService,
              private translate: TranslateService) {
  }

  ngOnDestroy() {
    if (this.nursesAvailableChangedSub) {
      this.nursesAvailableChangedSub.unsubscribe();
    }
  }

  ngOnInit() {
    if (!this.httpService.isLoggedIn()) {
      this.router.navigateByUrl('/login');
    }

    this.isLoading = true;

    this.nurseService.loadNurses().subscribe(r => {
      console.log(r);
      this.isLoading = false;
      this.nurses = r;
      this.nursesFiltered = r;

    }, err => {
      console.log(err);
      this.coreService.broadcastErrorMessage('Fetching nurses failed ', err);
      this.isLoading = false;
    });

    this.nursesAvailableChangedSub = this.nurseService.nursesAvailableChanged
        .subscribe( r => {
          // email

          console.log('visitorsAvailableChanged:');
          console.log(r);

          console.log(this.nurses);

          console.log(this.nursesFiltered);
          r.forEach( x => {

              const item = this.nurses.filter(i => i.username === x.username).slice();
              console.log(item);
              if (item !== null){
                console.log('item found');
                console.log(item[0]);

                item[0].username = x.username;
                item[0].email = x.email;
                item[0].first_name = x.first_name;
                item[0].last_name = x.last_name;
                item[0].id_card = x.id_card;
                item[0].phone = x.phone;

              }

          });

              // this.nurses = r;
              // this.visitorsFiltered = r;
            }
        );
  }


  openAddVisitorModal() {
      this.modalCtrl
          .create({
            component: ModalVisitorsUpsertComponent,
            componentProps: {  showHeader: false, action: 'add', visitorItem: null  },
            cssClass: 'bg-none'
          })
          .then(modalEl => {
            modalEl.present();
            return modalEl.onDidDismiss();
          });
    }



  filterItems() {
    const f1 = this.nurses.filter(x => x.name.toLowerCase().includes(this.searchPhrase.toLowerCase()));
    const f2 = [];
    // const f2 = this.nurses.filter(x => x.name.toLowerCase().includes(this.searchPhrase.toLowerCase()));

    // this.nurses.map(x => {
    //   x.vaccines.map(v => {
    //     if (v.toLowerCase().includes(this.searchPhrase.toLowerCase())) {
    //       f3.push(x);
    //     }
    //   });
    // });

    const f4 = f1.concat(f2);

    const uniqueItems = [...new Set(f4)];

    this.nursesFiltered = uniqueItems.slice();
    console.log('screenings filtered');
    console.log(this.nursesFiltered);
  }

  call(phone: string){
    alert('calling ' + phone);
  }

  editVisitor(visitorItem: any){
    this.modalCtrl
        .create({
          component: ModalVisitorsUpsertComponent,
          componentProps: {  showHeader: false, action: 'edit', visitorItem  },
          cssClass: 'bg-none'
        })
        .then(modalEl => {
          modalEl.present();
          return modalEl.onDidDismiss();
        });
  }


}
