import {Component, Input, OnInit} from '@angular/core';
import {CoreService} from "../../../services/core.service";
import {FormBooleanSectionData} from "../formbooleansectiondata.model";
import {FormDropdownSectionData, FormDropdownSectionItem} from "../formdropdownsectiondata.model";

@Component({
  selector: 'app-form-dropdown-section',
  templateUrl: './form-dropdown-section.component.html',
  styleUrls: ['./form-dropdown-section.component.scss'],
})
export class FormDropdownSectionComponent implements OnInit {
  @Input() isDisabled: boolean;
  @Input() sectionName: string;
  @Input() selectedItem: FormDropdownSectionItem;
  @Input() allItems: FormDropdownSectionItem[];

  constructor(private coreService: CoreService) { }

  ngOnInit() {}

  checkIfSelected(item: any){
      // console.log('checkIfSelected');
     //  console.log(item);

     let sel = false;


     if(this.selectedItem === null || this.selectedItem === undefined){

     }else{
         // console.log('selectedItem: ', this.selectedItem);

         if(this.selectedItem !== undefined ){

             //console.log('zzz: ', this.selectedItem);
             if(this.selectedItem.id !== undefined ){
                 if(this.selectedItem.id.toString() === item.id.toString()){
                     sel = true;
                 }
             }else{
                 if(this.selectedItem.toString() === item.id.toString()){
                     sel = true;
                 }
             }

         }

     }

     return sel;
  }

  selectChangeHandler (event: any) {
        //update the ui

        console.log(event.target.value);
        //    this.selectedDay = event.target.value;

        const newSelectedItem = this.allItems.filter(x => x.id == event.target.value.toString()).slice()[0];

            //this.allItems.filter(x => x.value === event.target.value.toString()).slice(0,1);
      //  console.log('newSelectedItem');
      //  console.log(newSelectedItem);
        this.coreService.setFormDropdownSectionUpdate(new FormDropdownSectionData(this.isDisabled, this.sectionName, newSelectedItem, this.allItems));
  }

  // brodcastsetFormDropdownSectionUpdate(selectedItem: FormDropdownSectionItem) {
  //   console.log('brodcastsetFormDropdownSectionUpdate');
  //
  //
  //   const newSelectedItem =
  //   this.coreService.setFormDropdownSectionUpdate(new FormDropdownSectionData(this.isDisabled, this.sectionName, selectedItem, this.allItems));
  // }
}
