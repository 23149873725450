import {Component, Input, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {CoreService} from "../../../services/core.service";
import {PatientService} from "../../../services/patient.service";
import {ModalController} from "@ionic/angular";
import {HttpService} from "../../../services/http.service";
import {TranslateService} from "@ngx-translate/core";
import {TranslationsCommonService} from "../../../services/translations.common.service";

@Component({
  selector: 'app-modal-patient-evaluation-upsert',
  templateUrl: './modal-patient-evaluation-upsert.component.html',
  styleUrls: ['./modal-patient-evaluation-upsert.component.scss'],
})
export class ModalPatientEvaluationUpsertComponent implements OnInit {
  @Input() admissionId: string;
  @Input() showHeader: number;

  @Input() action: string;
  @Input() evaluationItem: any;

  // evaluation_cardDisabled = false;
  // admission_cardDisabled = false;

  evaluation = "";
  create_dt ="";
  creator ="";
  admission ="";
  vision ="";
  hearing ="";
  respiratory ="";
  surgeries ="";
  alcohol = false;
  allergies ="";
  is_abused = false;

  reporter ="";


  isLoading = false;


  /////// form-bool settings  //////////////////
  form_bool_ALCOHOL = 'form_bool_ALCOHOL';
  form_bool_ABUSED = 'form_bool_ABUSED';
  //////////////////////////////////////////////////

  formName = 'addPatient';
  private submitSectionSub: Subscription;
  private formBooleanSectionSub: Subscription;

  constructor(private route: ActivatedRoute,
              public router: Router,
              public coreService: CoreService,
              public patientService: PatientService,
              public modalCtrl: ModalController,
              private httpService: HttpService,
              private translate: TranslateService,
              public translationsCommonService: TranslationsCommonService) {
  }

  ngOnDestroy() {
    if (this.submitSectionSub) {
      this.submitSectionSub.unsubscribe();
    }
  }

  ngOnInit() {
    if (!this.httpService.isLoggedIn()) {
      this.router.navigateByUrl('/login');
    }

    /////// form-boolean update handler  //////////////////
    this.formBooleanSectionSub = this.coreService.formBooleanSectionsChanged.subscribe(
        formBooleanSectionDataItems  => {
          console.log('formBooleanSectionSub: ');
          console.log(formBooleanSectionDataItems);

          const formBooleanSectionItem_ABUSED = formBooleanSectionDataItems.find(x => x.sectionName === this.form_bool_ABUSED);

          if (formBooleanSectionItem_ABUSED != null) {
            this.is_abused = formBooleanSectionItem_ABUSED.position;
          }

          const formBooleanSectionItem_ALCHOHOL = formBooleanSectionDataItems.find(x => x.sectionName === this.form_bool_ALCOHOL);

          if (formBooleanSectionItem_ALCHOHOL != null) {
            this.alcohol = formBooleanSectionItem_ALCHOHOL.position;
          }
        }
    );
    ////////////////////////////////////////////////////////////


    if (this.action === "edit") {
      console.log('modal action: ' + this. action);
      console.log('evaluationItem:');
      console.log(this.evaluationItem);

      //this.patientid_cardDisabled = true;

      this.evaluation                        =                 this.evaluationItem.evaluation.value                 ;
     // this.create_dt                         =                 this.evaluationItem.create_dt.value                   ;
      //this.creator                           =                 this.evaluationItem.creator.value                     ;
      this.admission                         =                 this.evaluationItem.admission.value                   ;
      this.vision                            =                 this.evaluationItem.vision.value                      ;
      this.hearing                           =                 this.evaluationItem.hearing.value                     ;
      this.respiratory                       =                 this.evaluationItem.respiratory.value                 ;
      this.surgeries                         =                 this.evaluationItem.surgeries.value                   ;
      this.alcohol                           =                 this.evaluationItem.alcohol.value                     ;
      this.allergies                         =                 this.evaluationItem.allergies.value                   ;
      this.is_abused                         =                 this.evaluationItem.is_abused.value                   ;
      this.reporter                          =                 this.evaluationItem.reporter.value                    ;
    }else{
      this.admission = this.admissionId;
    }



    this.submitSectionSub = this.coreService.submitSectionsChanged.subscribe(
        submitSectionDataItems  => {
          console.log('submitSectionSub: ');
          console.log(submitSectionDataItems);

          const submitSectionItem = submitSectionDataItems.find(x => x.sectionName === this.formName);

          if (submitSectionItem != null){
            if (submitSectionItem.cancelBroadcasted === true) {
              //this.router.navigateByUrl('/vaccine-lots'  );
              this.modalCtrl.dismiss();
            }

            if (submitSectionItem.submitBroadcasted === true) {
                this.onUpsertPatientEvaluation( );
            }
          }
        }
    );
  }



  onUpsertPatientEvaluation() {
    console.log('onUpsertPatientEvaluation');
    this.isLoading = true;



    this.patientService.upsertEvaluation(
        this.action,
        this.admissionId,
        this.evaluation              ,
       // this.create_dt               ,
        this.creator                 ,
        this.admission               ,
        this.vision                  ,
        this.hearing                 ,
        this.respiratory             ,
        this.surgeries               ,
        this.alcohol                 ,
        this.allergies               ,
        this.is_abused               ,
        this.reporter
    ).subscribe(r => {
      console.log(r);
      this.isLoading = false;
      this.coreService.broadcastToastMessage(this.translationsCommonService.TRNSL_DATA_SAVED_SUCCESSFULLY, false);

      this.patientService.broadcastInfoAboutAddedEvaluation(r);

      this.modalCtrl.dismiss();
      //this.router.navigateByUrl('/vaccine-lots');
    }, err => {
      console.log(err);
      this.coreService.broadcastErrorMessage(this.translationsCommonService.TRNSL_DATA_SAVING_FAILED, err);
      this.isLoading = false;
    });
  }

  checkIfFormValid() {
    return true;
  }
}
