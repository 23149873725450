import {Component, Input, OnInit} from '@angular/core';
import {WoundsService} from '../../../services/wounds.service';
import {TranslationsCommonService} from '../../../services/translations.common.service';
import {CoreService} from '../../../services/core.service';
import {FormBooleanSectionData} from '../../shared/formbooleansectiondata.model';

@Component({
  selector: 'app-wound-form-boolean-section-dbdependent',
  templateUrl: './wound-form-boolean-section-dbdependent.component.html',
  styleUrls: ['./wound-form-boolean-section-dbdependent.component.scss'],
})
export class WoundFormBooleanSectionDbdependentComponent implements OnInit {
  @Input() isDisabled: boolean;
  @Input() fieldName: string;
  @Input() position: boolean;
  @Input() woundId: string;
  @Input() reportType: string;
  @Input() displayName: string;
  @Input() presentationType: string;
  @Input() savedInDb: boolean;
  @Input() showSaveIndicator = true;

  constructor(private coreService: CoreService,
              private woundService: WoundsService,
              private  translationsCommonService: TranslationsCommonService) {
  }

  ngOnInit() {
    console.log('WoundsFormBooleanSectionComponent, position:', this.position);
  }

  setPosition(newPosition: boolean) {
    console.log('setPosition: ', newPosition);

    // perform PATCH here
    this.woundService.patchWoundField(this.woundId, this.fieldName, newPosition).subscribe(r => {
      this.position = newPosition;
      console.log('new position: ', this.position);
      this.coreService.setFormBooleanSectionUpdate(new FormBooleanSectionData(this.isDisabled, this.fieldName, this.position));
    }, err => {
      console.log(err);
      this.coreService.broadcastErrorMessage('Patching REPORT_FIELDS failed ', err);
    });
  }

  getTileClass() {
    if (this.position === true) {
      return 'positionActive';
    } else {
      return 'positionInactive';
    }
  }
}
