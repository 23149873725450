import {Component, OnDestroy, OnInit} from '@angular/core';
import {CoreService} from '../../services/core.service';
import {Router} from '@angular/router';
import {LoadingController, Platform} from '@ionic/angular';
import {NgForm} from '@angular/forms';
import {HttpService} from '../../services/http.service';
import {CookieService} from 'ngx-cookie-service';
import {Subscription} from 'rxjs';
import {NurseService} from '../../services/nurse.service';

import {Location} from '@angular/common';
import {environment} from '../../../environment';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
    // da = [];
    // isLoading = false;
    // isLogin = true;
    // message = '';
    // txtPhoneCountryCode = '';
    // txtPhoneNumber = '';
    //
    // regCountryCode = '';
    // regOrgName = 'Limassol Clinic';
    //
    // ORGS_FOR_COUNTRY = [];



    txtPhoneCountryCode = '357';
    txtPhoneNumber = '';
    message = '';





    feedback = '';

    mobileSend = false;
    loginBtnDisabled = false;
    resendBtnDisabled;

    otp = '';

    phoneError = '';
    phoneInit = false;
    phoneChecked = false;
    sendBtnDisabled = false;


    countries = [];
    prefix = 357;
    number = '';
    token;
    loading;


    platformMobile = true;
    private loginStateSub: Subscription;

    // https://github.com/xUnholy/cordova-device-accounts-demo/blob/master/src/app/home/home.page.ts
    constructor(
        private coreService: CoreService,
        public router: Router,
        private loadingCtrl: LoadingController,
        private httpService: HttpService,
        private cookieService: CookieService,
        private location: Location,
        private platform: Platform
        //    private deviceAccounts: DeviceAccounts
    ) {

        location.onUrlChange((url, state) => {
            this.ngOnInit();
        });

        if (this.platform.is('desktop') || this.platform.is('mobileweb')  ) {
            this.platformMobile = false;
        } else {
            this.platformMobile = true;
        }
    }

    ngOnDestroy() {
        if (this.loginStateSub) {
            this.loginStateSub.unsubscribe();
        }
    }

    ngOnInit() {
        this.txtPhoneCountryCode = '';
        this.txtPhoneNumber = '';



        this.listPhoneCountries();

        // if (!this.httpService.isLoggedInPublic()){
        //
        //     this.httpService.loginPublicFunctions();
        //     // this.httpService.loginPublicFunctions().subscribe(x => {
        //     //     console.log(x);
        //     // });
        // }
        //
        // this.loginStateSub = this.httpService.loginStateChanged.subscribe( r => {
        //         console.log('LOGIN PAGE: loginStateChanged');
        //         // this.httpService.setLanguage().subscribe(langRes => {
        //         //     console.log(langRes);
        //         //
        //         // });
        //     }
        // );
    }

    // onLogin() {
    //   this.isLoading = true;
    //   this.loadingCtrl
    //     .create({ keyboardClose: true, message: 'Logging in...' })
    //     .then(loadingEl => {
    //       loadingEl.present();
    //
    //       console.log(this.txtPhoneCountryCode, this.txtPhoneNumber);
    //
    //       this.httpService.login0(this.txtPhoneCountryCode, this.txtPhoneNumber).subscribe(otp => {
    //
    //           this.httpService.login(otp, this.txtPhoneCountryCode, this.txtPhoneNumber).subscribe(token => {
    //
    //                   localStorage.clear();
    //                  this.httpService.setLanguage(token).subscribe(langRes => {
    //                   console.log(langRes);
    //                   loadingEl.dismiss();
    //                   this.router.navigateByUrl('/home');
    //               });
    //           }, err => {
    //               console.log(err);
    //               this.message = err.message;
    //               this.isLoading = false;
    //               loadingEl.dismiss();
    //           });
    //
    //       }, err => {
    //           console.log(err);
    //           this.message = err.message;
    //           this.isLoading = false;
    //           loadingEl.dismiss();
    //       });
    //     });
    // }

    // onSwitchAuthMode() {
    //   this.isLogin = !this.isLogin;
    // }
    //
    // register(){
    //     this.router.navigateByUrl('/registration/' + this.regCountryCode + '/' + this.regOrgName);
    // }
    //
    // checkCountrysOrganizations() {
    //   if (this.regCountryCode.length > 1) {
    //       this.registrationService.checkCountrysOrganizations(this.regCountryCode).subscribe(res => {
    //
    //            const xx = [];
    //             xx.push({organization: 0, country: 'CY', name: 'NEW ORGANIZATION'});
    //           // xx.push({organization: 6661, country: 'PL', name: 'Ministerstwo Śledzia i Wódki'});
    //           // xx.push({organization: 6662, country: 'PL', name: 'KGHM'});
    //           // xx.push({organization: 6663, country: 'PL', name: 'NBP'});
    //           // xx.push({organization: 6664, country: 'PL', name: 'Prokuratura Generalna Prokuratora Dziury W Dubie'});
    //           // xx.push({organization: 6665, country: 'PL', name: 'RKS Huwdu'});
    //           // xx.push({organization: 6666, country: 'PL', name: 'Tauron'});
    //           // xx.push({organization: 6667, country: 'PL', name: 'PKN Orlen'});
    //           // xx.push({organization: 6668, country: 'PL', name: 'PKP S.A.'});
    //           // xx.push({organization: 6669, country: 'PL', name: 'Drutex Polskie Okna'});
    //           // xx.push({organization: 6670, country: 'PL', name: 'Ministerstwo Choroby i Nagłej Śmierci'});
    //            this.ORGS_FOR_COUNTRY = xx;
    //
    //           this.ORGS_FOR_COUNTRY = xx.concat(res);
    //           console.log('ORGS_FOR_COUNTRY');
    //           console.log(this.ORGS_FOR_COUNTRY);
    //       }, err => {
    //           console.log(err);
    //           this.message = err.message;
    //       });
    //   }
    // }
    //
    // getListItemClass(currentOrgId: string){
    //   if (this.regOrgName === currentOrgId) {
    //       return 'highlightedListItem';
    //   }else {
    //       return '';
    //   }
    // }




    executeCountrySearch() {
        console.log('executing countrySearch');
    }


    listPhoneCountries() {
        this.httpService.listPhoneCountries().subscribe(countries => {
// @ts-ignore
            this.countries = countries;
        }, err => {
            console.log(err);
            this.message = err.message;
        });

        this.txtPhoneCountryCode = '357';
    }

    submitOtp() {
        this.httpService.login(this.otp, this.txtPhoneCountryCode, this.txtPhoneNumber).subscribe(token => {



            localStorage.clear();
            this.httpService.setLanguage(token).subscribe(langRes => {
                console.log(langRes);
                this.router.navigateByUrl('/home');


                // this.loadingCtrl
                //   .create({ keyboardClose: true, message: 'Logging in...' })
                //   .then(loadingEl => {
                //                 loadingEl.present();
                //                 localStorage.clear();
                //                 this.httpService.setLanguage(token).subscribe(langRes => {
                //                 console.log(langRes);
                //                 loadingEl.dismiss();
                //                 this.router.navigateByUrl('/home');
                //             });
                //   });
            }, err => {
                console.log(err);
                this.message = err.message;
            });
        });
    }

    requestOtp() {

        if (this.validatePhone() === true){

            console.log(this.txtPhoneCountryCode, this.txtPhoneNumber);

            this.httpService.login0(this.txtPhoneCountryCode, this.txtPhoneNumber).subscribe(otp => {


                this.otp = otp;
                this.mobileSend = true;
                // this.httpService.login(otp, this.txtPhoneCountryCode, this.txtPhoneNumber).subscribe(token => {
                //
                //   localStorage.clear();
                //
                //   this.router.navigateByUrl('/dashboard');
                // }, err => {
                //   console.log(err);
                //   this.message = err.message;
                // });

            }, err => {
                console.log(err);
                this.message = err.message;
            });
        }



    }

    validatePhone() {
        this.phoneChecked = false;
        this.phoneError = '';
        this.sendBtnDisabled = true;

        let phoneValid = false;
        // if (phoneInit) {
        if (this.txtPhoneNumber.length === 0) {
            this.phoneError = 'Phone number is required';
        } else if (this.txtPhoneNumber.length <= 3) {
            this.phoneError = 'The phone number must be at least 4 digits long';
        } else if (this.txtPhoneNumber.length >= 15) {
            this.phoneError = 'The phone number can be up to 15 digits long';
        } else if (!(/^\d+$/.test(this.txtPhoneNumber.replace(/\s/g, '')))) {
            this.phoneError = 'The phone number can only contain numbers';
        } else if (this.txtPhoneCountryCode === '') {
            this.phoneError = 'You have to choose country code';
        } else {
            phoneValid = true;
        }

        this.phoneChecked = true;
        this.sendBtnDisabled = false;
        console.log('phone error:' + this.phoneError);

        return phoneValid;
        // } else {
        //  phoneInit = true;
        // }
    }


    getOTPresendButtonClasses() {
        let initialClasses = 'text-decoration-underline resend-link cursor-pointer ';


        if (this.loading) {
            initialClasses = initialClasses + ' ' + 'active-link';
        }

        if (this.resendBtnDisabled) {
            initialClasses = initialClasses + ' ' + '    class=" {loading ? \'active-link\' : \'\'} {resendBtnDisabled ? \'disable-link\' : \'\'}"\n';
        }


        return initialClasses;
    }


    openAppInSystemBrowser(){
        const url = environment.WEB_APP_URL;
        window.open(url, '_system', 'location=yes');
    }
}
