import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router, RouterModule} from '@angular/router';
import {Calendar} from '@ionic-native/calendar/ngx';
import {ModalController} from '@ionic/angular';
import {QuickMenuComponent} from '../shared/quick-menu/quick-menu.component';
import {CoreService} from '../../services/core.service';
import {HttpService} from '../../services/http.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {ResourcesService} from '../../services/resources.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-home',
  templateUrl: 'home.page.html',
  styleUrls: ['home.page.scss'],
})
export class HomePage implements OnInit, OnDestroy{
    keyName = 'key1';
    txt1='xx';
    cres = '';
    cres2 = '';

  version = '';
  langCode = '';
  isLoggedIn = false;

  TRANSLATIONS = null;

  constructor(public router: Router,
              public coreService: CoreService,
              private httpService: HttpService,
              private resourcesService: ResourcesService,
              private translate: TranslateService) {}

              private loginStateSub: Subscription;
              private languageSub: Subscription;

    ngOnDestroy() {
        if (this.loginStateSub) {
            this.loginStateSub.unsubscribe();
        }

        if (this.languageSub) {
            this.languageSub.unsubscribe();
        }
    }

  ngOnInit() {
      if (!this.httpService.isLoggedIn()){
          this.router.navigateByUrl('/login');
      }

      this.langCode = this.resourcesService.getLangCode();

        console.log(this.TRANSLATIONS);
        console.log('HOME PAGE ONINIT');
                this.version = this.coreService.version;
                this.isLoggedIn = this.httpService.isLoggedIn();

                this.loginStateSub = this.httpService.loginStateChanged.subscribe( r => {
                            console.log('home: received loginState update event: ', r);
                            this.isLoggedIn = this.httpService.isLoggedIn();
                        }
                    );

         this.languageSub = this.httpService.languageChanged.subscribe(l => {
            console.log('home: received languageChanged update event: ', l);
            this.langCode = this.resourcesService.getLangCode();

          this.translate.use(this.langCode);
      });
     }

     loadPage(pageName: string) {
        console.log('loading page: ' + pageName);
        this.router.navigateByUrl('/' + pageName);
     }



}
