import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {take, map, tap, delay, switchMap} from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import {Router} from '@angular/router';
import {BehaviorSubject} from 'rxjs';
import {HttpService} from './http.service';
import {DatePipe} from '@angular/common';
import {CoreService} from './core.service';
import {url} from 'inspector';
import {Toast} from "../models/toast.model";
import {DataSourceInformation} from "../models/datasource.information.model";

@Injectable({
  providedIn: 'root'
})
export class CommonService {
    toastCommonMessageChanged = new EventEmitter<Toast>();


    constructor(public coreService: CoreService,
                private http: HttpClient,
                private cookieService: CookieService,
                public router: Router,
                private httpService: HttpService,
                private datepipe: DatePipe) {
    }

    broadcastToastMessage(message: string,  isError: boolean = false) {
        console.log('broadcastToastMessage: ', message, isError);
        this.toastCommonMessageChanged.emit(new Toast(message, isError));
    }

}
