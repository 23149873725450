import {Component, Input, OnInit} from '@angular/core';
import {CoreService} from "../../../services/core.service";
import {FormBooleanSectionData} from "../formbooleansectiondata.model";
import {PatientReportsService} from "../../../services/patientreports.service";
import {TranslationsCommonService} from "../../../services/translations.common.service";

@Component({
  selector: 'app-form-boolean-section-dbdependent',
  templateUrl: './form-boolean-section-dbdependent.component.html',
  styleUrls: ['./form-boolean-section-dbdependent.component.scss'],
})
export class FormBooleanSectionDbdependentComponent implements OnInit {
  @Input() isDisabled: boolean;
  @Input() sectionName: string;
  @Input() position: boolean;
  @Input() reportId: string;
  @Input() reportType: string;
  @Input() displayName: string;
  @Input() presentationType: string;
  @Input() savedInDb: boolean;
  @Input() showSaveIndicator = true;
  @Input() placeholder: string;

  constructor(private coreService: CoreService,
              private patientReportsService: PatientReportsService,
              private  translationsCommonService: TranslationsCommonService) {
  }

  ngOnInit() {
    console.log('FormBooleanSectionComponent, position:', this.position);
    //this.position = !this.position;
  }

  setPosition(newPosition: boolean) {

    if (this.isDisabled){
      console.log('DOING NOTHING BECAUSE: isDisabled==true');
    } else {
      console.log('setPosition: ', newPosition);

      // perform PATCH here
      this.patientReportsService.patchReportField(this.reportType, this.reportId, this.sectionName, newPosition).subscribe(r => {
        console.log('patchField report Id: ' + this.reportId + '; col: ' + this.sectionName + '; value: ' + newPosition);
        //this.coreService.broadcastToastMessage(this.translationsCommonService.TRNSL_DATA_SAVED_SUCCESSFULLY, false);
        //  this.REPORT_FIELDS_VALUES[array_index].state = 'saved';
        this.position = newPosition;
        console.log('new position: ',  this.position);
        this.coreService.setFormBooleanSectionUpdate(new FormBooleanSectionData(this.isDisabled, this.sectionName, this.position));
      }, err => {
        console.log(err);
        this.coreService.broadcastErrorMessage('Patching REPORT_FIELDS failed ', err);
        // this.REPORT_FIELDS_VALUES[array_index].state = 'fail';
      });
    }
  }
}
