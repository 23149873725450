import { Component, OnInit } from '@angular/core';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {CoreService} from '../../services/core.service';
import {PatientService} from '../../services/patient.service';
import {ModalController, Platform} from '@ionic/angular';
import {HttpService} from '../../services/http.service';
import {TranslateService} from '@ngx-translate/core';
import {ModalPatientEvaluationUpsertComponent} from '../shared/modal-patient-evaluation-upsert/modal-patient-evaluation-upsert.component';
import {ModalPatientEvaluationSectionComponent} from '../shared/modal-patient-evaluation-section/modal-patient-evaluation-section.component';
import {TranslateDbService} from '../../services/translate.db.service';

@Component({
  selector: 'app-patients-evaluation-details',
  templateUrl: './patients-evaluation-details.component.html',
  styleUrls: ['./patients-evaluation-details.component.scss'],
})
export class PatientsEvaluationDetailsComponent implements OnInit {

  /// old
  patientId = '';
  TITLE = '';

  patientItem = null;

  patientsAdmission = null;

  searchPhrase = '';
  isLoading = false;

  detailsVisible = false;
///


  admissionId: string | null;
  ALL_TRANSLATIONS = [];

  DATA: any = '';
  SELECTED_STAGES: any = [];
  ADMISSION_DETAILS: any = '';

  private patientsEvaluationsAvailableChangedSub: Subscription;

  constructor(private route: ActivatedRoute,
              public router: Router,
              public coreService: CoreService,
              public patientsService: PatientService,
              public modalCtrl: ModalController,
              private httpService: HttpService,
              private platform: Platform,
              public translateDb: TranslateDbService,
              private translate: TranslateService) {

    this.admissionId = this.route.snapshot.paramMap.get('admissionId');
  }


  ngOnInit(): void {

    this.admissionId = this.route.snapshot.paramMap.get('admissionId');
    if (!this.httpService.isLoggedIn()) {
      this.router.navigateByUrl('/login');
    }

    this.loadAdmissionDetails();

    this.translateDb.loadTranslations();
  }


  loadAdmissionDetails() {

  //  if (this.admissionId! == null) {
      this.patientsService.loadAdmission(this.admissionId?.toString()).subscribe(result => {
        console.log(result);
        this.ADMISSION_DETAILS = result[0];

        console.log(this.ADMISSION_DETAILS);


        this.loadEvaluationTable();
        this.loadEvaluationReport();

      }, err => {
        console.log(err);
        this.coreService.broadcastErrorMessage('', err);
        //this.coreService.formatAndBroadcastDiagnosticMessage(err);
      });
   // }
  }

  loadEvaluationReport() {
    // @ts-ignore
    this.patientsService.loadEvaluationReport(this.admissionId.toString()).subscribe(result => {
      console.log(result);
      this.SELECTED_STAGES = result;
    }, err => {
      console.log(err);
      this.coreService.broadcastErrorMessage('', err);
      //this.coreService.formatAndBroadcastDiagnosticMessage(err);
    });
  }

  loadEvaluationTable() {
    this.patientsService.loadEvaluationTable().subscribe(result => {
      console.log(result);
      this.DATA = result;
    }, err => {
      console.log(err);
      this.coreService.broadcastErrorMessage('', err);
      //this.coreService.formatAndBroadcastDiagnosticMessage(err);
    });
  }

  // getClass (stageId: number, activity: string) {
  //   const res = this.SELECTED_STAGES.filter((x: LawtonReportModel) => x.stage == stageId && x.activity===activity);
  //   if (res.length> 0) {
  //     return 'comhese-pointarea comhese-pointarea-selected';
  //   } else {
  //     return 'comhese-pointarea';
  //   }
  // }


  getCheckBoxValue(item: string) {

    let x = this.SELECTED_STAGES.filter((x: { [x: string]: string; }) => x['item'] == item);
    if (x.length > 0) {

      return true;
    } else {
      return false;
      console.log(item + ' not selected!');
    }
  }

  getPostData(item: string) {
    // @ts-ignore
    const admission = this.admissionId.toString()

    const postData = {
      admission,
      item
    };

    return JSON.stringify(postData);
  }


  getExistingNoteValue(item: string) {

    //console.log(item);
    //console.log(this.ADMISSION_DETAILS.notes);

    if (this.ADMISSION_DETAILS.notes != null) {

      const x = this.ADMISSION_DETAILS.notes.filter((x: { [x: string]: string; }) => x['note_type_id'] == item)[0]

      if (x === undefined) {
        return '';
      } else {
        return x.content;
      }
    } else {
      return '';
    }
  }

  buildNotesFieldName(item: string) {
    //console.log('buildNotesFieldName: ', item);
    return 'note_type_id' + item;
  }

  buildNotesJsonPostData(item: string) {
    const postData = {
      admission: this.admissionId,
      note_type_id: item,
      content: 'qqqREPLACEqqq'
    };

    const stringifiedPostData = JSON.stringify(postData);
    //console.log('buildNotesJsonPostData: ', stringifiedPostData);

    return stringifiedPostData;
  }

  openPatientEvaluationSection(admissionId: string, section_id: string ){
  //  switch (sectionName) {
     // case this.patientsService.PATIENT_EVALUATION_SECTION.HealthHistory:
        this.loadPatientSectionModal(admissionId,   section_id );
 //       break;
    //}
  }

  loadPatientSectionModal(admissionId: string, section_id: string ){

    console.log('loadPatientSectionModal: ' + admissionId);
    console.log('section_id: ' + section_id);

    this.modalCtrl
        .create({
          component: ModalPatientEvaluationSectionComponent,
          componentProps: { admissionId, section_id   },
          cssClass: 'bg-none'
        })
        .then(modalEl => {
          modalEl.present();
          return modalEl.onDidDismiss();
        });
  }

}
