import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {CoreService} from '../../../services/core.service';
import {PatientService} from '../../../services/patient.service';
import {ModalController, Platform} from '@ionic/angular';
import {HttpService} from '../../../services/http.service';
import {TranslateService} from '@ngx-translate/core';
import {TranslateDbService} from '../../../services/translate.db.service';

@Component({
  selector: 'app-modal-patient-evaluation-section',
  templateUrl: './modal-patient-evaluation-section.component.html',
  styleUrls: ['./modal-patient-evaluation-section.component.scss'],
})
export class ModalPatientEvaluationSectionComponent implements OnInit, AfterViewInit {
  @Input() admissionId: string;
  @Input() section_id: string;


  DATA: any = '';
  SELECTED_STAGES: any = [];
  ADMISSION_DETAILS: any = '';


  EVALUATION_ITEMS = [];

  admissionDetails = null;

  ALL_TRANSLATIONS = [];

  patientId = '';

  patientSectionItems = [];
  patientSectionItemsFiltered = null;


  notes = '';

  formName = 'patient-evaluation-section-';

  private patientsSectionChangedSub: Subscription;
  private submitSectionSub: Subscription;

  constructor(private route: ActivatedRoute,
              public router: Router,
              public coreService: CoreService,
              public patientsService: PatientService,
              public modalCtrl: ModalController,
              private httpService: HttpService,
              private platform: Platform,
              public translateDb: TranslateDbService,
              private translate: TranslateService) {
  }

  ngOnDestroy() {
    if (this.patientsSectionChangedSub) {
      this.patientsSectionChangedSub.unsubscribe();
    }

    if (this.submitSectionSub) {
      this.submitSectionSub.unsubscribe();
    }
  }

  ngAfterViewInit() {
    this.loadAdmissionDetails();
  }

  ngOnInit() {
    if (!this.httpService.isLoggedIn()) {
      this.router.navigateByUrl('/login');
    }

    this.translateDb.loadTranslations();

    this.loadAdmissionDetails();

    // this.loadPatientSection();
    // this.patientsSectionChangedSub = this.patientsService.patientsSectionChanged
    //     .subscribe( r => {
    //           this.loadPatientSection( );
    //         }
    //     );

    this.submitSectionSub = this.coreService.submitSectionsChanged.subscribe(
        submitSectionDataItems  => {
          console.log('submitSectionSub: ');
          console.log(submitSectionDataItems);

          const submitSectionItem = submitSectionDataItems.find(x => x.sectionName === this.formName);

          if (submitSectionItem != null){
            if (submitSectionItem.cancelBroadcasted === true) {
              this.modalCtrl.dismiss();
            }

            if (submitSectionItem.submitBroadcasted === true) {

              // save comment
              console.log('saving comment');
              this.saveNotes();

            }
          }
        }
    );
  }




  loadAdmissionDetails() {

    //  if (this.admissionId! == null) {
    this.patientsService.loadAdmission(this.admissionId?.toString()).subscribe(result => {
      console.log(result);
      this.ADMISSION_DETAILS = result[0];

      console.log(this.ADMISSION_DETAILS);

      this.loadEvaluationTable();
      this.loadEvaluationReport();

      if (this.DATA != '') {
        this.notes = this.getExistingNoteValue(this.DATA[0]['section_id']);
      }
    }, err => {
      console.log(err);
      this.coreService.broadcastErrorMessage('', err);
      // this.coreService.formatAndBroadcastDiagnosticMessage(err);
    });
    // }
  }

  loadEvaluationReport() {
    // @ts-ignore
    this.patientsService.loadEvaluationReport(this.admissionId.toString()).subscribe(result => {
      console.log(result);
      this.SELECTED_STAGES = result;
    }, err => {
      console.log(err);
      this.coreService.broadcastErrorMessage('', err);
      // this.coreService.formatAndBroadcastDiagnosticMessage(err);
    });
  }

  loadEvaluationTable() {
    this.patientsService.loadEvaluationTableForSection(this.section_id).subscribe(result => {
      console.log(result);
      this.DATA = result;
    }, err => {
      console.log(err);
      this.coreService.broadcastErrorMessage('', err);
      // this.coreService.formatAndBroadcastDiagnosticMessage(err);
    });
  }






































  saveNotes(){
    this.patientsService.savePatientSectionNotes(this.admissionId, this.section_id, this.notes).subscribe(r => {
      console.log(r);

      console.log('SAVING NOTES...');

    }, err => {
      console.log(err);
      this.coreService.broadcastErrorMessage('saving notes failed ', err);
    });
  }


  patientSection_toggleCheckbox(item: string) {

    console.log(item);

    this.patientsService.toggleEvaluationItem(this.admissionId, item).subscribe(r => {

      this.loadAdmissionDetails();
    }, err => {
      console.log(err);
      this.coreService.broadcastErrorMessage('adding PatientSectionItem failed ', err);
    });
  }

  getTileClass(item: string) {

    if (this.SELECTED_STAGES.filter(x => x.item == item).length > 0){
      return 'tileActive';
    } else {
      return '';
    }

  }

  checkIfFormValid() {
    return true;
  }


  getExistingNoteValue(item: string) {

    console.log('getExistingNoteValue');
    console.log(item);
    console.log(this.ADMISSION_DETAILS.notes);

    if (this.ADMISSION_DETAILS.notes != null) {

      const x = this.ADMISSION_DETAILS.notes.filter((x: { [x: string]: string; }) => x.note_type_id == item)[0];

      console.log(x);
      if (x === undefined){
        return '';
      } else {
        return x.content;
      }
    } else {
      return '';
    }
  }
}
