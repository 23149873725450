import {Component, OnDestroy, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {QuickMenuComponent} from '../quick-menu/quick-menu.component';
import {NavigationEnd, Router} from '@angular/router';
import {CoreService} from '../../../services/core.service';
import {filter} from 'rxjs/operators';
import {CommonService} from "../../../services/common.service";
import {Subscription} from "rxjs";
import {Datasources} from "../../../models/datasource.types";
import {HttpService} from "../../../services/http.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-quick-menu-icon',
  templateUrl: './quick-menu-icon.component.html',
  styleUrls: ['./quick-menu-icon.component.scss'],
})
export class QuickMenuIconComponent implements OnInit, OnDestroy {

    version = '0.0.0';
    currentUrl: string;
    dataSourceIndicator = '';

    dataSourceMessageVisible = false;
    dataSourceMessage = '';

    httpErrorReceived = false;
    httpErrorMessageVisible = false;
    httpErrorMessage = '';

    private dataSourceIndicatorChangedSub: Subscription;
    private errorMessageChangedSub: Subscription;

    private httpResponseCodeReceivedSub: Subscription;
    icon_httSuccessVisible = false;
    icon_httFailVisible = false;

    constructor(
      public modalCtrl: ModalController,
      public router: Router,
      public coreService: CoreService,
      private translate: TranslateService,
      private commonService: CommonService,
      private httpService: HttpService) {
        router.events
        .pipe(filter(event => event instanceof NavigationEnd))
        .subscribe((event: NavigationEnd) => {
          console.log('prev:', event.url);
          this.currentUrl = event.url;
        });
  }

  ngOnDestroy() {
      if (this.dataSourceIndicatorChangedSub) {
          this.dataSourceIndicatorChangedSub.unsubscribe();
      }

      if (this.errorMessageChangedSub) {
          this.errorMessageChangedSub.unsubscribe();
      }

      if (this.httpResponseCodeReceivedSub) {
          this.httpResponseCodeReceivedSub.unsubscribe();
      }
  }

  ngOnInit() {
    this.version = this.coreService.version;

    this.httpResponseCodeReceivedSub = this.httpService.httpResponseCodeReceived.subscribe( httpData => {
        const code = +httpData.status;

        if (code >= 200 && code < 300) {
            this.icon_httSuccessVisible = true;
        } else {
            if (code >= 400) {
                this.icon_httFailVisible = true;
                this.coreService.broadcastErrorMessage('http request failed:', httpData);
            }
        }

        setTimeout(z => {
            this.icon_httSuccessVisible = false;
            this.icon_httFailVisible = false;
        }, 750);
    });

    this.dataSourceIndicatorChangedSub = this.coreService.dataSourceIndicatorChanged.subscribe(dsUpdate => {
       // display appropriate icon
        this.dataSourceIndicator = dsUpdate.datasource;
        this.dataSourceMessage = dsUpdate.message;
    });

      this.errorMessageChangedSub = this.coreService.errorMessageChanged
          .subscribe(
              errorMessage  => {
                  console.log('errorMessageChangedSub: ');
                  console.log(errorMessage);
                  this.httpErrorMessage = errorMessage;
                  this.httpErrorReceived = true;
              }
          );
  }

  openQuickMenu() {
      this.router.navigateByUrl( '/quick-menu/' + encodeURIComponent(this.currentUrl));
  }
}
