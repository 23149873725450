import { Component, OnInit } from '@angular/core';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {CoreService} from '../../services/core.service';
import {PatientService} from '../../services/patient.service';
import {ModalController, Platform} from '@ionic/angular';
import {HttpService} from '../../services/http.service';
import {TranslateService} from '@ngx-translate/core';
import {ModalPatientAdmissionUpsertComponent} from '../shared/modal-patient-admission-upsert/modal-patient-admission-upsert.component';
import {TranslateDbService} from '../../services/translate.db.service';

@Component({
  selector: 'app-patient-admission-details',
  templateUrl: './patient-admission-details.component.html',
  styleUrls: ['./patient-admission-details.component.scss'],
})
export class PatientAdmissionDetailsComponent implements OnInit {
  ALL_TRANSLATIONS = [];

  patientId = '';
  admissionId = '';
  patientsAdmission = null;
  patientsAdmissionLabels = [];

  itemsVisibilityState = [];

  searchPhrase = '';
  isLoading = false;

  TITLE = '';

  constructor(private route: ActivatedRoute,
              public router: Router,
              public coreService: CoreService,
              public patientService: PatientService,
              public modalCtrl: ModalController,
              private httpService: HttpService,
              private platform: Platform,
              public translateDb: TranslateDbService,
              private translate: TranslateService) {
  }

  ngOnDestroy() {
  }

  ngOnInit() {
    if (!this.httpService.isLoggedIn()) {
      this.router.navigateByUrl('/login');
    }
    //this.patientId = this.route.snapshot.paramMap.get('patientId');
    this.admissionId = this.route.snapshot.paramMap.get('admissionId');
    this.isLoading = true;

    this.translateDb.loadTranslations().subscribe(t => {
      this.ALL_TRANSLATIONS = t;
    });

   this.loadPatientAdmissions();

  }

  loadPatientAdmissions(){

    this.patientService.loadPatientsAdmissionsByAdmissionId(this.admissionId).subscribe(r => {
      console.log(r);
      this.isLoading = false;
      this.patientsAdmission = r[0];

      this.patientId = this.patientsAdmission.patient;
      console.log('LOADING PATIENT DATA');
      console.log( this.patientsAdmission.first_name);

      this.TITLE = this.patientsAdmission.first_name + ' ' +this.patientsAdmission.last_name + ' - Admission' ;
    }, err => {
      console.log(err);
      this.coreService.broadcastErrorMessage('Fetching patients admissions failed ', err);
      this.isLoading = false;
    });
  }

  openEvaluations (admissionId: string) {
    this.router.navigateByUrl('/patients-evaluations/' + this.patientId + '/' + admissionId);
  }

  openAddPatientAdmissionModal() {
    this.modalCtrl
        .create({
          component: ModalPatientAdmissionUpsertComponent,
          componentProps: { patientId: this.patientId, showHeader: false, action: "add", patientItem: null  },
          cssClass: 'bg-none'
        })
        .then(modalEl => {
          modalEl.present();
          return modalEl.onDidDismiss();
        });
  }

  editPatient(patientItem: any){
    this.modalCtrl
        .create({
          component: ModalPatientAdmissionUpsertComponent,
          componentProps: { patientId: this.patientId, showHeader: false, action: "edit", patientItem: patientItem  },
          cssClass: 'bg-none'
        })
        .then(modalEl => {
          modalEl.present();
          return modalEl.onDidDismiss();
        });
  }
}
