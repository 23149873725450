import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-updates-log',
  templateUrl: './updates-log.component.html',
  styleUrls: ['./updates-log.component.scss'],
})
export class UpdatesLogComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
