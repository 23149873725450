import {Component, Input, OnInit} from '@angular/core';
import {CoreService} from '../../../services/core.service';
import {ModalController, Platform} from '@ionic/angular';
import {File} from '@ionic-native/file/ngx';
import {FileOpener} from '@ionic-native/file-opener/ngx';
import {WoundsService} from '../../../services/wounds.service';

@Component({
  selector: 'app-image-browser',
  templateUrl: './image-browser.component.html',
  styleUrls: ['./image-browser.component.scss'],
})
export class ImageBrowserComponent implements OnInit {
  @Input() fileId: string;
  fileData: any;
  isImage = false;
  base64String = '';
  fileDescription = '';

  constructor(public modalCtrl: ModalController,
              public coreService: CoreService,
              public woundsService: WoundsService,
              private file: File,
              private fileOpener: FileOpener,
              private platform: Platform) { }

  ngOnInit() {
        console.log('image-browser');
        this.woundsService.loadWoundFile(this.fileId).subscribe(r => {

        console.log(r);
        this.fileData = r[0];
        this.fileDescription = r[0].description;

        this.loadImageFromDb(r[0].encode, r[0].mimetype);
    });
  }


  loadImageFromDb(blobString: string, mimetype: string) {
    this.base64String = 'data:' + mimetype + ';base64,' + blobString;
  }

  downloadFile() {
    // https://ionicframework.com/docs/native/file
    // https://github.com/apache/cordova-plugin-file
    // https://forum.ionicframework.com/t/ionic-base64-pdf/81697/2


    //https://stackoverflow.com/questions/48534293/android-support-v4-content-fileprovider-not-found
    console.log('download file');

    // base 64


    // https://forum.ionicframework.com/t/could-not-download-pdf-from-base-64-data-from-web-service-why/123017/6
    // https://forum.ionicframework.com/t/file-opener-build-error-android/124503
    // https://forum.ionicframework.com/t/runtimeexception-unable-to-get-provider-android-support-v4-content-fileprovider/189507/2



    // error RuntimeException: Unable to get provider android.support.v4.content.FileProvider
    // SOLUTION!!!
    // https://forum.ionicframework.com/t/runtimeexception-unable-to-get-provider-android-support-v4-content-fileprovider/189507/7

    // Ok, so I downgraded Capacitor and that didn’t work for me so I went back up to 2.1.0 (core, cli, and android platform), removed my android directory and reinstalled using npx cap add android, then in Android Studio, ran Refactor -> Migrate to AndroidX as described here 14 (it gave me the message “no usages found” so that last step may or may not have done anything).
    //
    // I also ended up running npm audit fix at some point, though I doubt that had anything to do with anything. But you never know.
    //
    //     Unfortunately, I wasn’t especially scientific about isolating the problem. If I had to guess, I think one of these were the cause:
    //
    //     version mismatch between @capacitor/android and @capacitor/core (now both are on 2.1.0)
    // some edit I made in AndroidManifest.xml or other file (I re-started the android project from scratch)
    // Anyway, my app seems to work now in both the emulator and physical devices.
    //
    //     Thanks for your insights @vitonimal, hope some of this info is helpful to you and others.


    console.log('FILEDATA:');
    console.log('fileName: ', this.fileData.filename);
    console.log('mimetype: ', this.fileData.mimetype);
    //console.log('blob: ', this.fileData.blob);

    // https://stackoverflow.com/questions/49134592/open-pdf-from-base64-in-ionic
    const writeDirectory = this.platform.is('ios') ? this.file.dataDirectory : this.file.externalDataDirectory;

    fetch('data:' + this.fileData.mimetype + ';base64,' + this.fileData.blob, {
        method: 'GET'
      }).then(res => res.blob()).then(blob => {
        this.file.writeFile(writeDirectory, this.fileData.filename, blob, { replace: true })
            .then(res => {
              console.log('wres', res);
              this.fileOpener.open(res.toInternalURL(), this.fileData.mimetype).then((ores) => {
                console.log('ores', ores);
              }).catch(err => {
                console.log('open error');
              });

            }).catch(err => {
          console.log(err);
        });
    });
  }
}
