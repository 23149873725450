import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {take, map, tap, delay, switchMap} from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import {Router} from '@angular/router';
import {BehaviorSubject, of, Subscription} from 'rxjs';
import {HttpService} from './http.service';
import {DatePipe} from '@angular/common';
import {CoreService} from './core.service';
import {url} from 'inspector';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '../../environment';

@Injectable({
  providedIn: 'root'
})
export class TranslateDbService {
    ALL_TRANSLATIONS = [    ];


    ALL_TRANSLATIONS_TXT = [    ];
    currentLang = '';

    constructor(public coreService: CoreService,
                public router: Router,
                private httpService: HttpService) {

        console.log('TranslateDbService constructor FIRED!');

        //@ts-ignore
        // let x= [
        //   new TranslationLabel( "TXT_DETAILS","Details" ),
        //   new TranslationLabel( "TXT_CHARTS","cHARTS" )
        //   ];
        //
        // let y = JSON.stringify(x);
        //
        // x.forEach(r => {
        //   let dd = {
        //     colname: r.colname,
        //     label: r.label
        //   }
        //   // @ts-ignore
        //   this.ALL_TRANSLATIONS.push(
        //     {
        //       // @ts-ignore
        //       dd
        //     }
        //   )
        // })



        //this.ALL_TRANSLATIONS = y


        this.loadTranslationsInitial();


        // this.httpService.get(environment.URL_ROOT + '/Labels')
        //   .pipe(map(r => {
        //
        //     this.ALL_TRANSLATIONS = r.body;
        //     console.log('ALL_TRANSLATIONS size from TranslateDbService.constructor: ' + this.ALL_TRANSLATIONS.length);
        //     //
        //   }));
    }

    loadTranslationsInitial() {
        console.log('loadTranslationsInitial');


        this.httpService.get(environment.URL_ROOT + '/Labels')
            .pipe(map(r => {
                console.log('r:');

                console.log(r);
                this.ALL_TRANSLATIONS = r;
            }));


    }

    /*
    import { Observable } from 'rxjs/Observable';
    import 'rxjs/add/observable/of';

    public fetchModel(uuid: string = undefined): Observable<string> {
      if(!uuid) {
        return Observable.of(new TestModel()).map(o => JSON.stringify(o));
      }
      else {
        return this.http.get("http://localhost:8080/myapp/api/model/" + uuid)
                .map(res => res.text());
      }
    }

     */

    loadTranslations(){

        console.log('loadTranslations');
        console.log('ALL_TRANSLATIONS size:');
        console.log(this.ALL_TRANSLATIONS.length);

        // return of(this.ALL_TRANSLATIONS);


        if (this.ALL_TRANSLATIONS.length < 1) {

            console.log('CALLING FOR TRANSLATION LABELS...');
            return this.httpService.get(environment.URL_ROOT + '/Labels')
                .pipe(map(r => {

                    this.ALL_TRANSLATIONS = r;

                    return this.ALL_TRANSLATIONS;
                }));
        } else {
            return of(this.ALL_TRANSLATIONS);
        }


        //  return promise this.ALL_TRANSLATIONS;
        // return this.httpService.get(environment.URL_ROOT + '/Labels')
        //   .pipe(map(r => {
        //
        //     this.ALL_TRANSLATIONS = r.body;
        //
        //     return this.ALL_TRANSLATIONS;
        //   }));
    }


    getTranslation(EXT_TR: any, fieldName: string) {

        //console.log('this.ALL_TRANSLATIONS:');
        //console.log(this.ALL_TRANSLATIONS);
        try{
            //console.log('searching translation: ' + fieldName);
            //  const x = EXT_TR.filter((x: { colname: string; }) => x.colname === fieldName)[0].label;

            // @ts-ignore
            const x = this.ALL_TRANSLATIONS.filter((x: { colname: string; }) => x.colname === fieldName)[0].label;
            return x;
            // return this.ALL_TRANSLATIONS[0].dd.label;
        } catch {
            return fieldName;
        }

    }



}
