import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {CoreService} from '../../../services/core.service';
import {ModalController, Platform} from '@ionic/angular';
import {HttpService} from '../../../services/http.service';
import {TranslateDbService} from '../../../services/translate.db.service';
import {TranslateService} from '@ngx-translate/core';
import {WoundsService} from '../../../services/wounds.service';
import {TranslationsCommonService} from "../../../services/translations.common.service";

@Component({
  selector: 'app-wounds',
  templateUrl: './wounds.component.html',
  styleUrls: ['./wounds.component.scss'],
})
export class WoundsComponent implements OnInit, OnDestroy {
  admissionId = '';
  patientId = '';

  TITLE = 'Wounds';

  wounds = [];
  woundsFiltered = [];

  ALL_TRANSLATIONS = [];
  private woundAddedSub: Subscription;

  constructor(private route: ActivatedRoute,
              public router: Router,
              public coreService: CoreService,
              public woundsService: WoundsService,
              public modalCtrl: ModalController,
              private httpService: HttpService,
              private platform: Platform,
              public translateDb: TranslateDbService,
              public translationsCommonService: TranslationsCommonService,
              private translate: TranslateService) {
  }

  ngOnDestroy() {
    if (this.woundAddedSub) {
      this.woundAddedSub.unsubscribe();
    }
  }

  ngOnInit() {
    if (!this.httpService.isLoggedIn()) {
      this.router.navigateByUrl('/login');
    }
    this.admissionId = this.route.snapshot.paramMap.get('admissionId');
    this.loadWounds();

    this.woundAddedSub = this.woundsService.woundAdded.subscribe( httpData => {
      this.loadWounds();
    });
    this.translateDb.loadTranslations();

    this.TITLE = this.translationsCommonService.TXT_INJURY_EPUAP;
  }

  loadWounds(){
    this.woundsService.loadWounds(this.admissionId).subscribe(r => {
      console.log(r);
      console.log('LOADING WOUNDS DATA');
      console.log(r );
      this.wounds = r;
      this.woundsFiltered = r;

      this.woundsFiltered.forEach(i => {
        this.woundsFiltered.push(false);
      });
    }, err => {
      console.log(err);
      this.coreService.broadcastErrorMessage('Fetching WOUNDS failed ', err);
    });
  }
}
