import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {NavController, Platform} from '@ionic/angular';
import {Calendar} from '@ionic-native/calendar/ngx';
import {CoreService} from '../../services/core.service';
import {HttpService} from '../../services/http.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent implements OnInit {
  lectures = [];

  constructor(public router: Router,
              public navCtrl: NavController,
              //     private deeplinks: Deeplinks,
              private calendar: Calendar,
              private plt: Platform,
              public coreService: CoreService,
              private httpService: HttpService) {}

  ngOnInit() {
    this.httpService.logout();
    // localStorage.clear();

    this.router.navigateByUrl('/login');


  }
}
