import {Component, EventEmitter, OnInit} from '@angular/core';
import {ModalController, NavController} from '@ionic/angular';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {TranslateService} from '@ngx-translate/core';
import {NurseService} from '../../services/nurse.service';
import {CookieService} from 'ngx-cookie-service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {

  currentLanguage = '';
  userName = '';


  constructor(
      public modalCtrl: ModalController,
      public router: Router,
      private route: ActivatedRoute,
      private navCtrl: NavController,
      private cookieService: CookieService,
      private nurseService: NurseService,
      private translate: TranslateService,
      private location: Location) {
  }

  ngOnInit() {
    this.loadNurseData();
  }

  loadNurseData() {
     this.userName = this.nurseService.getUserName();
     this.nurseService.loadNurseData( this.userName).subscribe(u => {
          this.currentLanguage = u[0].language;
     });
  }

  setLanguage(languageCode: string) {
    this.currentLanguage = languageCode;
    this.translate.setDefaultLang(languageCode.toLowerCase());

    this.cookieService.set('gnu_lang', languageCode.toLowerCase(), 60, '/');

    this.nurseService.updateLanguageInDb(this.userName, languageCode).subscribe(u => {
      console.log(u + 'db lang updated');
    });
  }

  loadPage(pageName: string) {
    console.log('loading page: ' + pageName);
    this.router.navigateByUrl('/' + pageName);
  }

  goback() {
    this.location.back();
  }
}
