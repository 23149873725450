import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {take, map, tap, delay, switchMap, catchError} from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import {Router} from '@angular/router';
import {HttpService} from './http.service';
import {DatePipe} from '@angular/common';
import {CoreService} from './core.service';
import {ResourcesService} from './resources.service';
import {FormDropdownSectionItem} from '../components/shared/formdropdownsectiondata.model';
import {environment} from '../../environment';

@Injectable({
    providedIn: 'root'
})
export class WoundsService {
    constructor(public coreService: CoreService,
                private http: HttpClient,
                private cookieService: CookieService,
                public router: Router,
                private httpService: HttpService,
                private resourcesService: ResourcesService,
                private datepipe: DatePipe) {
    }

    woundAdded = new EventEmitter<string>();
    selectedStageChanged = new EventEmitter<string>();

    initializeWound(admissionId: string) {
        console.log('initializeWound, admissionId: ', admissionId);
        const postData = {
            admission: admissionId
        };
        return this.httpService.post(environment.URL_ROOT + '/Wounds', postData)
            .pipe(map(r => {
                console.log(r);
                this.woundAdded.emit('new wound added');
                return r;
            }));
    }

    saveWoundImage(base64String: string, description: string, woundId: string) {
        console.log('saveWoundImage, woundId: ', woundId);

        const pos = base64String.indexOf(',') + 1;
        const base64StringToUse = base64String.substr(pos, base64String.length);

        const postData = {
            _wound: woundId,
            _filename: woundId + '.png',
            _blob: base64StringToUse,
            _description: description
        };

        console.log('saveWoundImage postData:');
        console.log(postData);
        this.woundAdded.emit('wound image saving');

        return this.httpService.post(environment.URL_ROOT + '/rpc/WoundImagesAdd', postData)
            .pipe(map(r => {
                console.log(r);
                this.woundAdded.emit('wound image loaded');
                return r;
            }));
    }

    loadWoundFile(fileId: string) {
        console.log('loadWoundFile, fileId:' + fileId);
        return this.httpService.get(environment.URL_ROOT + '/TFiles?file=eq.' + fileId)
            .pipe(map(r => {
                    console.log(r);
                    return r;
                },
                error => {
                    console.log('loadWoundFile: ', error.message);
                    return error.message;
                }));
    }

    loadWoundFiles(woundId: string) {
        console.log('loadWoundFiles, woundId:' + woundId);
        return this.httpService.get(environment.URL_ROOT + '/WoundGallery?wound_id=eq.' + woundId)
            .pipe(map(r => {
                    console.log(r);
                    return r;
                },
                error => {
                    console.log('loadWoundFiles: ', error.message);
                    return error.message;
                }));
    }

    patchWoundField(woundId: string, col: string, value: any) {
        console.log('patchReportField');

        let valueToPrint = '';
        if (isNaN(+value)) {
            valueToPrint = '"' + value + '"';
        } else {
            valueToPrint = value;
        }
        const postDataString = '{\"' + col + '\": ' + valueToPrint + '}';


        console.log('postDataString: ' + postDataString);
        return this.httpService.patch(environment.URL_ROOT + '/TWounds?wound=eq.' + woundId, postDataString, col)
            .pipe(map(r => {
                console.log(r);
                return r;
            }, err => {
                console.log('xxxxx');
                return 'xxxx';
            }), catchError((err) => {
                const message = 'ZZZZZZZZZZ: ' + err;
                console.log(message);

                return err;
            }));
    }

    loadWoundFields() {

        const postData = {
            _endpoint: 'TWounds'
        };

        return this.httpService.post(environment.URL_ROOT + '/rpc/StructAdmin', postData)
            .pipe(map(r => {
                console.log(r);

                const fieldsList = r;

                // fieldsList.shift(); // remove admission field
                // fieldsList.shift(); // remove patient field
                // fieldsList.shift(); // remove date field
                // fieldsList.shift(); // remove time field

                return fieldsList;
            }));
    }


    loadWound(woundId: string) {
        return this.httpService.get(environment.URL_ROOT + '/Wounds?wound=eq.' + woundId)
            .pipe(map(r => {
                console.log(r);
                return r;
            }));
    }

    loadWounds(admissionId: string) {
        return this.httpService.get(environment.URL_ROOT + '/Wounds?admission=eq.' + admissionId)
            .pipe(map(r => {
                console.log(r);

                return r.reverse().slice();
            }));
    }

    broadcastNewStage(newStage: string) {
        this.selectedStageChanged.emit(newStage);
    }
}
