import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {take, map, tap, delay, switchMap} from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import {Router} from '@angular/router';
import {BehaviorSubject} from 'rxjs';
import {HttpService} from './http.service';
import {DatePipe} from '@angular/common';
import {CoreService} from './core.service';
import {url} from 'inspector';
import {TranslateService} from "@ngx-translate/core";
import {FileOpener} from "@ionic-native/file-opener/ngx";
import {Platform} from "@ionic/angular";
import { SQLite, SQLiteObject } from '@ionic-native/sqlite/ngx';

@Injectable({
  providedIn: 'root'
})
export class LogService {

    HTTP_REQUEST_TYPE = {
        GET: 'GET',
        POST: 'POST',
        PUT: 'PUT',
        DELETE: 'DELETE',
        PATCH: 'PATCH'
    }

    HTTP_REQUEST_DIRECTION = {
        REQUEST: 'REQUEST',
        RESPONSE: 'RESPONSE'
    }

    private storage: SQLiteObject;
    //songsList = new BehaviorSubject([]);
    private isDbReady: BehaviorSubject<boolean> = new BehaviorSubject(false);

    constructor(private http: HttpClient,
                public router: Router,
                private cookieService: CookieService,
                private translate: TranslateService,
                private fileOpener: FileOpener,
                private platform: Platform,
                private sqlite: SQLite) {


        // if platform != android - return nulls or empty strings to simulate none or incorrect
        this.platform.ready().then(() => {
            if(this.platform.is('android')) {
                this.sqlite.create({
                    name: 'gnuionicapp_cache.db',
                    location: 'default'
                })
                    .then((db: SQLiteObject) => {
                        this.storage = db;
                        this.setupCacheSource();
                    });
            }else{
                // browser mode - skipping setup
            }
        });

    }

    dbState() {
        return this.isDbReady.asObservable();
    }


    setupCacheSource() {
        return this.storage.executeSql('create table IF NOT EXISTS gnuionicapp_http_log(dt datetime, type TEXT, direction TEXT, url TEXT, headers TEXT, body TEXT)', [])
            .then(res => {
               console.log('gnuionicapp_http_log  initiated');
              // this.clearCache();
            });
    }


    // https://www.positronx.io/ionic-sqlite-database-crud-app-example-tutorial/
    createLog(requestType: string, direction: string, url: string, headers: string, body: string): Promise<string> {
        if(this.platform.is('android')) {

            let data = [requestType, direction, url, headers, body];
            return this.storage.executeSql('INSERT INTO gnuionicapp_http_log (dt, type, direction, url, headers, body) VALUES (datetime(\'now\'), ?, ?, ?, ?, ?)', data).then(res => {
                console.log(res);
                return res.toString();
            })
                .catch(e => console.log('log saving error: ' + e));
        } else {
          const p = Promise.resolve(null);
          return Promise.resolve(p);
        }
    }
}
