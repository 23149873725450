import {AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {CoreService} from '../../../services/core.service';
import {PatientService} from '../../../services/patient.service';
import {ModalController} from '@ionic/angular';
import {HttpService} from '../../../services/http.service';
import {TranslateService} from '@ngx-translate/core';
import {TranslateDbService} from '../../../services/translate.db.service';
import {TranslationsCommonService} from '../../../services/translations.common.service';
import {RectangleData} from '../../../models/drawing/rectangle.data';
import {WoundsService} from '../../../services/wounds.service';
import {WoundsStageModalComponent} from '../wounds-stage-modal/wounds-stage-modal.component';
@Component({
  selector: 'app-reports-wounds',
  templateUrl: './wounds-form.component.html',
  styleUrls: ['./wounds-form.component.scss'],
})
export class WoundsFormComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('imageCanvas', {static: false}) canvas: any;
  admissionId = '';
  reportType = 'wounds';
  ALL_TRANSLATIONS = [];

  showCanvas = false;

  imageLoading = false;

  woundId = null;
  formName = this.reportType + this.admissionId;

  rowCount = 0;

  TITLE = 'Wounds';
  canvasElement: any;

  canvasSelectionDataUrl  = '';
  customMessage = '';

  saveX: number;
  saveY: number;

  selectedColor = '#9e2956';
  colors = ['#9e2956', '#c2281d', '#de722f', '#edbf4c', '#5db37e', '#459cde', '#4250ad', '#802fa3'];
  lineWidth = 5;

  drawing = false;
  drawingNotes = '';

  lastRectangle: RectangleData;

  resultVisible = false;

  CANVAS_WIDTH = 400;
  CANVAS_HEIGHT = 448;

  WOUND_FIELDS = null;
  WOUND_FIELDS_VALUES = [];

  selectedStage = '1';

  private submitSectionSub: Subscription;
  private httpResponseCodeReceivedSub: Subscription;
  private selectedStageChangedSub: Subscription;

  constructor(private route: ActivatedRoute,
              public router: Router,
              public coreService: CoreService,
              public patientService: PatientService,
              public woundsService: WoundsService,
              public modalCtrl: ModalController,
              private httpService: HttpService,
              private translate: TranslateService,
              public translateDb: TranslateDbService,
              public translationsCommonService: TranslationsCommonService) {
  }

  ngOnDestroy() {
    if (this.submitSectionSub) {
      this.submitSectionSub.unsubscribe();
    }

    if (this.httpResponseCodeReceivedSub) {
      this.httpResponseCodeReceivedSub.unsubscribe();
    }

    if (this.selectedStageChangedSub) {
      this.selectedStageChangedSub.unsubscribe();
    }
  }

  initializeWound() {
    this.woundsService.initializeWound(this.admissionId).subscribe(r => {
      this.woundId = r[0].wound;

      console.log('initialized wound Id: ', this.woundId);
      this.showCanvas = true;


      this.loadWoundFields();
    }, err => {
      console.log(err);
      this.coreService.broadcastErrorMessage('Fetching wound failed ', err);
    });
  }

  ngOnInit() {
    if (!this.httpService.isLoggedIn()) {
      this.router.navigateByUrl('/login');
    }

    this.selectedStageChangedSub = this.woundsService.selectedStageChanged.subscribe( newStage => {
      console.log('selectedStageChangedSub: ', newStage);
      this.selectedStage = newStage;
    });

    this.httpResponseCodeReceivedSub = this.httpService.httpResponseCodeReceived.subscribe( httpData => {
      const code = +httpData.status;

      if (code >= 200 && code < 300) {
      } else {
        if (code >= 400) {
          this.imageLoading = false;
        }
      }
    });

    this.admissionId = this.route.snapshot.paramMap.get('admissionId');
    this.woundId = this.route.snapshot.paramMap.get('woundId');

    if (this.woundId === 'NEW') {
      this.showCanvas = true;
      this.initializeWound();

      this.loadWoundFields();
    } else {
      this.showCanvas = false;

      this.loadWoundFields();
    }

    this.TITLE = 'Wounds';
    this.translateDb.loadTranslations().subscribe(t => {
      this.ALL_TRANSLATIONS = t;
    });
  }

  closeAndRedirectToWounds() {
     this.modalCtrl.dismiss();
     this.router.navigateByUrl('wounds/' + this.admissionId);
  }

  ngAfterViewInit(): void {
    if (this.woundId === 'NEW') {
      this.canvasElement = this.canvas.nativeElement;
      this.canvasElement.width = this.CANVAS_WIDTH; // this.plt.width() + '';
      this.canvasElement.height = this.CANVAS_HEIGHT;

      this.setBackground();
    }
  }

  setBackground() {
    const background = new Image();
    background.src = './assets/human_scheme.jpg';
    const ctx = this.canvasElement.getContext('2d');

    background.onload = () => {
      ctx.drawImage(background, 0, 0, this.canvasElement.width, this.canvasElement.height);
    };
  }

  startInteraction(ev) {
    console.log('start: selection mode');
    let eventX = ev.pageX;
    let eventY = ev.pageY;

    if (ev.pageX === undefined) { // android
      eventX = ev.touches[0].pageX;
      eventY = ev.touches[0].pageY;
    }

    console.log('start: ', ev);
    this.drawing = true;
    const canvasPosition = this.canvasElement.getBoundingClientRect();
    console.log(canvasPosition);
    this.saveX = eventX - canvasPosition.x;
    this.saveY = eventY - canvasPosition.y;
  }

  movedInteraction(ev) {
    let eventX = ev.pageX;
    let eventY = ev.pageY;

    if (ev.pageX === undefined) { // android
      eventX = ev.touches[0].pageX;
      eventY = ev.touches[0].pageY;
    }

    if (!this.drawing) { return; }

    console.log('move: ', eventX, ', ', eventY);
    const canvasPosition = this.canvasElement.getBoundingClientRect();
    const ctx = this.canvasElement.getContext('2d');

    const currentX = eventX - canvasPosition.x;
    const currentY = eventY - canvasPosition.y;

    ctx.lineJoin = 'round';
    ctx.strokeStyle = this.selectedColor;
    ctx.lineWidth = this.lineWidth;

    ctx.beginPath();
    ctx.moveTo(this.saveX, this.saveY);
    ctx.lineTo(currentX, currentY);
    ctx.closePath();

    ctx.stroke();

    this.saveX = currentX;
    this.saveY = currentY;
  }

  endInteraction() {
    this.drawing = false;
    console.log('end');
  }

  exportCanvasImage() {
    const dataUrl = this.canvasElement.toDataURL('data:image/jpg');
    console.log('image: ', dataUrl);

    this.imageLoading = true;
    this.clearImage();

    this.woundsService.saveWoundImage(dataUrl, this.drawingNotes, this.woundId).subscribe(r => {
      console.log('woundsService: saveImage result:');
      console.log(r);

      this.canvasSelectionDataUrl = dataUrl;
      this.imageLoading = false;
      this.showCanvas = false;
    });
  }

  clearImage() {
    const context = this.canvasElement.getContext('2d');
    context.clearRect(0, 0, this.canvasElement.width, this.canvasElement.height);

    //  this.canvasSelectionDataUrl = '';
    this.setBackground();
  }

  getPlaceholderHint(dataType: string){
    if (dataType === 'timestamp with time zone' || dataType === 'date') {
      return this.coreService.DATE_FORMAT;
    } else if (dataType === 'integer' || dataType === 'double' ) {
      return 'number';
    } else {
      return 'text';
    }
  }

  getInputType(dataType: string) {
    if (dataType === 'integer' || dataType === 'double' || dataType === 'decimal' ) {
      return 'number';
    } else {
      return 'text';
    }
  }

  getInputClass(savedInDb: boolean, state: string) {
    if (savedInDb === true) {
      return 'saveSuccessBg';
    } else {
      if (state === 'fresh'){
        return '';
      } else if (state === 'saved'){
        return 'saveSuccessBg';
      } else if (state === 'fail') {
        return 'saveFailedBg';
      }
    }
  }

  getInputStyle(state: string) {

    if (state === 'fresh'){
      return 'background-color: white; color: black';
    } else if (state === 'saved'){
      return 'background-color: lightgreen; color: black';
    } else if (state === 'fail') {
      return 'background-color: red; color: black';
    }
  }

  loadWoundFields() {
    this.woundsService.loadWoundFields().subscribe(r => {

      console.log('loadWoundFields');

      console.log(this.WOUND_FIELDS);
      const R_REMOVED_VIEW_FIELDS: any[] | null = [];

      r.forEach((i: { col: string | string[]; }) => {
        if (i.col.indexOf('vf_') === -1) {
          if (i.col !== 'stage' && i.col !== 'wound'  ) { //&& i.col !== 'type' && i.col !== 'place'
            R_REMOVED_VIEW_FIELDS.push(i);
          }
        }
      });

      // @ts-ignore
      this.WOUND_FIELDS = R_REMOVED_VIEW_FIELDS;

      console.log(this.WOUND_FIELDS);
      // @ts-ignore
      this.WOUND_FIELDS.forEach(i => {

        // @ts-ignore
        this.WOUND_FIELDS_VALUES.push(
            {
              col: i.col,
              state: 'fresh',
              value: '',
              savedInDb: false
            }
        );
      });

      console.log('WOUND_FIELDS:');
      console.log(this.WOUND_FIELDS);

      if(this.woundId !== 'NEW'){
        this.loadWound();
      }


    }, err => {
      console.log(err);
      this.coreService.formatAndBroadcastDiagnosticMessage(err);
    });
  }

  loadWound() {
    this.woundsService.loadWound(this.woundId).subscribe(r => {

      // const R_REMOVED_VIEW_FIELDS = [];
      //
      const wfvCOPY = this.WOUND_FIELDS_VALUES.slice();
      this.WOUND_FIELDS_VALUES = [];
      r.forEach(i => {

        wfvCOPY.forEach(ii => {

          this.WOUND_FIELDS_VALUES.push(
              {
                col: ii.col,
                state: 'fresh',
                value: i[ii.col],
                savedInDb: false
              }
          );
        });
      });

      console.log(r);
    }, err => {
      console.log(err);
      this.coreService.broadcastErrorMessage('Fetching wound failed ', err);
    });
  }

  getBoolValue(itemValue: any) {

    if (itemValue === '') {
      return false;
    } else if (itemValue === false) {
      return false;
    } else if (itemValue === true) {
      return true;
    } else {
      return false;
    }
  }

  // only for TEXT fields
  patchField(col: string, array_index: number, newValue: any) {
    console.log('newValue: ', newValue);

    if (newValue === '' || newValue === undefined) {
      // this.coreService.broadcastToastMessage("NOT UPDATING FIELD", true);
      console.log('NOT UPDATING FIELD');
    } else {
      this.woundsService.patchWoundField(this.woundId, col, newValue).subscribe(r => {
            console.log('patchField report Id: ' + this.woundId + '; col: ' + col + '; value: ' + newValue);
            //   this.coreService.broadcastToastMessage(this.translationsCommonService.TRNSL_DATA_SAVED_SUCCESSFULLY, false);
            this.WOUND_FIELDS_VALUES[array_index].state = 'saved';
            this.WOUND_FIELDS_VALUES[array_index].savedInDb = true;
          }, err => {
            console.log('http ERROR caught FROM modal');
            console.log(err);
            // this.coreService.broadcastErrorMessage('Patching REPORT_FIELDS failed ', err);
            this.WOUND_FIELDS_VALUES[array_index].state = 'fail';
          }
      );
    }
  }

  calculateWidthClass(colName: string) {
    if (colName === 'description' || colName === 'drawNotes' || colName === 'position' || colName === 'stage') {
      return 'full-width';
    } else if ( colName === 'length' || colName === 'width' || colName === 'depth') {
      return 'onethird-width';
    } else {
      return 'half-width';
    }
  }

  openStageSelectionModal() {
    this.modalCtrl
        .create({
          component: WoundsStageModalComponent,
          componentProps: { selectedStage: this.selectedStage, woundId: this.woundId },
          cssClass: 'bg-none'
        })
        .then(modalEl => {
          modalEl.present();
          return modalEl.onDidDismiss();
        });
  }


  getWoundFieldValue(colname: string) {
    //console.log('getWoundFieldValue: ', colname);
  //  console.log('woundFields:');
//    console.log(this.WOUND_FIELDS_VALUES);
    const i = this.WOUND_FIELDS_VALUES.filter(x => x.col == colname);

    //console.log(i);
    if(i!=null && i!=undefined){
      return i[0].value;
    } else{
      return '';
    }

    return '';
  }
}
