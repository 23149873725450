import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {RectangleData} from '../../models/drawing/rectangle.data';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {CoreService} from '../../services/core.service';
import {PatientService} from '../../services/patient.service';
import {WoundsService} from '../../services/wounds.service';
import {ModalController} from '@ionic/angular';
import {HttpService} from '../../services/http.service';
import {TranslateService} from '@ngx-translate/core';
import {TranslateDbService} from '../../services/translate.db.service';
import {TranslationsCommonService} from '../../services/translations.common.service';
import {ChartBloodPressureData} from './chart.bloodpressure.data';
import {ChartGlucoseData} from './chart.glucose.data';
import {ChartInrData} from './chart.inr.data';
import {ChartPulseData} from './chart.pulse.data';
import {ChartRespiratoryData} from './chart.respiratory.data';
import {ChartTemperatureData} from './chart.temperature.data';

@Component({
  selector: 'app-patients-admission-charts',
  templateUrl: './patients-admission-charts.component.html',
  styleUrls: ['./patients-admission-charts.component.scss'],
})
export class PatientsAdmissionChartsComponent implements OnInit, OnDestroy {

  admissionId = '';
  TITLE = 'CHARTS';

  constructor(private route: ActivatedRoute,
              public router: Router,
              public coreService: CoreService,
              public patientService: PatientService,
              public woundsService: WoundsService,
              public modalCtrl: ModalController,
              private httpService: HttpService,
              private translate: TranslateService,
              public translateDb: TranslateDbService,
              public translationsCommonService: TranslationsCommonService) {
  }

  ngOnDestroy() {

  }

  ngOnInit() {
    if (!this.httpService.isLoggedIn()) {
      this.router.navigateByUrl('/login');
    }

    console.log('charts...');

    this.admissionId = this.route.snapshot.paramMap.get('admissionId');
    this.loadPatientAdmission();
    this.loadPatientReport_Bloodpressure('visiglu', 'gnu_bpChart1');
    this.loadPatientReport_Glucose('glucose', 'gnu_glucoseChart1');
    this.loadPatientReport_Inr('Antigent', 'gnu_inrChart1');
    this.loadPatientReport_Pulse('visiglu', 'gnu_pulseChart1');
    this.loadPatientReport_RespRate('visiglu', 'gnu_respChart1');
    this.loadPatientReport_Temperature('visiglu', 'gnu_tempChart1');
  }



  loadPatientReport_Temperature(reportType: string, htmlElement: string){
    this.patientService.loadPatientReports(reportType, this.admissionId).subscribe(result => {
      console.log(result);

      const CHART_DATA = [];
      if (result.length === 0) {
      } else {
        result.forEach((r: { created: any;  temperature: any;  }) => {
          const item = new ChartTemperatureData(r.created.toString(), +r.temperature);
          CHART_DATA.push(item);
        });

        console.log(CHART_DATA);
        this.coreService.loadChartData(htmlElement, JSON.stringify(CHART_DATA), '0');
      }

    });
  }


  loadPatientReport_RespRate(reportType: string, htmlElement: string){
    this.patientService.loadPatientReports(reportType, this.admissionId).subscribe(result => {
      console.log(result);

      const CHART_DATA = [];
      if (result.length === 0) {
      } else {
        result.forEach((r: { created: any;  respiratory_rate: any;  }) => {
          const item = new ChartRespiratoryData(r.created.toString(), +r.respiratory_rate);
          CHART_DATA.push(item);
        });

        console.log(CHART_DATA);
        this.coreService.loadChartData(htmlElement, JSON.stringify(CHART_DATA), '0');
      }

    });
  }

  loadPatientReport_Pulse(reportType: string, htmlElement: string){
    this.patientService.loadPatientReports(reportType, this.admissionId).subscribe(result => {
      console.log(result);

      const CHART_DATA = [];
      if (result.length === 0) {
      } else {
        result.forEach((r: { created: any;  pulse: any;  }) => {
          const item = new ChartPulseData(r.created.toString(), +r.pulse);
          CHART_DATA.push(item);
        });

        console.log(CHART_DATA);
        this.coreService.loadChartData(htmlElement, JSON.stringify(CHART_DATA), '0');
      }

    });
  }

  loadPatientReport_Inr(reportType: string, htmlElement: string){
    this.patientService.loadPatientReports(reportType, this.admissionId).subscribe(result => {
      console.log(result);

      const CHART_DATA = [];
      if (result.length === 0) {
      } else {
        result.forEach((r: { created: any;  inr: any;  }) => {
          const item = new ChartInrData(r.created.toString(), +r.inr);
          CHART_DATA.push(item);
        });

        console.log(CHART_DATA);
        this.coreService.loadChartData(htmlElement, JSON.stringify(CHART_DATA), '0');
      }

    });
  }

  loadPatientReport_Glucose(reportType: string, htmlElement: string){
    this.patientService.loadPatientReports(reportType, this.admissionId).subscribe(result => {
      console.log(result);

      const CHART_DATA = [];
      if (result.length === 0) {
      } else {
        result.forEach((r: { created: any;  glucose: any;  }) => {
          const item = new ChartGlucoseData(r.created.toString(), +r.glucose);
          CHART_DATA.push(item);
        });

        console.log(CHART_DATA);
        this.coreService.loadChartData(htmlElement, JSON.stringify(CHART_DATA), '0');
      }

    });
  }

  loadPatientReport_Bloodpressure(reportType: string, htmlElement: string){
    this.patientService.loadPatientReports(reportType, this.admissionId).subscribe(result => {
      console.log(result);

      //this.REPORT_DATA = result;

      const CHART_DATA = [];
      if (result.length === 0){
        //this.emptyResult = true;
        //this.REPORT_TITLE = this.REPORT_TITLE + ' - NO DATA';
      } else {
        result.forEach((r: { bp_systolic: any;  bp_diastolic: any; date: any; }) => {
          const item = new ChartBloodPressureData(r.date.toString(), r.bp_diastolic, r.bp_systolic);
          CHART_DATA.push(item);
        });

        console.log(CHART_DATA);
        this.coreService.loadChartData(htmlElement, JSON.stringify(CHART_DATA), '0');
      }

    });
  }



  loadPatientAdmission() {
    this.patientService.loadPatientsAdmissionsByAdmissionId(this.admissionId).subscribe(r => {
      console.log(r);
    }, err => {
      console.log(err);
      this.coreService.broadcastErrorMessage('Fetching patients admissions failed ', err);
      // this.isLoading = false;
    });
  }

}
