import {Component, Input, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {CoreService} from "../../services/core.service";
import {PatientService} from "../../services/patient.service";
import {ModalController, Platform} from "@ionic/angular";
import {HttpService} from "../../services/http.service";
import {TranslateService} from "@ngx-translate/core";
import {ModalPatientAdmissionUpsertComponent} from "../shared/modal-patient-admission-upsert/modal-patient-admission-upsert.component";
import {Capacitor} from "@capacitor/core";
import {ModalPatientEvaluationUpsertComponent} from "../shared/modal-patient-evaluation-upsert/modal-patient-evaluation-upsert.component";
import {ModalPatientEvaluationSectionComponent} from "../shared/modal-patient-evaluation-section/modal-patient-evaluation-section.component";
import {TranslateDbService} from "../../services/translate.db.service";

@Component({
  selector: 'app-patient-evaluations',
  templateUrl: './patient-evaluations.component.html',
  styleUrls: ['./patient-evaluations.component.scss'],
})
export class PatientEvaluationsComponent implements OnInit {
  ALL_TRANSLATIONS = [];
  admissionId = '';
  patientId = '';


  TITLE = '';

  patientItem = null;
  patientData_first_name = '';
  patientData_last_name = '';

  patientsEvaluations = [];
  patientsEvaluationsFiltered = [];

  itemsVisibilityState = [];

  searchPhrase = '';
  isLoading = false;

  private patientsEvaluationsAvailableChangedSub: Subscription;

  constructor(private route: ActivatedRoute,
              public router: Router,
              public coreService: CoreService,
              public patientService: PatientService,
              public modalCtrl: ModalController,
              private httpService: HttpService,
              private platform: Platform,
              public translateDb: TranslateDbService,
              private translate: TranslateService) {
  }

  ngOnDestroy() {
    if (this.patientsEvaluationsAvailableChangedSub) {
      this.patientsEvaluationsAvailableChangedSub.unsubscribe();
    }
  }

  ngOnInit() {
    if (!this.httpService.isLoggedIn()) {
      this.router.navigateByUrl('/login');
    }
    this.admissionId = this.route.snapshot.paramMap.get('admissionId');
    this.patientId = this.route.snapshot.paramMap.get('patientId');
    this.isLoading = true;

    this.translateDb.loadTranslations().subscribe(t =>{
      this.ALL_TRANSLATIONS = t;
    })
    this.loadPatient();
    this.loadPatientEvaluations();

    this.patientsEvaluationsAvailableChangedSub = this.patientService.patientsEvaluationsAvailableChanged
        .subscribe( r => {
              //email
              console.log('patientsEvaluationsAvailableChangedSub ACTIVATED!')
              this.loadPatientEvaluations( );
            }
        );
  }

  loadPatient(){
    this.patientService.loadPatient(this.admissionId).subscribe(r => {
      console.log(r);
      this.patientItem = r[0];

      this.TITLE = this.patientItem.full_name + ' Evaluations';

      console.log('patientItem');
      console.log(this.patientItem);
      this.isLoading = false;

    }, err => {
      console.log(err);
      this.coreService.broadcastErrorMessage('Fetching patients failed ', err);
      this.isLoading = false;
    });
  }

  loadPatientEvaluations(){
    this.patientService.loadPatientsEvaluations(this.admissionId).subscribe(r => {
      console.log(r);
      this.isLoading = false;

          console.log('LOADING PATIENT EVALUATION DATA');
          console.log(r );
          // console.log(r[0].first_name);
          this.patientsEvaluations = r;
          this.patientsEvaluationsFiltered = r;

          this.patientsEvaluationsFiltered.forEach(i => {
            this.itemsVisibilityState.push(false);
          });

          // console.log('patientsEvaluationsFiltered:');
          // console.log(this.patientsEvaluationsFiltered);

    }, err => {
      console.log(err);
      this.coreService.broadcastErrorMessage('Fetching patients evaluations failed ', err);
      this.isLoading = false;
    });
  }

  openAddPatientEvaluationModal() {
    this.modalCtrl
        .create({
          component: ModalPatientEvaluationUpsertComponent,
          componentProps: { admissionId: this.admissionId, showHeader: false, action: "add", evaluationItem: null  },
          cssClass: 'bg-none'
        })
        .then(modalEl => {
          modalEl.present();
          return modalEl.onDidDismiss();
        });
  }

  filterItems() {
    const f1 = this.patientsEvaluations.filter(x => x.last_name.value.toLowerCase().includes(this.searchPhrase.toLowerCase()));
    const f2 = [];
    //const f2 = this.patientsEvaluations.filter(x => x.name.toLowerCase().includes(this.searchPhrase.toLowerCase()));

    // this.patientsEvaluations.map(x => {
    //   x.vaccines.map(v => {
    //     if (v.toLowerCase().includes(this.searchPhrase.toLowerCase())) {
    //       f3.push(x);
    //     }
    //   });
    // });

    const f4 = f1.concat(f2);

    const uniqueItems = [...new Set(f4)];

    this.patientsEvaluationsFiltered = uniqueItems.slice();
    console.log('screenings filtered');
    console.log(this.patientsEvaluationsFiltered);
  }

  loadPatientSectionModal(evaluationId: string,API_PATH: string, SECTION_NAME: string ){

    console.log('loadPatientSectionModal: ' + evaluationId);

    this.modalCtrl
        .create({
          component: ModalPatientEvaluationSectionComponent,
          componentProps: { evaluationId: evaluationId, API_PATH: API_PATH, SECTION_NAME: SECTION_NAME  },
          cssClass: 'bg-none'
        })
        .then(modalEl => {
          modalEl.present();
          return modalEl.onDidDismiss();
        });
  }

  openPatientEvaluationSection(evaluationId: string, sectionName: string){
    switch(sectionName) {
      case this.patientService.PATIENT_EVALUATION_SECTION.HealthHistory:
        this.loadPatientSectionModal(evaluationId, 'Thealth_history_items', sectionName)
        break;
      case this.patientService.PATIENT_EVALUATION_SECTION.SupportSystem:
        this.loadPatientSectionModal( evaluationId, 'Tsupport_systems', sectionName);
        break;
      case this.patientService.PATIENT_EVALUATION_SECTION.Vision:
        this.loadPatientSectionModal( evaluationId, 'Tvision_items', sectionName);
        break;

      case this.patientService.PATIENT_EVALUATION_SECTION.Hearing:
        this.loadPatientSectionModal( evaluationId, 'Thearing_items', sectionName);
        break;

      case this.patientService.PATIENT_EVALUATION_SECTION.MentalState:
        this.loadPatientSectionModal( evaluationId, 'Tmental_states', sectionName);
        break;

      case this.patientService.PATIENT_EVALUATION_SECTION.Respiratory:
        this.loadPatientSectionModal( evaluationId, 'Trespiratory_items', sectionName);
        break;

      case this.patientService.PATIENT_EVALUATION_SECTION.Cardiovasc:
        this.loadPatientSectionModal( evaluationId, 'Tcardiovascs', sectionName);
        break;

      case this.patientService.PATIENT_EVALUATION_SECTION.Digestive:
        this.loadPatientSectionModal( evaluationId, 'Tdigestives', sectionName);
        break;

      case this.patientService.PATIENT_EVALUATION_SECTION.Urine:
        this.loadPatientSectionModal( evaluationId, 'Turines', sectionName);
        break;

      case this.patientService.PATIENT_EVALUATION_SECTION.Skin:
        this.loadPatientSectionModal( evaluationId, 'Tskins', sectionName);
        break;

      case this.patientService.PATIENT_EVALUATION_SECTION.Muskel:
        this.loadPatientSectionModal( evaluationId, 'Tmuskels', sectionName);
        break;

      case this.patientService.PATIENT_EVALUATION_SECTION.Transport:
        this.loadPatientSectionModal( evaluationId, 'Ttransport_items', sectionName);
        break;

      case this.patientService.PATIENT_EVALUATION_SECTION.Housing:
        this.loadPatientSectionModal( evaluationId, 'Thousing_items', sectionName);
        break;

      case this.patientService.PATIENT_EVALUATION_SECTION.Interior:
        this.loadPatientSectionModal( evaluationId, 'Tinterior', sectionName);
        break;

      case this.patientService.PATIENT_EVALUATION_SECTION.Exterior:
        this.loadPatientSectionModal( evaluationId, 'Texterior', sectionName);
        break;
    }
  }

  editEvaluation(evaluationItem: any){
    this.modalCtrl
        .create({
          component: ModalPatientEvaluationUpsertComponent,
          componentProps: { admissionId: this.admissionId, showHeader: false, action: "edit", evaluationItem: evaluationItem  },
          cssClass: 'bg-none'
        })
        .then(modalEl => {
          modalEl.present();
          return modalEl.onDidDismiss();
        });
  }
}
