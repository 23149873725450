import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {take, map, tap, delay, switchMap} from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import {Router} from '@angular/router';
import {HttpService} from './http.service';
import {DatePipe} from '@angular/common';
import {CoreService} from './core.service';
import {environment} from '../../environment';

@Injectable({
  providedIn: 'root'
})
export class NurseService {
    URL_ROOT = 'https://api.home-care.app';
    constructor(public coreService: CoreService,
                private http: HttpClient,
                private cookieService: CookieService,
                public router: Router,
                private httpService: HttpService,
                private datepipe: DatePipe) {
    }

    nursesAvailableChanged = new EventEmitter<any>();

    public getUserName() {

        const token = this.cookieService.get('token');

        const tokenData = this.parseJwt(token);
        console.log('decoded token: ');
        console.log(tokenData);

        return tokenData.name;
    }


    parseJwt(token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    };


    loadNurseData(userName: string) {
        console.log('loadVisitorData');
        return this.httpService.get (this.URL_ROOT + '/Nurses?username=eq.' + userName)
            .pipe(map(r => {
                console.log(r);
                return r;
            }));
    }

    updateLanguageInDb(userName: string, languageCode: string) {
        const postData = {
            language: languageCode
        };

        return this.httpService.patch(environment.URL_ROOT + '/Nurses?username=eq.' + userName, postData)
            .pipe(map(r => {
                console.log(r);
                return r;
            }));
    }

    loadNurses() {
        console.log('loadNurses');
        return this.httpService.get (this.URL_ROOT + '/Nurses')
            .pipe(map(r => {
                console.log(r);
                return r;
            }));
    }

    addNurse(vistitorName: string, visitorEmail: string, visitorType: string, visitorFirstName: string, visitorLastName: string, visitorIdCard: string, visitorPhone:string) {

        const postData = {
            _name: vistitorName,
            _email:visitorEmail,
            _type:visitorType,
            _first_name: visitorFirstName,
            _last_name: visitorLastName,
            _id_card: visitorIdCard,
            _phone: visitorPhone
        };

        console.log(postData);

        return this.httpService.post(environment.URL_ROOT + '/rpc/NursesVisitorSet', postData)
            .pipe(map(r => {
                console.log(r);
                return r;
            }));
    }

    broadcastInfoAboutAddedNurse(nurse: any) {

        // this.getVaccineLots().subscribe(x => {
        //     this.vaccineLotSelectedChanged.emit(x);
        // });

        this.nursesAvailableChanged.emit(nurse);
    }


/////OLD
//     getVisitor() {
//         console.log('getVisitor');
//         return this.httpService.get(environment.URL_ROOT + '/getVisitors')
//             .pipe(map(r => {
//                     console.log(r[0]);
//                     return r[0];
//                 },
//                 error => {
//                     console.log('error during getVisitor: ', error.message);
//                     return error.message;
//                 }));
//     }
//
//     saveVisitorFile(visitor: string, fileName: string, fileType: string, description: string, base64String: string) {
//         const postData = {
//             _visitor: visitor,
//             _filename: fileName,
//             _description: description,
//             _blob: base64String
//         };
//
//         console.log(postData);
//         return this.httpService.post(environment.URL_ROOT + '/rpc/addVisitorFile', postData)
//             .pipe(map(r => {
//                 console.log(r);
//                 return r;
//             }));
//     }
//
//     getVisitorFiles() {
//         console.log('getVisitorFiles');
//         return this.httpService.get(environment.URL_ROOT + '/getVisitorFiles')
//             .pipe(map(r => {
//                     console.log(r);
//                     return r;
//                 },
//                 error => {
//                     console.log('error during getVisitorFiles: ', error.message);
//                     return error.message;
//                 }));
//     }
}
