import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {DatePipe} from '@angular/common';
import {DatePickerComponent} from 'ionic4-date-picker';
import {HttpService} from '../../../services/http.service';
import {CoreService} from '../../../services/core.service';
import {ModalController} from '@ionic/angular';
import {DatePickerData} from '../datepickerdata.model';
import {ResourcesService} from '../../../services/resources.service';
import {FormControl, FormGroup} from '@angular/forms';
import {Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {TranslationsCommonService} from '../../../services/translations.common.service';
@Component({
  selector: 'app-picker-date-time',
  templateUrl: './picker-date-time.component.html',
  styleUrls: ['./picker-date-time.component.scss'],
})
export class PickerDateTimeComponent implements OnInit, OnDestroy {
  @Input() initialDate: string; 
  @Input() fromDate: string;
  @Input() descriptionLabel: string;

  initialDateObject: Date = new Date();
  hours = '';
  minutes = '';
  date = '2020-12-01';
  time = '12:00';

  dateTimeToUse = '';

  dateTimeIsValid = true;

  TRANSLATIONS = null;

  private submitSectionSub: Subscription;

  constructor(private datepipe: DatePipe,
              public coreService: CoreService,
              public modalCtrl: ModalController,
              private translate: TranslateService,
              public translatationsCommonService: TranslationsCommonService) { }

  ngOnDestroy() {
    if (this.submitSectionSub) {
      this.submitSectionSub.unsubscribe();
    }
  }

  ngOnInit() {
    if (this.initialDate === '' || this.initialDate === undefined) {
      console.log('initialDate is empty');
      this.initialDateObject = new Date();
    } else {
      this.initialDateObject = new Date(this.initialDate);

      const i = this.datepipe.transform(this.initialDate, this.coreService.DATETIME_FORMAT);
      this.dateTimeToUse = i;

      const zz = new Date(i);
      let newHours = zz.getHours().toString();
      let HOURS_leadingZeros = '';
      if (newHours.length === 1){
        HOURS_leadingZeros = '0';
      }

      this.hours = HOURS_leadingZeros + newHours;

      console.log('hours: ', this.hours);
      let newMinutes = zz.getMinutes().toString();
      let MINUTES_leadingZeros = '';
      if (newMinutes.length === 1) {
        MINUTES_leadingZeros = '0';
      }

      this.minutes = MINUTES_leadingZeros + newMinutes;

      console.log('minutes: ', this.minutes);
      this.date = i.substring(0, 10);
    }

    console.log(this.date);
    this.checkIfDateTimeValid();
   // https://github.com/write2sv/ionic4-date-picker

    this.submitSectionSub = this.coreService.submitSectionsChanged.subscribe(
            submitSectionDataItems  => {
              console.log('submitSectionSub: ');
              console.log(submitSectionDataItems);

              const submitSectionItem = submitSectionDataItems.find(x => x.sectionName === this.descriptionLabel);

              if (submitSectionItem != null){
                if (submitSectionItem.cancelBroadcasted === true) {
                  this.modalCtrl.dismiss();
                }

                if (submitSectionItem.submitBroadcasted === true) {
                  this.confirmDate();
                }
              }
            }
        );
  }

  numberOnlyValidationHours() {
    const pattern = /[0-9.,]/;
    if (!pattern.test(this.hours)) {
      // invalid character, prevent input
      console.log('pattern not matching');
      this.hours = '';
    } else {
      if (this.hours.length > 2 || +this.hours > 23) {
        this.hours = '';
      } else {
        this.timeSelected();
      }
    }
  }

  numberOnlyValidationMinutes() {
    const pattern = /[0-9.,]/;
    if (!pattern.test(this.minutes)) {
      console.log('pattern not matching');
      this.minutes = '';
    } else {
      if (this.minutes.length > 2 || +this.minutes > 59) {
        this.minutes = '';
      } else {
        this.timeSelected();
      }
    }
  }

  dateSelected(event) {
    this.checkIfDateTimeValid();
    this.date = this.datepipe.transform(event, this.coreService.DATE_FORMAT);

    this.dateTimeToUse  = this.date + ' ' + this.hours + ':' + this.minutes;
    console.log(this.dateTimeToUse);
    this.dateTimeToUse = this.datepipe.transform(this.dateTimeToUse, this.coreService.DATETIME_FORMAT);

    console.log('dateTimeToUse - from dateSelected:');
    console.log(this.dateTimeToUse);
    this.checkIfDateTimeValid();
  }

  timeSelected() {


      console.log('timeSelected');
      this.checkIfDateTimeValid();

      if (this.dateTimeIsValid) {
        this.time = this.hours + ':' + this.minutes;
        this.dateTimeToUse  = this.datepipe.transform(this.date, this.coreService.DATE_FORMAT) + ' ' + this.time;
        console.log(this.dateTimeToUse);
        this.dateTimeToUse = this.datepipe.transform(this.dateTimeToUse, this.coreService.DATETIME_FORMAT);

        console.log('dateTimeToUse - from tigmeSelected:');
        console.log(this.dateTimeToUse);
      }

      this.checkIfDateTimeValid();
  }

  confirmDate() {
    this.coreService.setDatePickerDate(new DatePickerData(this.dateTimeToUse, this.descriptionLabel));
    this.coreService.setSelectedDate(this.dateTimeToUse);
    this.modalCtrl.dismiss();
  }

  checkIfDateTimeValid() {
    const dateValid = this.dateTimeToUse.length > 0;
    const hoursValid = +this.hours > -1 && +this.hours < 25 && this.hours !== '';
    const minutesValid = +this.minutes > -1 && +this.minutes < 60 && this.minutes !== '';

    this.dateTimeIsValid = dateValid && hoursValid && minutesValid;
    console.log('dateTimeIsValid: ', this.dateTimeIsValid);

    return this.dateTimeIsValid;
  }
}
